import { httpMethods } from '../../Globals/Constants';
import Http from '../../Globals/Http';


const get		= async ()			=> await Http.request('categories');

const add		= async (data)		=> await Http.request('category', httpMethods.POST, data);
const update	= async (id, data)	=> await Http.request(`category/${id}`, httpMethods.PUT, data);

const sort		= async (ids)		=> await Http.request('categories/sort', httpMethods.PATCH, ids);

const remove	= async (id)		=> await Http.request(`category/${id}`, httpMethods.DELETE);

const image = {
	add			: async (id, data)	=> await Http.request(`category/image/${id}`, httpMethods.POST, data),
	copy		: async (id, id2)	=> await Http.request(`category/image/copy/${id}/${id2}`, httpMethods.POST)
};


export {
	get,
	add,
	update,
	remove,
	sort,
	image
}