import React, {useEffect,useState} from 'react';
import { useHistory } from "react-router-dom";

// plug-ins
import Moment from 'moment';

// helpers
import Utils from '../../Globals/Utils';

// constants
import { orderStatus } from '../../Globals/Constants';

// styles
import './styles.css';

// images
import imgClock from './Images/clock-black.svg';
import imgPayment from './Images/payment.svg';
import imgDiscount from './Images/discount.svg';
import imgUser from './Images/user.svg';
import imgPrice from './Images/price.svg';
import imgCall from './Images/call.svg';
import imgDelivery from './Images/delivery.svg';


const OrderInfo = (props) => {
	const history = useHistory();
	const [loading, setLoading] = useState(true);
	const [orderId, setOrderId] = useState(null);
	const [order, setOrder] = useState(null)
	useEffect(async () => {
		const order = props.order;
		setOrder(order);
		setLoading(false);
	}, []);
	const orderStatusNameGet = (status) => {
		switch (status) {
			case orderStatus.DELIVERY_END:
			case orderStatus.COMPLETED:
			case orderStatus.ARCHIVE:
				return 'выполнен';
			case orderStatus.CANCELED:
				return 'отменен';
			case orderStatus.REFUND:
				return 'возврат';
			default:
				return 'в работе';
		}
	}
	const orderSelect = (id) => {
		if (id === orderId) setOrderId(null);
		else setOrderId(id);
	}
	const delivery = (order) => {
		if (order.properties.isPickup) return 'Самовывоз';
		if (order.properties.deliveryFree) return 'Бесплатная доставка';
		return `Доставка ${order.amounts.delivery}  ₽`;
	};
	const goto = (link) => history.push(link);
	return loading ? null :
		<div className="order-info">
			<div className="order-info-header">
				<div className="order-info-number">
					<h4>Заказ №{order.number}</h4>
					<span className="status">{orderStatusNameGet(order.status)}</span>
				</div>
				<div className="amounts">
					<b>{Utils.moneyFormat(order.amounts.amountFull)} ₽</b>
					<div className="delivery">{delivery(order)}</div>
				</div>
			</div>
			<div className="order-info-info">
				<div>
					<div className="date">Создан {Moment(order.createdAt).format('DD.MM.YYYY HH:mm:ss')}</div>
					<div className="address">
						{order.properties.isPickup ? `Самовывоз с ${order.address.address}` : order.address.address}
					</div>
				</div>
				<div className="show-more" onClick={() => orderSelect(order._id)}>{orderId === order._id ? 'свернуть заказ' : 'развернуть заказ'}</div>
			</div>
			{orderId === order._id ?
					<div className="order-info-main">
						<div className="order-info-main-top">
							{order.options.date ?
								<div className="order-props">
									<img src={imgClock} alt="" />
									Дата: {Moment(order.options.date).format('D MMM H:mm')}
								</div> : null}
							<div className="order-props">
								<img src={imgPayment} alt="" />
								{order.properties.isPaid ? 'Оплата онлайн' : 'При получении'}
							</div>
							<div className="order-props">
								<img src={imgDiscount} alt="" />
								Скидка: {order.discounts.discount||0} ₽
							</div>
							<div className="order-props">
								<img src={imgPrice} alt="" />
								Сумма: {order.amounts.amountFull} ₽
							</div>
							<div className="order-props">
								<img src={imgDelivery} alt="" />
								Доставка: {order.amounts.delivery} ₽
							</div>
						</div>
						{order.comment ?
							<div className="order-notice">
								<b>Комментарий</b>{order.comment}
							</div> : null}
						<div className="order-info-products">
							<h5>Состав заказа</h5>
							{order.products.map((v,i) => <div key={i} className="order-info-product">
								<div className="name">{v.name}</div>
								<div className="count-price">
									<div className="quantity">{v.quantity} шт.</div>
									<div className="amount">{Utils.moneyFormat(v.price)} ₽</div>
								</div>
							</div>)}
						</div>
						{props.clientHide ? null :
							<div className="order-info-main-top">
								{order.client ?
									<div className="order-props order-props-client" onClick={() => goto(`/books/client/${order.client.id}`)}>
										<img src={imgUser} alt="" />
										{order.client.name}
									</div> : null}
								{order.client ?
									<div className="order-props order-props-client">
										<img src={imgCall} alt="" />
										<a href={`tel:${order.client.phone}`}>{Utils.phoneFormatter(order.client.phone)}</a>
									</div> : null}
							</div>}
						<div className={`order-info-footer${props.clientHide?' order-info-footer-noclient':''}`}>
							<div className="order-info-footer-row">
								<h5>Смена</h5>
								<div>{order.dayShift?.number||'–'} ({Moment(order.dayShift.date).format('D MMMM YYYY')})</div>
							</div>
							<div className="order-info-footer-row">
								<h5>Фмлиал</h5>
								<div>{order.point.name}</div>
							</div>
							<div className="order-info-footer-row">
								<h5>Создан</h5>
								<div>{Moment(order.createdAt).format('DD.MM.YYYY HH:mm:ss')}</div>
							</div>
							{order.options?.shipping ?
								<div className="order-info-footer-row">
									<h5>Отгружен</h5>
									<div>{Moment(order.options.shipping).format('DD.MM.YYYY HH:mm:ss')}</div>
								</div> : null}
							{order.dateFinish ?
								<div className="order-info-footer-row">
									<h5>Завершен</h5>
									<div>{Moment(order.dateFinish).format('DD.MM.YYYY HH:mm:ss')}</div>
								</div> : null}
						</div>
					</div>
				: null}
		</div>;
};

export default OrderInfo;