import React, {useEffect,useState} from 'react';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import Layout from '../../../Components/Layout';
import PointsSelect from '../../../Components/PointsSelect';

// models
import {Categories, Points} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';

// constants
import { API, commonStatus, commonStatusName } from '../../../Globals/Constants';

// images
import imgCheckOff from './Images/checkbox-off.svg';
import imgCheckOn from './Images/checkbox-on.svg';
import imgNoImage from './Images/no-image.svg';


// start
const CategoriesScreen = () => {
	const [loading, setLoading] = useState(true);
	const [partnerId, setPartnerId] = useState(null);
	const [points, setPoints] = useState([]);
	const [categoriesAll, setCategoriesAll] = useState([]);
	const [categories, setCategories] = useState(null);
	const [id, setId] = useState(null);
	const {value:name, setValue:setName, bind:bindName} = useInput('');
	const {value:description, setValue:setDescription, bind:bindDescription} = useInput('');
	const {value:sortOrder, setValue:setSortOrder, bind:bindSortOrder} = useInput('');
	const {value:link, setValue:setLink, bind:bindLink} = useInput('');
	const [categoryPoints, setCategoryPoints] = useState([]);
	const [image, setImage] = useState(null);
	const [cover, setCover] = useState(null);
	const [imageName, setImageName] = useState(null);
	const [coverName, setCoverName] = useState(null);
	const [search, setSearch] = useState(null);
	const [isHide, setIsHide] = useState(true);
	const [status, setStatus] = useState(null);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await pointsGet();
			await categoriesGet();
			const point = ls('point');
			setPartnerId(point.partnerId);
		};
		await dataGet();
		setLoading(false);
	}, []);
	const pointsGet = async () => {
		const points = await Points.get.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (points === undefined) return;
		setPoints(points);
	};
	const categoriesGet = async () => {
		const categories = await Categories.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (categories === undefined || categories.length === 0) return;
		setCategories(categories);
		setCategoriesAll(categories);
	};
	const categoryAdd = () => show();
	const categoryEdit = (category) => {
		category = category || categories.find(f => f._id === id);
		show(category);
	};
	const categoryShow = (category) => show(category);
	const show = (category) => {
		setId(category?._id||null);
		setName(category?.name||'');
		setDescription(category?.description||'');
		setSortOrder(category?.options?.sortOrder||'');
		setIsHide(category?.options?.isHide||false);
		setLink(category?.link||'');
		setImage(category ? imageUrlGet(partnerId, category._id, category.image) : null);
		setImageName(category?.image||null);
		setCover(category ? imageUrlGet(partnerId, category._id, category.cover) : null);
		setCoverName(category?.cover||null);
		setStatus(category?category.status:'');
		setCategoryPoints(category ? category.points : points.map(v => ({id:v._id,name:v.name})));
	};
	const cancel = () => categoryShow(null);
	const save = async () => {
		const data = {
			name:name,
			description:description,
			points:categoryPoints,
			sortOrder:sortOrder,
			isHide:isHide,
			link:link,
			status:status
		};
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить название категории');
			return;
		}
		if (data.points.length === 0) {
			errorShow('Необходимо выбрать филиалы');
			return;
		}
		const res = id ?
				await Categories.update(id, data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				})
			:
				await Categories.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
		if (res) {
			imageUpload(id||res.id);
			successShow(res.message);
			categoriesGet();
			cancel();
			return true;
		}
	}
	const imageUpload = async (id) => {
		const data = imagePrepare(image);
		if (data) {
			const type = Utils.imageExtGet(image);
			Categories.image.add(id, {data,type,index:'image'}).catch((ex) => console.log(666, ex));
		}
		const dataCover = imagePrepare(cover);
		if (dataCover) {
			const typeCover = Utils.imageExtGet(cover);
			Categories.image.add(id, {data:dataCover,type:typeCover,index:'cover'}).catch((ex) => console.log(666, ex));
		}
	}
	const imagePrepare = (image) => Utils.isImage(image) ? image.replace(/^.*,/gi,'') : null;
	const searchCategory = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setCategories(categoriesAll);
		else setCategories(categoriesAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase())));
		setSearch(search);
	}
	const categoryDelete = (id) => {
		Categories.remove(id).then((res) => {
			successShow(res.message);
			categoriesGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
		return true;
	}
	const pointGet = (item) => {
		const p = item.points;
		const out = [];
		if (p && p.length !== points.length) {
			for (let i = 0; i < p.length; i++) {
				out.push(points.find(f => f._id === p[i].id).name);
			}
		}
		return out.length === 0 ? 'Все филиалы' : out.join(', ');
	};
	const handleStatus = (e) => setStatus(parseInt(e.target.value));
	const linkCreate = (e) => {
		const name = e.target.value;
		setLink(Utils.translit(name));
		setName(name);
	}
	const pointCategorySelect = (point) => {
		const p = categoryPoints.find(f => f.id === point._id);
		if (p) setCategoryPoints(categoryPoints.filter(f => f.id !== point._id));
		else setCategoryPoints([...categoryPoints, {id:point._id,name:point.name}]);
	}
	const massCopy = async (ids) => {
		for (const v of ids) {
			const s = categories.find(f => f._id === v);
			if (s) {
				const data = {
					name:`${s.name} (копия)`,
					description:s.description,
					points:s.points,
					sortOrder:s.sortOrder,
					isHide:s.isHide,
					link:s.link,
					image:s.image,
					cover:s.cover,
					status:s.status
				};
				const res = await Categories.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
				if (res) Categories.image.copy(s._id, res.id).catch((ex) => console.log(666, ex));
			}
		};
		successShow('Операция прошла успешно');
		categoriesGet();
		cancel();
		return true;
	};
	const massDelete = async (ids) => {
		for (const v of ids) {
			await Categories.remove(v).catch((ex) => {
				console.log(666, ex);
				errorShow();
			});
		};
		successShow('Операция прошла успешно');
		categoriesGet();
		cancel();
		return true;
	};
	const coverError = (e) => {
		e.target.src = imgNoImage;
		e.target.className = 'image image-error';
	};
	const imageError = (e) => e.target.src = imgNoImage;
	const coverDelete = () => setCover(null);
	const imageDelete = () => setImage(null);
	const handleImage = (e, type) => {
		const file = e.target.files?.[0];
		if (!file) return;
		const reader = new FileReader();
		reader.onload = (r) => type === 'cover' ? setCover(r.target.result) : setImage(r.target.result);
		reader.readAsDataURL(file);
	};
	const imageUrlGet = (partnerId, id, filename) => `${API.assets}partners/${partnerId}/categories/${id}/${filename}`;
	const statusGet = (v) => <div className={`status${v.status===commonStatus.ACTIVE?v.status:0}`}>
		<div className="status-point"></div>
	</div>;
	const nameGet = (v) => v.options?.isHide ? <div className="hide">{v.name}</div> : v.name;
	const categorySort = async (data) => {
		setCategories([...data]);
		setCategoriesAll([...data]);
		const ids = data.map(v => v._id);
		await Categories.sort({ids}).catch((ex) => console.log(666, ex));
	};
	return (
		<>
			<Template>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<Layout
							title="Кат.товаров"
							type="books"
							data={categories}
							id={id}
							search={search}
							rows={[
								{title:'Название',field:'name',func:nameGet},
								{title:'Филиал',field:'points',class:'area',func:pointGet},
								{title:'Статус',field:'status',class:'status',func:statusGet}
							]}
							rowsShort={['name','status']}
							footerItems={[
								{title:'Сделать копию',action:massCopy},
								{title:'Удалить',action:massDelete,confirm:true}
							]}
							empty={<>Добавьте первую<br/>категорию</>}
							contentShowTitle={name}
							contentShow={<>
								{description ?
									<div className="product-view-row product-view-row-simple" dangerouslySetInnerHTML={{__html: Utils.rn2br(description)}} /> : null}
								<PointsSelect isShow={true} selected={categoryPoints} />
								{image && cover ?
									<>
										<h4>Фотография</h4>
										{cover ?
											<>
												<h5>Широкий баннер</h5>
												<div className="product-view-row product-view-row-simple">
													<img src={imageUrlGet(partnerId, id, coverName)} alt="" onError={coverError} className="image" />
												</div>
											</> : null}
										{image ?
											<>
												<h5>Плитка</h5>
												<div className="product-view-row product-view-row-simple product-edit-image">
													<img src={imageUrlGet(partnerId, id, imageName)} alt="" onError={imageError} className="image-small" />
												</div>
											</> : null}
									</> : null}
							</>}
							contentEdit={<>
								<div className="product-edit-row">
									<input type="text" {...bindName} placeholder="Название категории" className="input-title" autoFocus={true} onChange={linkCreate} required />
								</div>
								<div className="product-edit-row">
									<textarea placeholder="Описание" {...bindDescription}></textarea>
								</div>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="link" className="label-middle">Ссылка</label>
									<input id="link" {...bindLink} type="text" placeholder="Ссылка для категории" />
								</div>
								<PointsSelect isEdit={true} points={points} selected={categoryPoints} onSelect={pointCategorySelect} />
								<h4>Фотографии</h4>
								<h5>Широкий баннер</h5>
								<div className="product-edit-row">
									{cover ?
											<div className="product-edit-images-wide">
												<div className="product-edit-image">
													<img src={cover} alt="" onError={coverError} className="image" />
													<div className="delete" onClick={coverDelete}></div>
												</div>
											</div>
										: null}
									<label>
										<div className="product-edit-btn">
											Добавить фото
											<input type="file" accept="image/jpeg,image/png,image/webp" onChange={(e) => handleImage(e, 'cover')} />
										</div>
									</label>
								</div>
								<h5>Плитка</h5>
								<div className="product-edit-row">
									{image ?
											<div className="product-edit-images">
												<div className="product-edit-image">
													<img src={image} alt="" onError={imageError} />
													<div className="delete" onClick={imageDelete}></div>
												</div>
											</div>
										: null}
									<label>
										<div className="product-edit-btn">
											Добавить фото
											<input type="file" accept="image/jpeg,image/png,image/webp" onChange={(e) => handleImage(e, 'image')} />
										</div>
									</label>
								</div>
								<h4>Дополнительные параметры</h4>
								<div className="product-edit-row product-edit-row-oneline">
									<label className="label-middle">Скрытая категория</label>
									<img src={isHide ? imgCheckOn : imgCheckOff} alt="" onClick={() => setIsHide(!isHide)} />
								</div>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="sortOrder" className="label-middle">Порядок сортировки</label>
									<input id="sortOrder" {...bindSortOrder} type="text" placeholder="0" maxLength={2} />
								</div>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="status">Статус</label>
									<div className="select select-middle">
										<select id="status" onChange={handleStatus} value={status} required>
											<option value=""></option>
											{commonStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
										</select>
									</div>
								</div>
							</>}
							onDelete={categoryDelete}
							onEdit={categoryEdit}
							onAdd={categoryAdd}
							onSave={save}
							onSearch={searchCategory}
							onShow={categoryShow}
							onSort={categorySort}
							onClose={cancel}
						/>
					)
				}
			</Template>
			<Alert />
		</>
	);
};

export default CategoriesScreen;