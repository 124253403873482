import React, {useEffect,useState} from 'react';

// plug-ins
import {useModal} from 'react-hooks-use-modal';
import moment from 'moment';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Empty from '../../../Components/Empty';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import Layout from '../../../Components/Layout';

// models
import {ComponentCategories, Components, Invoices as Inv, Products, Suppliers, Workshops} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';

// constants
import { commonStatus, commonStatusName, measureTypeName } from '../../../Globals/Constants';

// styles
import './styles.css';

// images
import imgPlus from './Images/plus-black.svg';
import imgPlusButton from './Images/plus-btn.svg';
import imgClose from './Images/close.svg';
import imgCheckOff from './Images/checkbox-off.svg';
import imgCheckOn from './Images/checkbox-on.svg';
import imgArrowCurveDown from './Images/arrow-curve-down.svg';
import imgAdd from './Images/add.svg';


// start
const InvoicesScreen = () => {
	const componentAmountGet = (t) => {
		const p = t.id === 'price' ? t.value : price || 0;
		const q = t.id === 'quantity' ? t.value : quantity || 0;
		const a = p * q;
		setTotalAmount(a > 0 ? Number(a.toFixed(2)) : 0);
	};
	const [ModalProducts, modalProductsOpen, modalProductsClose] = useModal('root');
	const [ModalChangeStatus, modalChangeStatusOpen, modalChangeStatusClose] = useModal('root');
	const [ModalComponent, modalComponentOpen, modalComponentClose] = useModal('root');
	const [ModalItem, modalItemOpen, modalItemClose] = useModal('root');
	const [ModalItemComponents, modalItemComponentsOpen, modalItemComponentsClose] = useModal('root');
	const [ModalCategoryAdd, modalCategoryAddOpen, modalCategoryAddClose] = useModal('root');
	const [loading, setLoading] = useState(true);
	const [invoicesAll, setInvoicesAll] = useState([]);
	const [invoices, setInvoices] = useState(null);
	const [id, setId] = useState(null);
	const [name, setName] = useState(null);
	const {value:description, setValue:setDescription, bind:bindDescription} = useInput('');
	const {value:number, setValue:setNumber, bind:bindNumber} = useInput('');
	const {value:date, setValue:setDate, bind:bindDate} = useInput('');
	const [items, setItems] = useState([]);
	const [total, setTotal] = useState(0);
	const [inventory, setInventory] = useState([]);
	const [supplierId, setSupplierId] = useState(null);
	const [suppliers, setSuppliers] = useState([]);
	const {value:quantity, setValue:setQuantity, bind:bindQuantity} = useInput('', componentAmountGet);
	const {value:price, setValue:setPrice, bind:bindPrice} = useInput('', componentAmountGet);
	const [totalAmount, setTotalAmount] = useState(0);
	const [workshopId, setWorkshopId] = useState(null);
	const [workshops, setWorkshops] = useState([]);
	const [component, setComponent] = useState(null);
	const [components, setComponents] = useState([]);
	const [products, setProducts] = useState([]);
	const [productsAll, setProductsAll] = useState([]);
	const [categoryId, setCategoryId] = useState(null);
	const [categories, setCategories] = useState([]);
	const {value:categoryName, setValue:setCategoryName, bind:bindCategoryName} = useInput('');
	const {value:componentName, setValue:setComponentName, bind:bindComponentName} = useInput('');
	const {value:componentDescription, setValue:setComponentDescription, bind:bindComponentDescription} = useInput('');
	const {value:componentWeight, setValue:setComponentWeight, bind:bindComponentWeight} = useInput('');
	const {value:componentMeasureType, setValue:setComponentMeasureType, bind:bindComponentMeasureType} = useInput('');
	const {value:componentPrice, setValue:setComponentPrice, bind:bindComponentPrice} = useInput('');
	const {value:componentInvoicePrice, setValue:setComponentInvoicePrice, bind:bindComponentInvoicePrice} = useInput('');
	const {value:componentMinimum, setValue:setComponentMinimum, bind:bindComponentMinimum} = useInput('');
	const {value:componentRequestCount, setValue:setComponentRequestCount, bind:bindComponentRequestCount} = useInput('');
	const [componentStatus, setComponentStatus] = useState('');
	const [isPieces, setIsPieces] = useState(false);
	const [isProduct, setIsProduct] = useState(undefined);
	const [status, setStatus] = useState('');
	const [search, setSearch] = useState(null);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await workshopsGet();
			await suppliersGet();
			await categoriesGet();
			await componentsGet();
			await productsGet();
			await invoicesGet();
		};
		await dataGet();
		setLoading(false);
	}, []);
	const invoicesGet = async () => {
		const invoices = await Inv.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (invoices === undefined || invoices.length === 0) return;
		setInvoices(invoices);
		setInvoicesAll(invoices);
	};
	const workshopsGet = async () => {
		const workshops = await Workshops.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (workshops === undefined) return;
		setWorkshops(workshops);
	};
	const categoriesGet = async () => {
		const categories = await ComponentCategories.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (categories === undefined) return;
		setCategories(categories);
	};
	const componentsGet = async () => {
		const components = await Components.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (components === undefined) return;
		setComponents(components);
	};
	const productsGet = async () => {
		const products = await Products.get.active().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (products === undefined) return;
		setProducts(products);
		setProductsAll(products);
	};
	const suppliersGet = async () => {
		const suppliers = await Suppliers.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (suppliers === undefined) return;
		setSuppliers(suppliers);
	};
	const invoiceAdd = () => show();
	const invoiceEdit = (invoice) => {
		invoice = invoice || invoices.find(f => f._id === id);
		show(invoice);
	};
	const invoiceShow = (invoice) => show(invoice);
	const show = (invoice) => {
		setId(invoice?._id||null);
		setName(invoice ? `№${invoice.number} от ${moment(invoice.date).format('DD.MM.YYYY HH:mm')}` : '');
		setNumber(invoice?.number||'');
		setDate(Utils.dateTimeNormalize(invoice, 'date'));
		setDescription(invoice?.description||'');
		setSupplierId(invoice?.supplier?.id||null);
		setItems(invoice?.items||[]);
		setTotal(invoice?.total||0);
		setInventory(invoice?.inventory||null);
		setWorkshopId(invoice?.workshop?.id||null);
		setStatus(invoice?.status||'');
	};
	const cancel = () => {
		invoiceShow(null);
		modalChangeStatusHide();
		modalCategoryAddClose();
		modalComponentClose();
		modalItemComponentsClose();
		modalItemClose();
		modalProductsClose();
	}
	const save = async () => {
		const data = {
			number:number,
			date:date,
			workshopId:workshopId,
			supplierId:supplierId,
			description:description,
			items:items,
			total:calcTotalGet()
		};
		if (Utils.empty(data.date)) {
			errorShow('Необходимо заполнить дату');
			return;
		}
		if (Utils.empty(data.supplierId)) {
			errorShow('Необходимо выбрать поставщика');
			return;
		}
		if (Utils.empty(data.workshopId)) {
			errorShow('Необходимо выбрать склад');
			return;
		}
		if (items.length === 0) {
			errorShow('Необходимо добавить компоненты');
			return;
		}
		const res = id ?
				await Inv.update(id, data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				})
			:
				await Inv.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
		if (res) {
			successShow(res.message);
			invoicesGet();
			cancel();
			return true;
		}
	}
	const searchInvoice = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setInvoices(invoicesAll);
		else setInvoices(invoicesAll.filter(f => f.date.toLowerCase().includes(search.toLowerCase())));
		setSearch(search);
	}
	const invoiceDelete = (id) => {
		Inv.remove(id).then((res) => {
			successShow(res.message);
			invoicesGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
		return true;
	}
	const handleSupplier = (e) => setSupplierId(e.target.value);
	const handleWorkshop = (e) => setWorkshopId(e.target.value);
	const componentsItemShow = () => modalItemComponentsOpen();
	const componentSelect = (component) => {
		setComponent(component);
		setPrice('');
		setQuantity('');
		setTotalAmount(0);
		setIsProduct(false);
		modalItemComponentsClose();
	}
	const componentAdd = async (e) => {
		e.preventDefault();
		if (!component) {
			errorShow('Необходимо выбрать компонент');
			return;
		}
		const data = {
			id:component._id,
			name:component.name,
			price:parseFloat(price),
			quantity:parseFloat(quantity),
			weight:component.weight,
			measure:component.measure,
			total:totalAmount,
			isProduct:isProduct
		};
		if (Utils.empty(data.price) || Utils.empty(data.quantity)) {
			errorShow('Необходимо заполнить все поля');
			return;
		}
		setItems([...items, data]);
	}
	const categoryAddShow = () => {
		setCategoryName('');
		modalCategoryAddOpen();
	}
	const categorySave = async () => {
		if (Utils.empty(categoryName)) return;
		const data = {name:categoryName};
		const res = await ComponentCategories.add(data).catch((ex) => {
			console.log(666, ex);
			errorShow();
		});
		if (res) {
			successShow(res.message);
			categoriesGet();
			modalCategoryAddClose();
		}
	}
	const componentAddShow = () => {
		setCategoryId(null);
		setComponentName('');
		setComponentDescription('');
		setComponentPrice('');
		setComponentInvoicePrice('');
		setComponentMinimum('');
		setComponentRequestCount('');
		setComponentWeight('');
		setComponentMeasureType('');
		setIsPieces(false);
		setComponentStatus('');
		modalComponentOpen();
	}
	const handleCategory = (e) => setCategoryId(e.target.value);
	const handleMeasureType = (e) => setComponentMeasureType(parseInt(e.target.value));
	const itemShow = () => {
		setPrice('');
		setQuantity('');
		setTotalAmount(0);
		setIsProduct(undefined);
		setComponent(null);
		setCategoryId(null);
		modalItemOpen();
	}
	const componentSave = async (e) => {
		e.preventDefault();
		const data = {
			categoryId:categoryId,
			name:componentName,
			description:componentDescription,
			price:componentPrice,
			requestCount:componentRequestCount,
			minimum:componentMinimum,
			weight:componentWeight,
			measure:componentMeasureType,
			isPieces:isPieces
		};
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить название компонента');
			return;
		}
		if (Utils.empty(data.weight)) {
			errorShow('Необходимо заполнить объем / вес компонента');
			return;
		}
		if (data.measure === null) {
			errorShow('Необходимо заполнить единицу измерения компонента');
			return;
		}
		const res = id ?
				await Components.update(id, data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				})
			:
				await Components.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
		if (res) {
			successShow(res.message);
			componentsGet();
			modalComponentClose();
		}
	}
	const handleComponentStatus = (e) => setComponentStatus(parseInt(e.target.value));
	const calcTotalGet = () => items.reduce((acc, v) => acc += parseFloat(v.total), 0);
	const invoiceChangeStatus = () => {
		Inv.status(id, {status:commonStatus.IN_ACTIVE}).then((res) => {
			successShow(res.message);
			invoicesGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
	}
	const modalChangeStatusHide = () => modalChangeStatusClose();
	const modalChangeStatusShow = (item) => {
		setId(item._id);
		setTotal(invoices.find(f => f._id === item._id).total);
		modalChangeStatusOpen();
	}
	const statusGet = (v) => <div className={`status${v.status===commonStatus.ACTIVE?v.status:0}`}>
		<div className="status-point"></div>
	</div>;
	const massCopy = async (ids) => {
		for (const v of ids) {
			const s = invoices.find(f => f._id === v);
			if (s) {
				const data = {
					number:`${s.number} (копия)`,
					date:Utils.dateTimeNormalize(null, 'date'),
					workshopId:s.workshop.id,
					supplierId:s.supplier.id,
					description:s.description,
					items:s.items,
					total:s.total,
					status:commonStatus.ACTIVE
				};
				await Inv.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
			}
		};
		successShow('Операция прошла успешно');
		invoicesGet();
		cancel();
		return true;
	};
	const productItemShow = () => modalProductsOpen();
	const productSelect = (product) => {
		product.weight = product.properties.weight;
		product.measure = product.properties.measureType;
		setComponent(product);
		setPrice('');
		setQuantity('');
		setTotalAmount(0);
		setIsProduct(true);
		modalProductsClose();
	}
	const searchProduct = (e) => {
		const search = e.target.value;
		setSearch(search);
		if (Utils.empty(search)) setProducts(productsAll);
		else setProducts(productsAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase())));
	};
	return (
		<>
			<Template>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<Layout
							title="Накладные"
							type="warehouse"
							data={invoices}
							id={id}
							search={search}
							rows={[
								{title:'Дата',field:'date',func:(v) => <>
									№{v.number} от {moment(v.date).format('DD.MM.YYYY HH:mm')}
									{v.description ? <span>{v.description}</span> : null}
								</>},
								{title:'Поставщик',field:'supplier',sort:'supplier.name',class:'area',func:(v) => v.supplier ? v.supplier.name : '–'},
								{title:'Склад',field:'workshop',class:'area',sort:'workshop.name',class:'area',func:(v) => v.workshop ? v.workshop.name : '–'},
								{title:'Сумма',field:'total',class:'prices'},
								{title:'Статус',field:'status',class:'status status-small',func:statusGet}
							]}
							rowsShort={['date','total','status']}
							rowControlItems={(v) => v.status === commonStatus.ACTIVE ? [{title:'Оплатить',class:'check',action:modalChangeStatusShow}] : []}
							footerItems={[
								{title:'Сделать копию',action:massCopy}
							]}
							empty={<>Добавьте первую<br/>накладную</>}
							contentShowTitle={name}
							contentShow={<>
								{description ?
									<div className="product-view-row product-view-row-simple" dangerouslySetInnerHTML={{__html: Utils.rn2br(description)}} /> : null}
								<div className="product-view-row product-view-row-oneline">
									<span>Номер накладной</span>
									<div>{number}</div>
								</div>
								<div className="product-view-row product-view-row-oneline">
									<span>Дата</span>
									<div>{moment(date).format('DD.MM.YYYY HH:mm')}</div>
								</div>
								<div className="product-view-row product-view-row-oneline">
									<span>Склад</span>
									<div>{workshops.find(f => f._id === workshopId)?.name||'–'}</div>
								</div>
								<div className="product-view-row product-view-row-oneline">
									<span>Поставщик</span>
									<div>{suppliers.find(f => f._id === supplierId)?.name||'–'}</div>
								</div>
								{status !== commonStatus.ACTIVE ?
									<div className="product-view-row product-view-row-oneline">
										<span>Статус</span>
										<div>Проведено списание</div>
									</div> : null}
								{inventory ?
									<div className="product-view-row product-view-row-oneline">
										<span>Инвентаризация от</span>
										<div>{moment(inventory.date).format('DD.MM.YYYY HH:mm')}</div>
									</div> : null}
								<h4>Позиции</h4>
								{items.length ?
										<div className="product-edit-components-block">
											{items.map((v,i) => <div key={i} className="product-edit-component-item">
												<div className="product-edit-component-item-name">
													<b>{v.name}</b>
													{v.isProduct ? <span className="type">товар</span> : null}
												</div>
												<div className="info">
													<div><b>сумма: {v.total}₽</b> • кол-во: {v.quantity} {measureTypeName[v.measure]} • цена: {v.price}₽</div>
												</div>
											</div>)}
										</div>
									: null}
								<div className="product-edit-row product-edit-row-oneline product-edit-row-select">
									<label className="label-middle"><b>Итого</b></label>
									<b>{total} ₽</b>
								</div>
							</>}
							contentEdit={<>
								<div className="product-edit-row product-edit-row-oneline product-edit-row-first">
									<label htmlFor="number" className="label-middle">Номер накладной</label>
									<input id="number" {...bindNumber} type="text" placeholder="0" required />
								</div>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="date" className="label-middle">Дата</label>
									<input id="date" {...bindDate} type="datetime-local" required />
								</div>
								<div className="product-edit-row">
									<div className="select select-wide">
										<select onChange={handleWorkshop} value={workshopId} required>
											<option value="">Выберите склад</option>
											{workshops.map((v,i) => <option key={i} value={v._id}>{v.name}</option>)}
										</select>
									</div>
								</div>
								<div className="product-edit-row">
									<div className="select select-wide">
										<select onChange={handleSupplier} value={supplierId} required>
											<option value="">Выберите поставщика</option>
											{suppliers.map((v,i) => <option key={i} value={v._id}>{v.name}</option>)}
										</select>
									</div>
								</div>
								<div className="product-edit-row">
									<textarea placeholder="Примечание" {...bindDescription} className="lite"></textarea>
								</div>
								<h4>Позиции</h4>
								<div className="product-edit-row">
									{items.length ?
											<div className="product-edit-components-block">
												{items.map((v,i) => <div key={i} className="product-edit-component-item">
													<div className="product-edit-component-item-name">
														<div>
															<b>{v.name}</b>
															{v.isProduct ? <span className="type">товар</span> : null}
														</div>
														<img src={imgClose} alt="" className="close-icon" onClick={() => setItems(items.filter(f => f.id !== v.id))} />
													</div>
													<div className="info">
														<div><b>сумма: {v.total}₽</b> • кол-во: {v.quantity} {measureTypeName[v.measure]} • цена: {v.price}₽</div>
													</div>
												</div>)}
											</div>
										: null
									}
									<div className="product-edit-btn" onClick={() => itemShow()}>Добавить</div>
								</div>
								{items.length ?
									<div className="product-edit-row product-edit-row-oneline product-edit-row-select">
										<label className="label-middle"><b>Итого</b></label>
										<b>{calcTotalGet().toFixed(2)} ₽</b>
									</div> : null}
							</>}
							deleteExpression={(v) => v.status === commonStatus.ACTIVE}
							editExpression={(v) => v.status === commonStatus.ACTIVE}
							hideEditExpression={() => status === '' || status === commonStatus.IN_ACTIVE}
							onDelete={invoiceDelete}
							onEdit={invoiceEdit}
							onAdd={invoiceAdd}
							onSave={save}
							onSearch={searchInvoice}
							onShow={invoiceShow}
							onClose={cancel}
						/>
					)
				}
			</Template>
			<Alert />
			<ModalItem>
				<div className="modal modal-items">
					<div className="header">
						<h4>Добавить позицию</h4>
						<img src={imgClose} alt="" onClick={modalItemClose} className="btn-close" />
					</div>
					<form onSubmit={componentAdd}>
						{component ?
								<div>
									<b>{component.name}</b> • <span>{isProduct===true?component.prices?.priceFull:(component.prices?.manual||component.prices?.invoice||0)} ₽ за {isProduct===true?component.properties.weight:component.weight} {measureTypeName[isProduct===true?component.properties.measureType:component.measure]}</span>
								</div>
							: null}
						<div className="buttons">
							<button type="button" className={`btn-cancel btn-select btn-select-half${isProduct===false?' btn-select-half-select':''}`} onClick={() => componentsItemShow()}>Компонент</button>
							<button type="button" className={`btn-cancel btn-select btn-select-half${isProduct===true?' btn-select-half-select':''}`} onClick={() => productItemShow()}>Товар</button>
						</div>
						<div className="weights-form">
							<div className="weights-form-row">
								<label htmlFor="price">Цена{component ? 
									(isProduct ?
											<span>товара за {component.properties.weight} {measureTypeName[component.properties.measureType]}</span>
										:
											<span>компонента за {component.weight} {measureTypeName[component.measure]}</span>

									) : null}
								</label>
								<div>
									<input id="price" type="text" {...bindPrice} placeholder="0" maxLength={5} required />
									<span>₽</span>
								</div>
							</div>
							<div className="weights-form-row">
								<label htmlFor="quantity">Количество</label>
								<div>
									<input id="quantity" type="text" {...bindQuantity} placeholder="0" maxLength={5} required />
									{component ? <span>{measureTypeName[isProduct ? component.properties.measureType : component.measure]}</span> : <span>–</span>}
								</div>
							</div>
						</div>
						<div className="weights-form">
							<div className="weights-form-row">
								<label>Сумма</label>
								<div>
									<b>{totalAmount} ₽</b>
								</div>
							</div>
						</div>
						<div className="buttons">
							<button type="submit" className="btn-accept">Добавить</button>
						</div>
					</form>
				</div>
			</ModalItem>
			<ModalItemComponents>
				<div className="modal modal-conditions-lite">
					<div className="header">
						<div className="header-sub">
							<h4>Добавить компонент</h4>
							<img src={imgAdd} alt="" className="add" onClick={() => componentAddShow()} />
						</div>
						<img src={imgClose} alt="" onClick={modalItemComponentsClose} className="btn-close" />
					</div>
					<div className="conditions">
						<div className="sections">
							<div className="inner">
								{categories.length ?
										<div className="list">
											{categories.map((v,i) => <div key={i} className={`section${categoryId===v._id?' section-select':''}`} onClick={() => setCategoryId(v._id)}>
												{v.name}
											</div>)}
										</div>
									:
										<div className="category-empty">
											<div>
												Здесь можно добавить новые категории, чтобы заполнить меню
											</div>
											<img src={imgArrowCurveDown} alt="" />
										</div>
								}
							</div>
							<div className="sections-footer">
								<button type="button" onClick={() => categoryAddShow()}>
									<img src={imgPlus} alt="" />
										Добавить категорию
								</button>
							</div>
						</div>
						<div className="content">
							{components.filter(f => f.category.id === categoryId).length ?
									<div className="components-list">
										{components.filter(f => f.category.id === categoryId).map((v,i) => <div key={i} className="component" onClick={() => componentSelect(v)}>
											{v.name}
											<span>{v.category.name} • {v.prices?.manual||v.prices?.invoice||0} ₽ • {v.weight} {measureTypeName[v.measure]}</span>
										</div>)}
									</div>
								:
									<div className="empty">
										<Empty image={'caregories'} text={
											<>
												Для добавления компонента,<br />выберите один из списка слева
												<div className="product-add-container">
													<p>или<br/>добавьте новую позицию</p>
													<button type="button" onClick={() => componentAddShow()}>Добавить компонент</button>
												</div>
											</>} />
									</div>
								}
						</div>
					</div>
				</div>
			</ModalItemComponents>
			<ModalComponent>
				<div className="modal modal-component-newadd">
					<div className="header">
						<h4>Добавить компонент</h4>
						<img src={imgClose} alt="" onClick={modalComponentClose} className="btn-close" />
					</div>
					<form className="product-edit" onSubmit={componentSave}>
						<div className="container-inner">
							<div className="list">
								<div className="product-edit-row">
									<input type="text" {...bindComponentName} placeholder="Название компонента" autoFocus={true} required />
								</div>
								<div className="product-edit-row">
									<textarea placeholder="Описание" {...bindComponentDescription} className="lite"></textarea>
								</div>
								<div className="product-edit-row product-edit-row-oneline product-edit-row-plus">
									<div className="select select-wide">
										<select onChange={handleCategory} value={categoryId} required>
											<option value="">Выберите категорию</option>
											{categories.map((v,i) => <option key={i} value={v._id}>{v.name}</option>)}
										</select>
									</div>
									<img src={imgPlusButton} alt="" className="categoty-add" onClick={() => categoryAddShow()} />
								</div>
								<h4>Объем / Вес</h4>
								<div className="product-edit-row product-edit-row-oneline">
									<input type="text" {...bindComponentWeight} placeholder="Объем / Вес" maxLength={4} required />
									<div className="select">
										<select onChange={handleMeasureType} value={componentMeasureType} required>
											{measureTypeName.map((v,i) => <option key={i} value={i===0?'':i}>{v}</option>)}
										</select>
									</div>
								</div>
								<h4>Цены</h4>
								<div className="product-edit-row product-edit-row-oneline product-edit-row-select">
									<label className="label-middle">Цена из последней накладной</label>
									{componentInvoicePrice?`${componentInvoicePrice} ₽`:'–'}
								</div>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="price" className="label-middle">Цена (коррекция), ₽</label>
									<input id="price" {...bindComponentPrice} type="text" placeholder="0" maxLength={6} />
								</div>
								<h4>Свойства</h4>
								<div className="product-edit-row product-edit-row-oneline">
									<label className="label-middle">Штучный, количественный товар</label>
									<img src={isPieces ? imgCheckOn : imgCheckOff} alt="" onClick={() => setIsPieces(!isPieces)} />
								</div>
								<h4>Дополнительные параметры</h4>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="minimum" className="label-middle">Минимальный остаток на складе</label>
									<input id="minimum" {...bindComponentMinimum} type="text" placeholder="0" maxLength={4} />
								</div>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="requestcount" className="label-middle">Количество для заявки по умолчанию</label>
									<input id="requestcount" {...bindComponentRequestCount} type="text" placeholder="0" maxLength={4} />
								</div>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="status">Статус</label>
									<div className="select select-middle">
										<select id="status" onChange={handleComponentStatus} value={componentStatus} required>
											<option value=""></option>
											{commonStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
										</select>
									</div>
								</div>
							</div>
						</div>
						<div className="product-edit-footer">
							<button type="button" onClick={modalComponentClose} className="btn-cancel">Отменить</button>
							<button type="submit" className="btn-save btn-accept">Сохранить</button>
						</div>
					</form>
				</div>
			</ModalComponent>
			<ModalCategoryAdd>
				<div className="modal">
					<div className="header">
						<h4>Добавить категорию</h4>
						<img src={imgClose} alt="" onClick={modalCategoryAddClose} className="btn-close" />
					</div>
					<input type="text" {...bindCategoryName} placeholder="Название категории" autoFocus={true} />
					<div className="buttons">
						<button type="button" onClick={modalCategoryAddClose} className="btn-cancel">Отменить</button>
						<button type="button" onClick={categorySave} className="btn-accept">Сохранить</button>
					</div>
				</div>
			</ModalCategoryAdd>
			<ModalChangeStatus>
				<div className="modal">
					<div className="header">
						<h4>Оплата накладной</h4>
						<img src={imgClose} alt="" onClick={modalChangeStatusHide} className="btn-close" />
					</div>
					<p>Будет произведена операция списания из кассы на сумму <b>{total}₽</b></p>
					<div className="buttons">
						<button type="button" onClick={modalChangeStatusHide} className="btn-cancel">Отменить</button>
						<button type="button" onClick={invoiceChangeStatus} className="btn-accept">Провести</button>
					</div>
				</div>
			</ModalChangeStatus>
			<ModalProducts>
				<div className="modal modal-products-list">
					<div className="header">
						<h4>Добавить позицию</h4>
						<img src={imgClose} alt="" onClick={modalProductsClose} className="btn-close" />
					</div>
					<input type="search" placeholder="Поиск" className="search" onChange={searchProduct} autoFocus={true} />
					<div className="products">
						<div className="list">
							{products.length ?
									products.map((v,i) => <div key={i} className="product">
										<div>
											{v.name}
											<span>{v.prices.priceFull} ₽ • {v.properties.weight} {measureTypeName[v.properties.measureType]}</span>
										</div>
										<img src={component?.id === v._id ? imgCheckOn : imgCheckOff} alt="" onClick={() => productSelect(v)} />
									</div>)
								:
									<div className="product-empty">
										<div>
											Ничего не найдено
										</div>
									</div>
							}
						</div>
					</div>
				</div>
			</ModalProducts>
		</>
	);
};

export default InvoicesScreen;