import React, {useEffect,useState} from 'react';

// plug-ins
import moment from 'moment';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Error from '../../../Components/Error';
import Layout from '../../../Components/Layout';

// models
import {DayShifts} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';

// constants
import { commonStatus, commonStatusDayShiftName } from '../../../Globals/Constants';


// start
const DayShiftsScreen = () => {
	const [loading, setLoading] = useState(true);
	const [dayShifts, setDayShifts] = useState([]);
	const [id, setId] = useState(null);
	const [dayShift, setDayShift] = useState(null);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await dayShiftsGet();
		};
		await dataGet();
		setLoading(false);
	}, []);
	const dayShiftsGet = async () => {
		const dayShifts = await DayShifts.get.all().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (dayShifts === undefined) return;
		setDayShifts(dayShifts);
	};
	const dayShiftShow = (dayShift) => show(dayShift);
	const show = (dayShift) => {
		setId(dayShift?._id||null);
		setDayShift(dayShift);
	}
	const cancel = () => show(null);
	const statusGet = (v) => <div className={`status${v.status===commonStatus.ACTIVE?v.status:0}`}>
		<div className="status-point"></div>
	</div>;
	return (
		<>
			<Template>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<Layout
							title="Смены"
							type="statistics"
							data={dayShifts}
							id={id}
							rows={[
								{title:'Номер',field:'number',func:(v) => `Смена №${v.number}`},
								{title:'Дата',field:'date',class:'date',func:(v) => moment(v.date).format('DD.MM.YYYY')},
								{title:'Статус',field:'status',class:'status status-last',func:statusGet}
							]}
							empty={<>Добавьте новую<br/>смену используя пункт меню вверху</>}
							contentShowTitle={`Смена №${dayShift?.number}`}
							contentShow={<>
								<div className="product-view-row-simple">
									Смена за {moment(dayShift?.date).format('DD.MM.YYYY')}
								</div>
								<div className="product-view-row-simple">
									Статус <b>{commonStatusDayShiftName[dayShift?.status]}</b>
								</div>
								<h4>Открыта</h4>
								<div className="product-view-row-simple product-edit-row-oneline">
									<label>Дата и время</label>
									<div>{moment(dayShift?.start).format('DD.MM.YYYY HH:mm:ss')}</div>
								</div>
								<div className="product-view-row-simple product-edit-row-oneline">
									<label>Оветственный</label>
									<div>{dayShift?.users.opener.name}</div>
								</div>
								{dayShift?.users.closer ?
									<>
										<h4>Закрыта</h4>
										<div className="product-view-row-simple product-edit-row-oneline">
											<label>Дата и время</label>
											<div>{moment(dayShift?.end).format('DD.MM.YYYY HH:mm:ss')}</div>
										</div>
										<div className="product-view-row-simple product-edit-row-oneline">
											<label>Оветственный</label>
											<div>{dayShift?.users.opener.name}</div>
										</div>
									</> : null}
							</>}
							onShow={dayShiftShow}
							onClose={cancel}
						/>
					)
				}
			</Template>
		</>
	);
};

export default DayShiftsScreen;