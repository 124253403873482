import React, {useEffect,useState} from 'react';
import {Link} from 'react-router-dom';

// components
import Sidemenus from '../../Globals/Sidemenus';

// helpers
import {ls} from '../../Globals/Localstorage';

// contstants
import { API } from '../../Globals/Constants';

// images
import imgHelp from './Images/help.svg';

// styles
import './styles.css';

const SideMenu = ({title,type,children,tabs}) => {
	const [loading, setLoading] = useState(true);
	const [point, setPoint] = useState(null);
	const [user, setUser] = useState(null);
	const [isShow, setIsShow] = useState(false);
	const [isAll, setIsAll] = useState(false);
	useEffect(async () => {
		const user = ls('user');
		setUser(user);
		const point = ls('point');
		setPoint(point);
		const pointall = ls('pointall');
		setIsAll(pointall);
		setLoading(false);
	}, []);
	const isselect = (page) => {
		const link = window.location.pathname.split('/');
		if (link.length === 4) {
			link.pop();
			page = page.substring(0, page.length - 1);
		}
		return link.join('/') === page;
	};
	const data = Array.isArray(type) ? type.map((v) => (typeof v === 'object' ? {items:Sidemenus[v.type],title:v.title} : {items:Sidemenus[v]})) : [{items:Sidemenus[type]}];
	const linkGet = () => {
		const link = window.location.pathname.split('/');
		link.shift();
		if (link.length === 3) link.pop();
		return link.join('/');
	};
	const showMenu = () => setIsShow(!isShow);
	const pointSwitch = (point) => {
		const p = user.points.find(f => f.id === point.id);
		ls('point', p);
		ls('pointall', false);
		window.location.reload();
	};
	const pointSwitchAll = () => {
		ls('pointall', true);
		window.location.reload();
	};
	const pointsGet = () => {
		const points = user.points;
		points.sort((a,b) => a.id === point.id ? -1 : 1);
		return points;
	};
	return loading ? null :
		<>
			{isShow ? <div className="categories-list-switcher-overlay" onClick={() => setIsShow(false)}></div> : null}
			<div className="components-categories-list">
				<div className="components-categories-list-title">
					<h2>{title}</h2>
					<a href={`${API.help}${linkGet()}`} target='_blank'><img src={imgHelp} alt="" /></a>
				</div>
				{user?.points.length > 1 ?
						<div className="categories-list-switcher" onClick={() => showMenu()}>
							<div className={`categories-list-switcher-menu${isShow?' categories-list-switcher-menu-open':''}`}>
								{isShow ?
										<>
											<div onClick={() => pointSwitchAll()} className={`categories-list-switcher-menu-item${isAll?' categories-list-switcher-menu-item-select':''}`}>
												<b>Все филиалы</b>
											</div>
											{pointsGet().map((v,i) => <div key={i} onClick={() => pointSwitch(v)} className={`categories-list-switcher-menu-item${isAll?'':(v.id===point.id?' categories-list-switcher-menu-item-select':'')}`}>
													<b>{v.name}</b>
												</div>)}
										</>
									:
										<div className="categories-list-switcher-menu-item categories-list-switcher-menu-item-single">
											{isAll ? 'Все филиалы' : point?.name}
										</div>
								}
							</div>
						</div>
					: null}
				{children ? children :
					<div className={`categories-list-container${user?.points.length > 1?' categories-list-container-menu':''}`}>
						{tabs}
						<div className="container-inner">
							<div className="list">
								{data.map((v,i) => <div key={i}>
									{v.title ? <div className="separator">{v.title}</div> : null}
									{v.items.map((v,i) => <Link key={i} to={v.link} className={`category${isselect(v.link)?' category-select':''}`}>
										{v.name}
									</Link>)}
								</div>)}
							</div>
						</div>
					</div>
				}
			</div>
		</>;
};

export default SideMenu;