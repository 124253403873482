import React, {useEffect,useState} from 'react';

// plug-ins
import {useModal} from 'react-hooks-use-modal';
import InputMask from 'react-input-mask';
import {YMaps,Map,ZoomControl,Polygon} from 'react-yandex-maps';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import Layout from '../../../Components/Layout';

// models
import {Addresses, Cities, Points} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';

// constants
import { commonStatus, commonStatusName, MAP } from '../../../Globals/Constants';

// styles
import './styles.css';

// images
import imgClose from './Images/close.svg';
import imgCheck from './Images/check.svg';

// start
const PointsScreen = () => {
	const [ModalChangeStatus, modalChangeStatusOpen, modalChangeStatusClose] = useModal('root');
	const [ModalMap, modalMapOpen, modalMapClose] = useModal('root');
	const [loading, setLoading] = useState(true);
	const [point, setPoint] = useState(null);
	const [pointsAll, setPointsAll] = useState([]);
	const [points, setPoints] = useState([]);
	const [cities, setCities] = useState([]);
	const [addresses, setAddresses] = useState([]);
	const [id, setId] = useState(null);
	const {value:name, setValue:setName, bind:bindName} = useInput('');
	const {value:domain, setValue:setDomain, bind:bindDomain} = useInput('');
	const {value:address, setValue:setAddress, bind:bindAddress} = useInput('');
	const {value:phone, setValue:setPhone, bind:bindPhone} = useInput('');
	const {value:email, setValue:setEmail, bind:bindEmail} = useInput('');
	const {value:color, setValue:setColor, bind:bindColor} = useInput('');
	const [area, setArea] = useState([]);
	const [latitude, setLatitude] = useState(null);
	const [longitude, setLongitude] = useState(null);
	const {value:workTimeDescription, setValue:setWorkTimeDescription, bind:bindWorkTimeDescription} = useInput('');
	const [workTime, setWorkTime] = useState([]);
	const [search, setSearch] = useState(null);
	const [cityId, setCityId] = useState(null);
	const [status, setStatus] = useState(null);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await citiesGet();
			await pointsGet();
			const point = ls('point');
			setPoint(point);
		};
		await dataGet();
		setLoading(false);
	}, []);
	const citiesGet = async () => {
		const cities = await Cities.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (cities === undefined) return;
		setCities(cities);
	};
	const pointsGet = async () => {
		const points = await Points.get.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (points === undefined) return;
		setPoints(points);
		setPointsAll(points);
	};
	const pointAdd = () => show();
	const pointEdit = (point) => {
		point = point || points.find(f => f._id === id);
		show(point);
	};
	const pointShow = (point) => show(point);
	const show = (point) => {
		setId(point?._id||null);
		setName(point?.name||'');
		setDomain(point?.domain||'');
		setAddress(point?.contacts.address||'');
		setPhone(point?.contacts.phone||'');
		setEmail(point?.contacts.email||'');
		setLatitude(point?.properties?.latitude||null);
		setLongitude(point?.properties?.longitude||null);
		setArea(point && point.area ? point.area : []);
		setColor(point?.options?.color||'#00ff00');
		setWorkTimeDescription(point?.properties.workTimeDescription||'');
		setWorkTime(workTimePrepare(point?.properties?.workTime)||[]);
		setCityId(point?.cityId||'');
		setStatus(point?point.status:'');
	};
	const cancel = () => {
		pointShow(null);
		modalChangeStatusHide();
	}
	const save = async () => {
		const data = {
			name:name,
			cityId:cityId,
			domain:domain,
			address:address,
			phone:Utils.phoneNormalize(phone),
			email:email,
			latitude:latitude,
			longitude:longitude,
			area:area,
			color:color||'#00ff00',
			workTime:workTime,
			workTimeDescription:workTimeDescription,
			status:status
		};
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить название филиала');
			return;
		}
		if (Utils.empty(data.cityId)) {
			errorShow('Необходимо выбрать город филиала');
			return;
		}
		if (Utils.empty(data.address)) {
			errorShow('Необходимо заполнить адрес филиала');
			return;
		}
		if (Utils.empty(data.phone)) {
			errorShow('Необходимо заполнить телефон филиала');
			return;
		}
		if (Utils.empty(data.email)) {
			errorShow('Необходимо заполнить электронную почту филиала');
			return;
		}
		if (Utils.empty(data.workTime)) {
			errorShow('Необходимо заполнить время работы филиала');
			return;
		}
		if (Utils.empty(data.area)) {
			errorShow('Необходимо заполнить область обслуживания');
			return;
		}
		const res = id ?
				await Points.update(id, data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				})
			:
				await Points.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
		if (res) {
			successShow(res.message);
			pointsGet();
			cancel();
			return true;
		}
	}
	const searchPoint = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setPoints(pointsAll);
		else setPoints(pointsAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase())));
		setSearch(search);
	}
	const handleCity = (e) => setCityId(e.target.value);
	const handleStatus = (e) => setStatus(parseInt(e.target.value));
	const addressGet = async (e) => {
		const address = e.target.value;
		const addresses = await Addresses.get(address, cityId).catch((ex) => console.log(666, ex));
		setAddress(address);
		setAddresses(addresses);
	}
	const selectAddress = (address) => {
		setAddress(address.address);
		setLatitude(address.latitude);
		setLongitude(address.longitude);
		setAddresses([]);
	}
	const pointSelect = (point) => {
		const user = ls('user');
		const p = user.points.find(v => v.id === point._id);
		ls('point', p);
		setPoint(p);
		cancel();
		return true;
	}
	const isCurrentPoint = (p) => point.id === p._id;
	const pointChangeStatusOn = (id) => pointChangeStatus(id);
	const pointChangeStatusModal = () => pointChangeStatus(id);
	const pointChangeStatus = (id) => {
		const point = points.find(f => f._id === id);
		const status = point.status === commonStatus.ACTIVE ? commonStatus.IN_ACTIVE : commonStatus.ACTIVE;
		Points.status(id, {status}).then((res) => {
			successShow(res.message);
			pointsGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
	}
	const modalChangeStatusHide = () => modalChangeStatusClose();
	const modalChangeStatusShow = (id) => {
		setId(id);
		setName(points.find(f => f._id === id).name);
		modalChangeStatusOpen();
	}
	const workTimePrepare = (workTime) => {
		workTime = workTime||[];
		const wt = [];
		Utils.weekDayName.full.forEach((v,i) => {
			const start = workTime.find(f => f.day === i)?.start||null;
			const end = workTime.find(f => f.day === i)?.end||null;
			wt.push({day:i,start:start,end:end});
		});
		return wt;
	};
	const wtStartSet = (e, i) => {
		const wt = [...workTime||[]];
		wt[i].start = e.target.value;
		setWorkTime(wt);
	}
	const wtEndSet = (e, i) => {
		const wt = [...workTime||[]];
		wt[i].end = e.target.value;
		setWorkTime(wt);
	}
	const cityCoordinatesGet = () => {
		const city = cities.find(f => f._id === cityId);
		const latitude = city?.coordinates?.latitude||MAP.defCoords[0], longitude = city?.coordinates?.longitude||MAP.defCoords[1];
		return [latitude,longitude];
	};
	const mapDraw = (ref) => {
		if (ref) {
			ref.editor.startDrawing();
			ref.geometry.events.add('change', (e) => setArea(e.get('newCoordinates')));
		}
	};
	const cityGet = (id) => {
		const city = cities.find(f => f._id === id);
		return city?.name||'';
	};
	const statusGet = (v) => <div className={`status${v.status===commonStatus.ACTIVE?v.status:0}`}>
		<div className="status-point"></div>
	</div>;
	return (
		<>
			<Template>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<Layout
							title="Филиалы"
							type={[{type:'account'},{type:'settings',title:'Настройки'}]}
							data={points}
							id={id}
							search={search}
							rows={[
								{title:'Название',field:'name',func:(v) => <>{v.name}<span>{v.contacts.address}</span></>},
								{title:'Статус',field:'status',class:'status status-small',func:statusGet},
								{title:'Текущий',field:'current',class:'current',func:(v) => isCurrentPoint(v) ? <img src={imgCheck} alt="" /> : null}
							]}
							rowsShort={['name','status']}
							rowControlItems={[
								{title:(v) => v.status===commonStatus.ACTIVE?'Отключить':'Включить',class:(v) => v.status===commonStatus.ACTIVE?'inactive':'active',action:(v) => v.status === commonStatus.ACTIVE ? modalChangeStatusShow(v._id) : pointChangeStatusOn(v._id)},
								{title:'Сделать текущим',class:'check',action:pointSelect,isskip:(v) => isCurrentPoint(v)}
							]}
							empty={<>Добавьте первый<br/>филиал</>}
							contentShowTitle={name}
							contentShow={<>
								<div className="product-view-row-simple">
									{cityGet(cityId)}
								</div>
								<h4>Контакты</h4>
								<div className="product-view-row-simple">
									{address}
								</div>
								<div className="product-view-row-simple">
									{Utils.phoneFormatter(phone)}
								</div>
								<div className="product-view-row-simple">
									<a href={`mailto:${email}`}>{email}</a>
								</div>
								<div className="product-view-row-simple">
									<a href={`https://${domain.replace('https://','').replace('http://','')}`} target="_blank" rel="noreferrer">{domain}</a>
								</div>
								<h4>Время работы</h4>
								<div className="product-view-row-simple">
									{workTimeDescription}
								</div>
							</>}
							contentEdit={<>
								<div className="product-edit-row">
									<input type="text" {...bindName} placeholder="Название филиала" className="input-title" autoFocus={true} required />
								</div>
								<div className="product-edit-row">
									<div className="select select-wide">
										<select onChange={handleCity} value={cityId} required>
											<option value="">Выберите город</option>
											{cities.map((v,i) => <option key={i} value={v._id}>{v.name}</option>)}
										</select>
									</div>
								</div>
								<div className="product-edit-row">
									<input type="text" {...bindDomain} placeholder="Домен" required />
								</div>
								<h4>Контакты</h4>
								<div className="product-edit-row">
									<input type="text" {...bindAddress} placeholder="Адрес филиала" onChange={addressGet} required />
								</div>
								<div className="product-edit-row-addresses">
									{addresses.length ?
										<div className="point-addresses-list">
											{addresses.map((v,i) => <div key={i} onClick={() => selectAddress(v)}>{v.address}</div>)}
										</div> : null}
									</div>
								<div className="product-edit-row">
									<InputMask type="tel" mask="+7 (999) 999-99-99" placeholder="Телефон филиала" maskPlaceholder={null} {...bindPhone} required />
								</div>
								<div className="product-edit-row">
									<input type="email" {...bindEmail} placeholder="Электронная почта" required />
								</div>

								<h4>Область обслуживания</h4>
								<div className="product-edit-row">
									<div className="product-edit-btn" onClick={() => modalMapOpen()}>{area && area.length ? 'Изменить' : 'Добавить'}</div>
								</div>
								<h4>График работы</h4>
								<div className="product-edit-row">
									<div className="work-time">
										{Utils.weekDayName.full.map((v,i) => <div key={i} className="product-edit-row product-edit-row-oneline">
											<div>{v}</div>
											<div className="product-edit-row-oneline">
												<input type="time" className="time" value={workTime[i]?.start||''} onChange={(e) => wtStartSet(e, i)} required />
												<input type="time" className="time" value={workTime[i]?.end||''} onChange={(e) => wtEndSet(e, i)} required />
											</div>
										</div>)}
									</div>
								</div>
								<div className="product-edit-row">
									<input type="text" {...bindWorkTimeDescription} placeholder="Время работы кратко" required />
								</div>
								<h4>Дополнительные параметры</h4>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="color" className="label-middle">Цвет</label>
									<div className="input">
										<input type="color" id="color" {...bindColor} className="color" />
									</div>
								</div>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="status">Статус</label>
									<div className="select select-middle">
										<select id="status" onChange={handleStatus} value={status} required>
											<option value=""></option>
											{commonStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
										</select>
									</div>
								</div>
							</>}
							onEdit={pointEdit}
							onAdd={pointAdd}
							onSave={save}
							onSearch={searchPoint}
							onShow={pointShow}
							onClose={cancel}
						/>
					)
				}
			</Template>
			<Alert />
			<ModalMap>
				<div className="modal modal-map">
					<div className="map-container">
						<YMaps query={{apikey:MAP.keys.Yandex}}>
							<Map
								state={{center:cityCoordinatesGet(),zoom:12}}
								modules={['geoObject.addon.editor']}
								width={'100%'} height={'100%'}
							>
								<ZoomControl />
								<Polygon 
									geometry={area||[]}
									options={{
										draggable:true,
										editorDrawingCursor:'crosshair',
										strokeOpacity:.5,
										strokeWidth:3,
										fillColor:color,
										opacity:.5
									}}
									properties={{hintContent:name}}
									modules={['geoObject.addon.hint']}
									instanceRef={mapDraw} />
							</Map>
						</YMaps>
						<div className="map-close">
							<img src={imgClose} alt="" onClick={modalMapClose} className="btn-close" />
						</div>
					</div>
				</div>
			</ModalMap>
			<ModalChangeStatus>
				<div className="modal">
					<div className="header">
						<h4>Отключить филиал</h4>
						<img src={imgClose} alt="" onClick={modalChangeStatusHide} className="btn-close" />
					</div>
					<p>Вы уверены что хотите отключить филиал <b>{name}?</b></p>
					<div className="buttons">
						<button type="button" onClick={modalChangeStatusHide} className="btn-cancel">Отменить</button>
						<button type="button" onClick={pointChangeStatusModal} className="btn-accept btn-delete">Отключить</button>
					</div>
				</div>
			</ModalChangeStatus>
		</>
	);
};

export default PointsScreen;