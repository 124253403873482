import React, {useEffect,useState} from 'react';

// plug-ins
import InputMask from 'react-input-mask';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import Layout from '../../../Components/Layout';
import PointsSelect from '../../../Components/PointsSelect';

// models
import {Points, Workshops} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';

// constants
import { commonStatusName } from '../../../Globals/Constants';


// start
const WorkshopsScreen = () => {
	const [loading, setLoading] = useState(true);
	const [points, setPoints] = useState([]);
	const [workshopsAll, setWorkshopsAll] = useState([]);
	const [workshops, setWorkshops] = useState(null);
	const [id, setId] = useState(null);
	const {value:name, setValue:setName, bind:bindName} = useInput('');
	const {value:description, setValue:setDescription, bind:bindDescription} = useInput('');
	const {value:phone, setValue:setPhone, bind:bindPhone} = useInput('');
	const {value:address, setValue:setAddress, bind:bindAddress} = useInput('');
	const {value:contact, setValue:setContact, bind:bindContact} = useInput('');
	const [search, setSearch] = useState(null);
	const [status, setStatus] = useState(null);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await pointsGet();
			await workshopsGet();
		};
		await dataGet();
		setLoading(false);
	}, []);
	const pointsGet = async () => {
		const points = await Points.get.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (points === undefined) return;
		setPoints(points);
	};
	const workshopsGet = async () => {
		const workshops = await Workshops.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (workshops === undefined) return;
		setWorkshops(workshops);
		setWorkshopsAll(workshops);
	};
	const workshopAdd = () => show();
	const workshopEdit = (workshop) => {
		workshop = workshop || workshops.find(f => f._id === id);
		show(workshop);
	};
	const workshopShow = (workshop) => show(workshop);
	const show = (workshop) => {
		setId(workshop?._id||null);
		setName(workshop?.name||'');
		setDescription(workshop?.description||'');
		setPhone(workshop?.contacts.phone||'');
		setContact(workshop?.contacts.contact||'');
		setAddress(workshop?.address||'');
		setStatus(workshop?workshop.status:'');
	};
	const cancel = () => workshopShow(null);
	const save = async () => {
		const data = {
			name:name,
			description:description,
			phone:phone,
			contact:contact,
			address:address,
			status:status
		};
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить название склада');
			return;
		}
		if (data.points.length === 0) {
			errorShow('Необходимо выбрать филиалы');
			return;
		}
		const res = id ?
				await Workshops.update(id, data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				})
			:
				await Workshops.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
		if (res) {
			successShow(res.message);
			workshopsGet();
			cancel();
			return true;
		}
	};
	const searchWorkshops = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setWorkshops(workshopsAll);
		else setWorkshops(workshopsAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase())));
		setSearch(search);
	};
	const workshopDelete = (id) => {
		Workshops.remove(id).then((res) => {
			successShow(res.message);
			workshopsGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
		return true;
	};
	const handleStatus = (e) => setStatus(parseInt(e.target.value));
	const pointGet = (item) => {
		const p = item.points;
		const out = [];
		if (p && p.length !== points.length) {
			for (let i = 0; i < p.length; i++) {
				out.push(points.find(f => f._id === p[i].id).name);
			}
		}
		return out.length === 0 ? 'Все филиалы' : out.join(', ');
	};
	const massCopy = async (ids) => {
		for (const v of ids) {
			const s = workshops.find(f => f._id === v);
			if (s) {
				const data = {
					name:`${s.name} (копия)`,
					description:s.description,
					points:s.points,
					phone:s.phone,
					contact:s.contact,
					address:s.address,
					status:s.status
				};
				await Workshops.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
			}
		};
		successShow('Операция прошла успешно');
		workshopsGet();
		cancel();
		return true;
	};
	const massDelete = async (ids) => {
		for (const v of ids) {
			await Workshops.remove(v).catch((ex) => {
				console.log(666, ex);
				errorShow();
			});
		};
		successShow('Операция прошла успешно');
		workshopsGet();
		cancel();
		return true;
	};
	return (
		<>
			<Template>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<Layout
							title="Склады"
							type="books"
							data={workshops}
							id={id}
							search={search}
							rows={[
								{title:'Название',field:'name'},
								{title:'Филиал',field:'name',class:'area',func:pointGet}
							]}
							rowsShort={['name']}
							footerItems={[
								{title:'Сделать копию',action:massCopy},
								{title:'Удалить',action:massDelete,confirm:true}
							]}
							empty={<>Добавьте первый<br/>склада</>}
							contentShowTitle={name}
							contentShow={<>
								{description ?
									<div className="product-view-row product-view-row-simple" dangerouslySetInnerHTML={{__html: Utils.rn2br(description)}} /> : null}
								{phone || address || contact ? <h4>Контакты</h4> : null}
								{phone ?
									<div className="product-view-row product-view-row-oneline">
										<span>Телефон</span>
										<div>{Utils.phoneFormatter(phone)}</div>
									</div> : null}
								{contact ?
									<div className="product-view-row product-view-row-oneline">
										<span>Контактное лицо</span>
										<div>{contact}</div>
									</div> : null}
								{address ?
									<div className="product-view-row product-view-row-oneline">
										<span>Адрес</span>
										<div>{address}</div>
									</div> : null}
							</>}
							contentEdit={<>
								<div className="product-edit-row">
									<input type="text" {...bindName} placeholder="Название склада" className="input-title" autoFocus={true} required />
								</div>
								<div className="product-edit-row">
									<textarea placeholder="Описание" {...bindDescription}></textarea>
								</div>
								<h4>Контакты</h4>
								<div className="product-edit-row">
									<InputMask type="tel" mask="+7 (999) 999-99-99" placeholder="Телефон" maskPlaceholder={null} {...bindPhone} />
								</div>
								<div className="product-edit-row">
									<input type="text" placeholder="Контактное лицо" {...bindContact} />
								</div>
								<div className="product-edit-row">
									<input type="text" placeholder="Адрес" {...bindAddress} />
								</div>
								<h4>Дополнительные параметры</h4>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="status">Статус</label>
									<div className="select select-middle">
										<select id="status" onChange={handleStatus} value={status} required>
											<option value=""></option>
											{commonStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
										</select>
									</div>
								</div>
							</>}
							onDelete={workshopDelete}
							onEdit={workshopEdit}
							onAdd={workshopAdd}
							onSave={save}
							onSearch={searchWorkshops}
							onShow={workshopShow}
							onClose={cancel}
						/>
					)
				}
			</Template>
			<Alert />
		</>
	);
};

export default WorkshopsScreen;