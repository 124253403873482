import React, {useEffect,useState} from 'react';

// styles
import './styles.css';

// images
import imgOrders from './Images/orders-circle.svg';
import imgOrdersWhite from './Images/orders-white-circle.svg';
import imgComponents from './Images/component-circle.svg';
import imgCategories from './Images/lists-circle.svg';
import imgUsers from './Images/user-circle.svg';
import imgSettings from './Images/settings-circle.svg';
import imgOrder from './Images/cook-circle.svg';
import imgPlus from './Images/plus-circle.svg';
import imgStatistic from './Images/statistic-circle.svg';

// satrt
const Empty = (props) => {
	const [img, setImg] = useState(null);
	const [text, setText] = useState(null);

	useEffect(async () => {
		if (props.image) {
			switch (props.image) {
				case 'orders':
					setImg(imgOrders);
					break;
				case 'orders-white':
					setImg(imgOrdersWhite);
					break
				case 'order':
					setImg(imgOrder);
					break;
				case 'components':
					setImg(imgComponents);
					break;
				case 'caregories':
					setImg(imgCategories);
					break;
				case 'users':
				case 'clients':
					setImg(imgUsers);
					break;
				case 'settings':
					setImg(imgSettings);
					break;
				case 'plus':
					setImg(imgPlus);
					break;
				case 'statistic':
					setImg(imgStatistic);
					break;
				default:
					break;
			}
		}
		if (props.text) setText(props.text);
	}, []);

	return (
		<div className="container-empty">
			<img src={img} alt="" />
			<div className="container-empty-info">{text}</div>
			{props.children}
		</div>
	);
};
export default Empty;