/**
 * 
 * @file index.js
 * @description Компонент Layout
 * @access public
 * @type компонент
 * @returns {JSX}
 * @since 0.1
 * @version 0.1
 * 
 * Компонент Layout
 * 
 * Основной компонент для отображения данных в виде таблицы
 * 
 * Принимает следующие свойства:
 * @param {string} title - заголовок таблицы
 * @param {string} type - тип таблицы
 * @param {array} data - данные для отображения
 * @param {string} id - идентификатор строки
 * @param {string} search - строка поиска
 * @param {array} rows - массив объектов с описанием колонок таблицы
 * @param {array} rowsShort - массив сокращенных колонок таблицы
 * @param {array} footerItems - массив объектов с описанием действий в подвале таблицы
 * @param {array} rowControlItems - массив объектов с описанием действий в строке таблицы
 * @param {boolean} rowControlHide - скрыть действия в строке таблицы
 * @param {function} onSearch - функция поиска
 * @param {function} onAdd - функция добавления
 * @param {function} onShow - функция отображения
 * @param {function} onEdit - функция редактирования
 * @param {function} onDelete - функция удаления
 * @param {function} onSave - функция сохранения
 * @param {function} onSort - функция сортировки
 * @param {function} onClose - функция закрытия
 * @param {string} empty - текст при отсутствии данных
 * @param {string} contentShowTitle - заголовок отображения
 * @param {JSX} contentShow - содержимое отображения
 * @param {JSX} contentEdit - содержимое редактирования
 * @returns {JSX}
 * @example
 * <Layout
 * 	title="Источники заказов"
 * 	type="books"
 * 	data={sources}
 * 	id={id}
 * 	search={search}
 * 	rows={[
 * 		{title:'Название',field:'name',subfield:'url',class:'name',func:(v) => v.name,sort:'name'},
 * 		{title:'Название',field:'name',subfield:'url',class:'name',func:(v) => v.name,sort:() => 'name'},
 * 	]}
 * 	rowsShort={['name']}
 * 	rowControlItems={[
 * 		{title:'Удалить',class:'delete',action:modalDeleteShow}
 * 	]}
 * 	footerItems={[
 * 		{title:'Удалить',action:massDelete},
 * 		{title:'Активировать',action:massActivite,depend:{data:sources,title:'Выберите источники'}},
 * 	]}
 * 	empty={<>Добавьте первую<br/>причину списания</>}
 * 	contentShowTitle={name}
 * 	contentShow={<></>}
 * 	contentEdit={<></>}
 * 	onDelete={sourceDelete}
 * 	onEdit={sourceEdit}
 * 	onAdd={sourceAdd}
 * 	onSave={save}
 * 	onSearch={searchSource}
 * 	onShow={sourceShow}
 * 	onClose={cancel}
 * />
 * 
  */

import React, {useEffect,useState} from 'react';

// plug-ins
import {sortableContainer, sortableElement} from 'react-sortable-hoc';
import {useModal} from 'react-hooks-use-modal';

// components
import SideMenu from '../SideMenu'
import NotFound from '../NotFound';

// helpers
import Utils from '../../Globals/Utils';

// styles
import './styles.css';

// images
import imgPlusButton from './Images/plus-btn.svg';
import imgFilterButton from './Images/filter-btn.svg';
import imgArrowCurveNE from './Images/arrow-curve-ne.svg';
import imgMenuPoints from './Images/menu-points.svg';
import imgEdit from './Images/edit.svg';
import imgCheckOn from './Images/checkbox-on.svg';
import imgCheckOff from './Images/checkbox-off.svg';
import imgCheckSelect from './Images/checkbox-select.svg';
import imgClose from './Images/close.svg';
import imgLoader from './Images/loader.svg';

let panelClose = null;

const Layout = (props) => {
	const [Modal, modalOpen, modalClose] = useModal('root');
	const [ModalDelete, modalDeleteOpen, modalDeleteClose] = useModal('root');
	const [ids, setIds] = useState([]);
	const [actionIndex, setActionIndex] = useState(null);
	const [dependId, setDependId] = useState(null);
	const [depend, setDepend] = useState(null);
	const [sortIndex, setSortIndex] = useState(props.sortIndex||0);
	const [sortType, setSortType] = useState(null);
	const [controlId, setControlId] = useState(null);
	const [id, setId] = useState(null);
	const [name, setName] = useState('');
	const [isShow, setIsShow] = useState(false);
	const [isEditShow, setIsEditShow] = useState(false);
	const [isFilterShow, setIsFilterShow] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	useEffect(async () => {
	}, []);
	const controlSelect = (e, id) => {
		e.stopPropagation();
		setIsFilterShow(false);
		if (id === controlId) setControlId(null);
		else setControlId(id);
	};
	const controlAction = (e, c, v) => {
		e.stopPropagation();
		setIsShow(false);
		setIsEditShow(false);
		setControlId(null);
		c.action(v);
	};
	const cancel = () => {
		setControlId(null);
		panelClose();
		modalClose();
		modalDeleteClose();
		props.onClose();
	};
	panelClose = () => {
		setIsEditShow(false);
		setIsShow(false);
	};
	const cancelMass = () => {
		setIds([]);
		setActionIndex(null);
		setDependId(null)
		setDepend(null);
		setIsLoading(false);
		cancel();
	};
	const save = async (e) => {
		e.preventDefault();
		const res = await props.onSave();
		if (res) cancel();
	};
	const add = () => {
		setIsShow(false);
		setIsEditShow(true);
		setControlId(null);
		props.onAdd();
	};
	const show = (e, v) => {
		e.stopPropagation();
		setIsShow(true);
		setIsEditShow(false);
		props.onShow(v);
	};
	const edit = (e, v) => {
		e.stopPropagation();
		setIsShow(false);
		setIsEditShow(true);
		setControlId(null);
		props.onEdit(v);
	};
	const stop = (e) => e.stopPropagation();
	const select = (e, id) => {
		e.stopPropagation();
		setControlId(null);
		if (ids.includes(id)) setIds(ids.filter((v) => v !== id));
		else setIds([...ids, id]);
	};
	const selectAll = () => {
		if (ids.length === props.data.length || ids.length > 0) cancelMass();
		else setIds(props.data.map((v) => v._id));
	};
	const actionHandle = (e) => {
		const index = parseInt(e.target.value||'-1');
		setActionIndex(index);
		if (index !== -1) {
			const d = props.footerItems[index];
			if (d && d.depend) {
				setDepend(d.depend);
				return;
			}
		}
		setDepend(null);
		setDependId(null);
	};
	const dependHandle = (e) => setDependId(e.target.value||null);
	const deleteShow = (e, id) => {
		e.stopPropagation();
		setId(id);
		setControlId(null);
		setName(props.data.find(f => f._id === id)?.name);
		modalDeleteOpen();
	};
	const remove = async () => {
		const res = await props.onDelete(id);
		if (res) cancel();
	};
	const mass = () => {
		if (ids.length === 0) return;
		if (actionIndex < 0) return;
		const d = props.footerItems[actionIndex];
		if (d && d.action) {
			if (d.depend && dependId === null) return;
			if (d.confirm) modalOpen();
			else massAccept();
		}
	};
	const massAccept = async () => {
		if (ids.length === 0) return;
		if (actionIndex < 0) return;
		const d = props.footerItems[actionIndex];
		if (d && d.action) {
			if (d.depend && dependId === null) return;
			setIsLoading(true);
			const res = await d.action(ids, dependId);
			if (res) cancelMass();
			setIsLoading(false);
		}
	};
	const rowsGet = () => {
		const rows = [];
		for (const r of props.rows) {
			if (isShow || isEditShow) {
				if (props.rowsShort && props.rowsShort.length) {
					if (props.rowsShort.includes(r.field)) {
						rows.push(r);
						continue;
					}
				} else rows.push(r);
			} else rows.push(r);
		}
		return rows;
	};
	const sortGet = () => {
		if (sortType === 1) return ' sort-up';
		if (sortType === -1) return ' sort-down';
		return ' sort-both';
	};
	const sortSet = (i) => {
		if (sortIndex === i) {
			if (sortType === 1) setSortType(-1);
			else setSortType(1);
		} else {
			setSortIndex(i);
			setSortType(1);
		}
	};
	const dataGet = () => {
		const row = rowsGet()[sortIndex];
		if (!row) return props.data;
		const field = row.field, sort = row.sort;
		props.data.sort((a,b) => {
			const key1 = sort ? (typeof sort === 'function' ? sort(a) : sort).split('.').reduce((o,i)=> o[i], a) : a[field];
			const key2 = sort ? (typeof sort === 'function' ? sort(b) : sort).split('.').reduce((o,i)=> o[i], b) : b[field];
			if (sortType === 1) {
				if (key1 > key2) return 1;
				if (key1 < key2) return -1;
			} else if (sortType === -1) {
				if (key1 < key2) return 1;
				if (key1 > key2) return -1;
			}
			return 0;
		});
		return props.data;
	};
	const isControlShow = () => props.rowControlHide === true ? false : (props.onEdit || props.onDelete || (props.rowControlItems && (typeof props.rowControlItems === 'function' || props.rowControlItems.length)));
	const SortableContainer = sortableContainer(({children}) => <tbody>{children}</tbody>);
	const SortableItem = sortableElement(({v, i}) => tableRowRender(v,i));
	const sortEnd = ({oldIndex, newIndex}) => {
		if (oldIndex === newIndex) return;
		const data = props.data, item = data[oldIndex];
		data.splice(oldIndex, 1);
		data.splice(newIndex, 0, item);
		if (props.onSort) props.onSort(data);
	};
	const tableRowRender = (v, i) => <tr key={i} onClick={(e) => show(e, v)} className={props.id===v._id?'row-selected':''}>
		{rowsGet().map((r,i) => <td key={i} className={r.class||''}>
			{r.func ? r.func(v) : v[r.field]}
			{r.subfield ? <span>{v[r.subfield]}</span> : null}
		</td>)}
		{isControlShow() ?
			<td className="control">
				<div className="control-inner">
					{props.footerItems ?
							<img src={ids.includes(v._id) ? imgCheckOn : imgCheckOff} alt="" className="check" onClick={(e) => select(e, v._id)} />
						: null}
					<img src={imgMenuPoints} alt="" onClick={(e) => controlSelect(e, v._id)} />
					{controlId === v._id ?
						<div className="control-block">
							{props.onEdit && (props.editExpression ? props.editExpression(v) : true) ? <div className="edit" onClick={(e) => edit(e, v)}>Редактировать</div> : null}
							{(!props.onEdit || (props.onEdit && props.editExpression && !props.editExpression(v))) && (!props.onDelete || (props.onDelete && props.deleteExpression && !props.deleteExpression(v))) && (!props.rowControlItems || (typeof props.rowControlItems === 'function' ? props.rowControlItems(v).length === 0 : props.rowControlItems.length === 0)) ?
									<div className="empty" onClick={stop}>Нет доступных действий</div>
								:
									(props.rowControlItems && (typeof props.rowControlItems === 'function' ? props.rowControlItems(v).length > 0 : props.rowControlItems.length > 0) ?
										(typeof props.rowControlItems === 'function' ? props.rowControlItems(v) : props.rowControlItems).map((c,i) => c.isskip && c.isskip(v) ? null :
											<div key={i} className={typeof c.class==='function'?c.class(v):c.class} onClick={(e) => controlAction(e, c, v)}>
												{typeof c.class === 'function' ? c.title(v) : c.title}
											</div>)
										: null)
							}
							{props.onDelete && (props.deleteExpression ? props.deleteExpression(v) : true) ? <div className="delete" onClick={(e) => deleteShow(e, v._id)}>Удалить</div> : null}
						</div>
					: null}
				</div>
			</td> : null}
	</tr>;
	const filterShow = (e, isshow) => {
		e.stopPropagation();
		setIsFilterShow(isshow);
		setControlId(null);
		if (isshow) props.filterShow();
	};
	const controlsHide = (e) => {
		setControlId(null);
		filterShow(e, false);
	};
	const filterStop = (e) => e.stopPropagation();
	const filterReset = () => props.filterReset();
	const filterApply = () => {
		props.filterApply();
		setIsFilterShow(false);
		setControlId(null);
	};
	return <>
		<div className="layout-container" onClick={controlsHide}>
			<div className={`categories-container${isShow||isEditShow?'':' categories-container-wide'}`}>
				<SideMenu title={props.title} type={props.type} tabs={props.contentTabs}>
					{props.contentSideMenu}
				</SideMenu>
				<div className="products-list">
					{props.onSearch ?
						<div className="products-list-controls">
							<input type="search" placeholder="Поиск" className={`search${props.onAdd?'':' search-single'}`} onChange={props.onSearch} value={props.search} />
							{props.contentFilter ?
								<>
									<button type="button" className="filter-button" onClick={(e) => filterShow(e, true)}>
										<img src={imgFilterButton} alt="" />
										{props.filterCount ? <span className="count">{props.filterCount}</span> : null}
									</button>
									{isFilterShow ?
											<div className="filter" onClick={filterStop}>
												<h4>Фильтр</h4>
												<img src={imgClose} alt="" className="close" onClick={(e) => filterShow(e, false)} />
												{props.contentFilter}
												<div className="buttons">
													<button type="button" className="btn-second" onClick={filterReset}>Сбросить</button>
													<button type="button" onClick={filterApply}>Применить</button>
												</div>
											</div>
										: null}
								</> : null}
							{props.onAdd ?
								<button type="button" onClick={add}>
									<img src={imgPlusButton} alt="" />
								</button> : null}
						</div> : null}
					<div className={`container-inner${props.footerItems&&ids.length?' container-inner-with-footer':''}${props.onSearch?'':' container-inner-without-addsearch'}`}>
						{props.data === null ?
								<div className="products-empty">
									<div>
										{props.empty}
									</div>
									<img src={imgArrowCurveNE} alt="" />
								</div>
							:
								(props.data.length > 0 ?
										<table className={`items${isControlShow()?'':' items-no-control'}`}>
											<thead>
												<tr>
													{rowsGet().map((v,i) => <th key={i} className={`${Utils.empty(v.title)?'':'sort '}${v.class||''}${sortIndex===i?sortGet():''}`} onClick={() => Utils.empty(v.title) ? null : sortSet(i)}>{v.title}{sortIndex===i?<span></span>:null}</th>)}
													{isControlShow() ?
														<th className="control">
															{props.footerItems ?
																	<img src={ids.length === props.data.length ? imgCheckOn : (ids.length === 0 ? imgCheckOff : imgCheckSelect)} alt="" className="check" onClick={selectAll} />
																: null}
														</th>
													: null}
												</tr>
											</thead>
											{props.onSort ?
													<SortableContainer onSortEnd={sortEnd} lockAxis={'y'} helperClass={'sortable'} pressDelay={200}>
														{dataGet().map((v,i) => <SortableItem key={i} index={i} v={v} />)}
													</SortableContainer>
												:
													<tbody>
														{dataGet().map((v,i) => tableRowRender(v,i))}
													</tbody>
											}
										</table>
									:
										<NotFound />
								)
						}
					</div>
					{props.footerItems ?
						ids.length === 0 ? null :
							<div className="products-list-footer">
								<div className="footer-selects">
									<div className="select">
										<select onChange={actionHandle}>
											<option value="">Выберите действие</option>
											{props.footerItems.map((v,i) => <option key={i} value={i}>{v.title}</option>)}
										</select>
									</div>
									{depend !== null ?
										<div className="select">
											<select onChange={dependHandle}>
												<option value="">{depend.title||'Выберите'}</option>
												{depend.data.map((v,i) => <option key={i} value={v._id}>{v.name}</option>)}
											</select>
										</div> : null}
								</div>
								<div className={`button${actionIndex===-1||actionIndex===''||actionIndex===undefined||actionIndex===null||(depend&&dependId===null)?' button-disable':''}`} onClick={mass}>
									Применить ({ids.length})
								</div>
							</div>
						: null}
				</div>
			</div>
			{isShow || isEditShow ?
					<div className="product-edit">
						{isShow ?
								<div className="product-edit-list-container">
									<div className="container-inner">
										<div className="list">
											<div className="product-view-row product-view-row-simple product-view-row-oneline product-view-row-oneline-up">
												<h3>{props.contentShowTitle}</h3>
												{props.hideEditExpression && props.hideEditExpression() ? null :
													(props.onEdit ?
														<img src={imgEdit} alt="" className="edit" onClick={(e) => edit(e)} /> : null
													)
												}
											</div>
											{props.contentShow}
										</div>
									</div>
									<div className="product-edit-footer">
										<button type="button" onClick={cancel} className="btn-cancel btn-cancel-wide">Закрыть</button>
									</div>
								</div>
							: null}
						{isEditShow ?
								<form className="product-edit-list-container" onSubmit={save}>
									<div className="container-inner">
										{props.contentEdit}	
									</div>
									{props.onSave ?
											<div className="product-edit-footer">
												<button type="button" onClick={cancel} className="btn-cancel">Отменить</button>
												<button type="submit" className="btn-save">Сохранить</button>
											</div>
										:
											props.closeIfNotSave ?
													<div className="product-edit-footer">
														<button type="button" onClick={cancel} className="btn-cancel btn-cancel-wide">Закрыть</button>
													</div>
												: null
										}
								</form>
							: null}
					</div>
				: null}
		</div>;
		<Modal>
			<div className="modal modal-layout">
				<div className="header">
					<h4>Внимание!</h4>
					<img src={imgClose} alt="" onClick={modalClose} className="btn-close" />
				</div>
				<p>
					Вы уверены что хотите совершить действие?
					Некоторые данные могут быть изменены без возможности восстановления.
				</p>
				<div className="buttons">
					<button type="button" onClick={modalClose} className="btn-cancel">Отменить</button>
					{isLoading ?
							<button type="button" className="btn-accept btn-delete btn-delete-disable">
								<img src={imgLoader} alt="" />
							</button>
						:
							<button type="button" onClick={massAccept} className="btn-accept btn-delete">Да</button>
					}
				</div>
			</div>
		</Modal>
		<ModalDelete>
			<div className="modal modal-layout">
				<div className="header">
					<h4>Удаление</h4>
					<img src={imgClose} alt="" onClick={modalDeleteClose} className="btn-close" />
				</div>
				<p>Вы уверены что хотите удалить {name ? <b>{name}</b> : 'позицию'}?</p>
				<div className="buttons">
					<button type="button" onClick={modalDeleteClose} className="btn-cancel">Отменить</button>
					<button type="button" onClick={remove} className="btn-accept btn-delete">Удалить</button>
				</div>
			</div>
		</ModalDelete>
	</>
};

export default Layout;

export {
	panelClose
};