import React, {useEffect,useState} from 'react';

// plug-ins
import moment from 'moment';
import { hexToCSSFilter } from 'hex-to-css-filter';
import {useModal} from 'react-hooks-use-modal';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Empty from '../../../Components/Empty';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import Layout from '../../../Components/Layout';
import PointsSelect from '../../../Components/PointsSelect';
import Accoirdion from '../../../Components/Accoirdion';

// models
import {Categories, ComponentCategories, Components, Points, ProductOptionGroups, Products, Semifinishes, Tags} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';
import Orfo from '../../../Globals/Orfo';

// constants
import { API, commonStatus, commonStatusName, deliveryType as delType, deliveryTypeName, measureType, measureTypeName, sourceType, sourceTypeName } from '../../../Globals/Constants';

// styles
import './styles.css';

// images
import imgPlus from './Images/plus-black.svg';
import imgPlusButton from './Images/plus-btn.svg';
import imgArrowCurveDown from './Images/arrow-curve-down.svg';
import imgClose from './Images/close.svg';
import imgCheckOff from './Images/checkbox-off.svg';
import imgCheckOn from './Images/checkbox-on.svg';
import imgNoPhoto from './Images/no-photo.svg';
import imgAdd from './Images/add.svg';
// tags
import iconCheck from './Images/icons/icon-check.svg';
import iconFire from './Images/icons/icon-fire.svg';
import iconAsterisk from './Images/icons/icon-asterisk.svg';
import iconHeart from './Images/icons/icon-heart.svg';
import iconStar from './Images/icons/icon-star.svg';
import iconThumb from './Images/icons/icon-thumb.svg';
// icons
const icons = [
	{img:iconCheck,id:'icon-check.svg'},
	{img:iconFire,id:'icon-fire.svg'},
	{img:iconAsterisk,id:'icon-asterisk.svg'},
	{img:iconHeart,id:'icon-heart.svg'},
	{img:iconStar,id:'icon-star.svg'},
	{img:iconThumb,id:'icon-thumb.svg'}
];


// start
const ProductsScreen = () => {
	const componentAmountGet = (t) => {
		if (isProduct) {
			const cnt = t.id === 'count' ? t.value : itemCount || 0;
			const amt = t.id === 'amount' ? t.value : amount || 0;
			const a = amt * cnt;
			const w = (component.properties.weight * cnt) / 1000;
			setTotalWeight(w > 0 ? Number(w.toFixed(3)) : 0);
			setTotalAmount(a > 0 ? Number(a.toFixed(2)) : 0);
			return;
		}
		const netto = t.id === 'netto' ? t.value : itemNetto || 0;
		const loss = t.id === 'loss' ? t.value : itemLoss || 0;
		const amt = t.id === 'amount' ? t.value : amount || 0;
		const w = netto - loss;
		const a = amt * w;
		setTotalWeight(w > 0 ? Number(w.toFixed(3)) : 0);
		setTotalAmount(a > 0 ? Number(a.toFixed(2)) : 0);
	};
	const [ModalPriceChangeMass, modalPriceChangeMassOpen, modalPriceChangeMassClose] = useModal('root');
	const [ModalOptionGroups, modalOptionGroupsOpen, modalOptionGroupsClose] = useModal('root');
	const [ModalProductsRelated, modalProductsRelatedOpen, modalProductsRelatedClose] = useModal('root');
	const [ModalProductsSupplement, modalProductsSupplementOpen, modalProductsSupplementClose] = useModal('root');
	const [ModalProductsSkus, modalProductsSkusOpen, modalProductsSkusClose] = useModal('root');
	const [ModalProducts, modalProductsOpen, modalProductsClose] = useModal('root');
	const [ModalComponent, modalComponentOpen, modalComponentClose] = useModal('root');
	const [ModalItem, modalItemOpen, modalItemClose] = useModal('root');
	const [ModalComponentCategory, modalComponentCategoryOpen, modalComponentCategoryClose] = useModal('root');
	const [ModalItemComponents, modalItemComponentsOpen, modalItemComponentsClose] = useModal('root');
	const [ModalSemifinishes, modalSemifinishesOpen, modalSemifinishesClose] = useModal('root');
	const [ModalCategory, modalCategoryOpen, modalCategoryClose] = useModal('root');
	const [ModalChangeStatus, modalChangeStatusOpen, modalChangeStatusClose] = useModal('root');
	const [ModalStop, modalStopOpen, modalStopClose] = useModal('root');
	const [ModalPriceChange, modalPriceChangeOpen, modalPriceChangeClose] = useModal('root');
	const [loading, setLoading] = useState(true);
	const [tab, setTab] = useState(1);
	const [filter, setFilter] = useState({});
	const [filterTemp, setFilterTemp] = useState({});
	const [partnerId, setPartnerId] = useState(null);
	const [productsAll, setProductsAll] = useState([]);
	const [products, setProducts] = useState(null);
	const [productsSupplement, setProductsSupplement] = useState([]);
	const [productsRelated, setProductsRelated] = useState([]);
	const [productsSkus, setProductsSkus] = useState([]);
	const [productsComponents, setProductsComponents] = useState([]);
	const [related, setRelated] = useState([]);
	const [supplements, setSupplements] = useState([]);
	const [skus, setSkus] = useState([]);
	const [tags, setTags] = useState([]);
	const [tagsAll, setTagsAll] = useState([]);
	const {value:relatedTitle, setValue:setRelatedTitle, bind:bindRelatedTitle} = useInput('');
	const [categories, setCategories] = useState([]);
	const [productOptionGroups, setProductOptionGroups] = useState([]);
	const [productOptionGroupsAll, setProductOptionGroupsAll] = useState([]);
	const [points, setPoints] = useState([]);
	const [category, setCategory] = useState(null);
	const [id, setId] = useState(null);
	const {value:productLink, setValue:setProductLink, bind:bindProductLink} = useInput('');
	const {value:productName, setValue:setProductName, bind:bindProductName} = useInput('');
	const {value:productWeight, setValue:setProductWeight, bind:bindProductWeight} = useInput('');
	const {value:productDescription, setValue:setProductDescription, bind:bindProductDescription} = useInput('');
	const {value:productPrice, setValue:setProductPrice, bind:bindProductPrice} = useInput('');
	const {value:productPriceFull, setValue:setProductPriceFull, bind:bindProductPriceFull} = useInput('');
	const {value:productDiscount, setValue:setProductDiscount, bind:bindProductDiscount} = useInput('');
	const {value:productKkal, setValue:setProductKkal, bind:bindProductKkal} = useInput('');
	const {value:productProteins, setValue:setProductProteins, bind:bindProductProteins} = useInput('');
	const {value:productFats, setValue:setProductFats, bind:bindProductFats} = useInput('');
	const {value:productCarbohydrates, setValue:setProductCarbohydrates, bind:bindProductCarbohydrates} = useInput('');
	const {value:productSortOrder, setValue:setProductSortOrder, bind:bindProductSortOrder} = useInput('');
	const {value:productArticle, setValue:setProductArticle, bind:bindProductArticle} = useInput('');
	const [productImages, setProductImages] = useState([]);
	const [productImageMainIndex, setProductImageMainIndex] = useState(null);
	const [productPoints, setProductPoints] = useState([]);
	const [categoryId, setCategoryId] = useState(0);
	const [productMeasureType, setProductMeasureType] = useState(measureType.GRAMS);
	const [days, setDays] = useState([]);
	const [deliveryType, setDeliveryType] = useState([]);
	const [sources, setSources] = useState([]);
	const {value:categoryName, setValue:setCategoryName, bind:bindCategoryName} = useInput('');
	const {value:categoryDescription, setValue:setCategoryDescription, bind:bindCategoryDescription} = useInput('');
	const {value:categoryLink, setValue:setCategoryLink, bind:bindCategoryLink} = useInput('');
	const {value:categorySortOrder, setValue:setCategorySortOrder, bind:bindCategorySortOrder} = useInput('');
	const [categoryStatus, setCategoryStatus] = useState('');
	const [isCategoryHide, setIsCategoryHide] = useState(false);
	const [categoryPoints, setCategoryPoints] = useState([]);
	const [search, setSearch] = useState(null);
	const {value:componentCategoryName, setValue:setComponentCategoryName, bind:bindComponentCategoryName} = useInput('');
	const [items, setItems] = useState([]);
	const [optionGroups, setOptionGroups] = useState([]);
	const {value:amount, setValue:setAmount, bind:bindAmount} = useInput('', componentAmountGet);
	const {value:itemBrutto, setValue:setItemBrutto, bind:bindItemBrutto} = useInput('');
	const {value:itemNetto, setValue:setItemNetto, bind:bindItemNetto} = useInput('', componentAmountGet);
	const {value:itemLoss, setValue:setItemLoss, bind:bindItemLoss} = useInput('', componentAmountGet);
	const {value:itemCount, setValue:setItemCount, bind:bindItemCount} = useInput('', componentAmountGet);
	const [totalWeight, setTotalWeight] = useState(0);
	const [totalAmount, setTotalAmount] = useState(0);
	const [component, setComponent] = useState(null);
	const [components, setComponents] = useState([]);
	const [componentCategoryId, setComponentCategoryId] = useState(null);
	const [componentCategories, setComponentCategories] = useState([]);
	const {value:componentName, setValue:setComponentName, bind:bindComponentName} = useInput('');
	const {value:componentDescription, setValue:setComponentDescription, bind:bindComponentDescription} = useInput('');
	const {value:componentWeight, setValue:setComponentWeight, bind:bindComponentWeight} = useInput('');
	const {value:componentMeasureType, setValue:setComponentMeasureType, bind:bindComponentMeasureType} = useInput('');
	const {value:componentPrice, setValue:setComponentPrice, bind:bindComponentPrice} = useInput('');
	const {value:componentInvoicePrice, setValue:setComponentInvoicePrice, bind:bindComponentInvoicePrice} = useInput('');
	const {value:componentMinimum, setValue:setComponentMinimum, bind:bindComponentMinimum} = useInput('');
	const {value:componentRequestCount, setValue:setComponentRequestCount, bind:bindComponentRequestCount} = useInput('');
	const {value:productChangePrice, setValue:setProductChangePrice, bind:bindProductChangePrice} = useInput('');
	const [componentStatus, setComponentStatus] = useState('');
	const [isPieces, setIsPieces] = useState(false);
	const [semifinishes, setSemifinishes] = useState([]);
	const [isSemifinish, setIsSemifinish] = useState(undefined);
	const [isProduct, setIsProduct] = useState(undefined);
	const [productIsNew, setProductIsNew] = useState(false);
	const [productIsTop, setProductIsTop] = useState(false);
	const [productIsStore, setProductIsStore] = useState(false);
	const [productIsStop, setProductIsStop] = useState(false);
	const [productIsStopTill, setProductIsStopTill] = useState(false);
	const {value:isStopTill, setValue:setIsStopTill, bind:bindIsStopTill} = useInput('');
	const [productSiteHide, setProductSiteHide] = useState(false);
	const [ids, setIds] = useState([]);
	const {value:priceSumChange, setValue:setPriceSumChange, bind:bindPriceSumChange} = useInput('');
	const {value:pricePercentChange, setValue:setPricePercentChange, bind:bindPricePercentChange} = useInput('');
	const [isPriceRound, setIsPriceRound] = useState(true);
	const [idsFinal, setIdsFinal] = useState([]);
	const [status, setStatus] = useState(null);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await pointsGet();
			await componentCategoriesGet();
			await componentsGet();
			await semifinishesGet();
			await categoriesGet();
			await productOptionGroupsGet();
			await tagsGet();
			await productsGet();
			const point = ls('point');
			setPartnerId(point.partnerId);
		};
		await dataGet();
		setLoading(false);
	}, []);
	const pointsGet = async () => {
		const points = await Points.get.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (points === undefined) return;
		setPoints(points);
	};
	const componentsGet = async () => {
		const components = await Components.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (components === undefined) return;
		setComponents(components);
	};
	const componentCategoriesGet = async () => {
		const categories = await ComponentCategories.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (categories === undefined) return;
		setComponentCategories(categories);
	};
	const categoriesGet = async () => {
		const categories = await Categories.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (categories === undefined) return;
		setCategories(categories);
	};
	const semifinishesGet = async () => {
		const semifinishes = await Semifinishes.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (semifinishes === undefined) return;
		setSemifinishes(semifinishes);
	};
	const tagsGet = async () => {
		const tags = await Tags.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (tags === undefined) return;
		setTagsAll(tags);
	};
	const productOptionGroupsGet = async () => {
		const groups = await ProductOptionGroups.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (groups === undefined) return;
		setProductOptionGroups(groups);
		setProductOptionGroupsAll(groups);
	};
	const productsGet = async () => {
		const products = await Products.get.all().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (products === undefined) return;
		const point = ls('point');
		const pointall = ls('pointall');
		const pointId = pointall ? null : point.id;
		let p = pointId ? products.filter(f => f.points.find(v => v.id === pointId)) : products;
		setProductsAll(p);
		if (category) p = p.filter(f => f.categoryId === category._id);
		setProducts(p);
		setProductsRelated(p);
		setProductsSupplement(p);
		setProductsSkus(p);
		setProductsComponents(p);
	};
	const productAdd = () => {
		show();
		setCategoryId(category?._id||0);
	}
	const productEdit = (product) => {
		product = product || products.find(f => f._id === id);
		show(product);
	};
	const productShow = (product) => show(product);
	const show = (product) => {
		setId(product?._id||null);
		setProductLink(product?.link||'');
		setProductName(product?.name||'');
		setProductDescription(product?.description||'');
		setProductPrice(product?.prices.price||'');
		setProductPriceFull(product?.prices.priceFull||'');
		setProductDiscount(product?.discounts.discount||'');
		setProductWeight(product?.properties.weight||'');
		setProductKkal(product?.properties.kkal||'');
		setProductProteins(product?.properties.proteins||'');
		setProductFats(product?.properties.fats||'');
		setProductCarbohydrates(product?.properties.carbohydrates||'');
		setProductArticle(product?.properties.article||'');
		setProductSortOrder(product?.options.sortOrder||'');
		setProductIsNew(product?.options.isNew||false);
		setProductIsTop(product?.options.isTop||false);
		setProductIsStop(product?.options.isStop||false);
		setProductIsStopTill(product?.options.isStopTill||null);
		setProductSiteHide(product?.options.siteHide||false);
		setProductIsStore(product?.options.isStore||false);
		setCategoryId(product?.categoryId||0);
		setStatus(product?product.status:'');
		setProductImages(product ? product.images.map((v) => imageUrlGet(product.partnerId, product._id, v)) : []);
		setProductImageMainIndex(product ? product.images.findIndex(f => f === product.image) : null);
		setProductPoints(product ? product.points : points.map(v => ({id:v._id,name:v.name})));
		setItems(product?.components||[]);
		setOptionGroups(product?.optionGroups||[]);
		setDays(product && product.options?.days && product.options.days && product.options.days.length ? product.options.days : productDaysSet());
		setDeliveryType(product && product.options?.deliveryType && product.options.deliveryType && product.options.deliveryType.length ? product.options.deliveryType : productDeliveryTypeSet());
		setSources(product && product.options && product.options.sources && product.options.sources.length ? product.options.sources : productSourcesSet());
		setSupplements(product?.supplements||[]);
		setSkus(product?.skus||[]);
		setRelated(product?.related?.products||[]);
		setRelatedTitle(product?.related?.title||'');
		setTags(product?.tags||[]);
	};
	const productDaysSet = () => [true,true,true,true,true,true,true];
	const productDeliveryTypeSet = () => Object.values(delType).slice(1).map(v => v);
	const productSourcesSet = () => Object.values(sourceType).slice(1).map(v => v);
	const cancel = () => {
		productShow(null);
		modalChangeStatusHide();
		modalStopHide();
		modalCategoryHide();
		modalItemComponentsClose();
		modalItemClose();
		modalComponentCategoryClose();
		modalComponentClose();
		modalCategoryClose();
		modalSemifinishesClose();
		modalProductsClose();
		modalStopClose();
		modalProductsSkusClose();
		modalProductsSupplementClose();
		modalProductsRelatedClose();
		modalOptionGroupsClose();
		modalPriceChangeClose();
	};
	const categoryAddShow = (category) => {
		setCategoryId(category?._id||null);
		setCategoryName(category?.name||'');
		setCategoryDescription(category?.description||'');
		setCategorySortOrder(category?.options?.sortOrder||'');
		setIsCategoryHide(category?.options?.isHide||false);
		setCategoryLink(category?.link||'');
		setCategoryStatus(category?category.status:'');
		setCategoryPoints(category ? category.points : points.map(v => ({id:v._id,name:v.name})));
		modalCategoryOpen();
	}
	const categorySelect = (cat) => {
		if (category) {
			if (category._id === cat._id) return categorySelectAll();
		}
		const products = productsAll.filter(f => f.categoryId === cat._id);
		setCategory(cat);
		setProducts(products);
	}
	const categorySelectAll = () => {
		setCategory(null);
		setProducts(productsAll);
	}
	const categorySave = async (e) => {
		e.preventDefault();
		const data = {
			name:categoryName,
			description:categoryDescription,
			points:categoryPoints,
			sortOrder:categorySortOrder,
			isHide:isCategoryHide,
			link:categoryLink,
			status:categoryStatus
		};
		if (data.points.length === 0) {
			errorShow('Необходимо выбрать филиалы');
			return;
		}
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить название категории');
			return;
		}
		const res = categoryId ?
				await Categories.update(categoryId, data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				})
			:
				await Categories.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
		if (res) {
			successShow(res.message);
			categoriesGet();
			cancel();
		}
	}
	const productSave = async () => {
		const data = {
			link:productLink,
			name:productName,
			description:productDescription,
			categoryId:categoryId,
			price:productPrice,
			priceFull:fullPriceGet(),
			discount:productDiscount,
			weight:productWeight,
			measureType:productMeasureType,
			kkal:productKkal,
			proteins:productProteins,
			fats:productFats,
			carbohydrates:productCarbohydrates,
			productArticle:productArticle,
			sortOrder:productSortOrder,
			isNew:productIsNew,
			isTop:productIsTop,
			isStop:productIsStop,
			isStopTill:productIsStopTill,
			siteHide:productSiteHide,
			isStore:productIsStore,
			components:items,
			points:productPoints,
			status:status
		};
		if (days.length > 0) data.days = days;
		if (deliveryType.length > 0) data.deliveryType = deliveryType;
		if (sources.length > 0) data.sources = sources;
		if (related.length > 0) {
			data.related = related.map(v => v.id);
			data.relatedTitle = relatedTitle;
		}
		if (supplements.length > 0) data.supplements = supplements.map(v => v.id);
		if (skus.length > 0) data.skus = skus.map((v) => ({id:v.id,title:v.title}));
		if (optionGroups.length > 0) data.groups = optionGroups.map(v => v.id);
		if (tags.length > 0) data.tags = tags;
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить название блюда');
			return;
		}
		if (Utils.empty(data.description)) {
			errorShow('Необходимо заполнить описание блюда');
			return;
		}
		if (Utils.empty(data.categoryId)) {
			errorShow('Необходимо выбрать категорию');
			return;
		}
		if (Utils.empty(data.price)) {
			errorShow('Необходимо указать цену блюда');
			return;
		}
		if (data.points.length === 0) {
			errorShow('Необходимо выбрать филиалы');
			return;
		}
		if (productImages.length === 0) {
			errorShow('Необходимо добавить фотографии блюда');
			return;
		}
		const res = id ?
				await Products.update(id, data).catch((ex) => {
					console.log(666, ex);
					errorShow(ex.message);
				})
			:
				await Products.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow(ex.message);
				});
		if (res) {
			imagesUpload(id||res.id);
			imagesUpdate(id||res.id);
			successShow(res.message);
			productsGet();
			cancel();
			return true;
		}
	}
	const imagesUpdate = async (id) => {
		const images = [];
		productImages.forEach((v,i) => {
			const data = imagePrepare(v);
			if (data) images.push(`${id}-${i}.${Utils.imageExtGet(v)}`);
		});
		if (images.length === 0) return;
		const image = images[productImageMainIndex] || images[0];
		await Products.image.update(id, {images,image}).catch((ex) => console.log(666, ex));
	}
	const imagesUpload = async (id) => {
		productImages.forEach((v,i) => {
			const data = imagePrepare(v);
			if (data) {
				const type = Utils.imageExtGet(v);
				Products.image.add(id, {data,type,index:i}).catch((ex) => console.log(666, ex));
			}
		});
	}
	const imagePrepare = (image) => Utils.isImage(image) ? image.replace(/^.*,/gi,'') : null;
	const handleMeasureType = (e) => {
		const measureType = e.target.value;
		setProductMeasureType(parseInt(measureType));
	}
	const handleCategory = (e) => setCategoryId(e.target.value);
	const handleStatus = (e) => setStatus(parseInt(e.target.value));
	const handleCategoryStatus = (e) => setCategoryStatus(parseInt(e.target.value));
	const handleComponentStatus = (e) => setComponentStatus(parseInt(e.target.value));
	const productChangeStatusOn = (id) => productChangeStatus(id);
	const productChangeStatusModal = () => productChangeStatus(id);
	const productChangeStatus = (id) => {
		const product = products.find(f => f._id === id);
		const status = product.status === commonStatus.ACTIVE ? commonStatus.IN_ACTIVE : commonStatus.ACTIVE;
		Products.status(id, {status}).then((res) => {
			successShow(res.message);
			productsGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
	}
	const productStopOff = (id) => productStop(id);
	const productStopModal = () => productStop(id);
	const productStop = async (id, stop, norelaod) => {
		const product = products.find(f => f._id === id);
		const data = stop === undefined ? {isStop:product.options?.isStop ? false : true} : {isStop:stop};
		if (data.isStop) {
			if (isStopTill) {
				const d = parseInt(isStopTill);
				const date = moment().add(d > 24 ? 24 : d, 'h').format();
				data.isStopTill = date;
			}
		}
		const res = await Products.patch(id, data).catch((ex) => {
			console.log(666, ex);
			errorShow();
		});
		if (res) {
			if (norelaod === undefined) {
				successShow(res.message);
				productsGet();
			}
		}
		cancel();
	}
	const modalStopHide = () => modalStopClose();
	const modalStopShow = (id) => {
		setId(id);
		setIsStopTill('');
		setProductName(products.find(f => f._id === id).name);
		modalStopOpen();
	}
	const modalChangeStatusHide = () => modalChangeStatusClose();
	const modalChangeStatusShow = (id) => {
		setId(id);
		setProductName(products.find(f => f._id === id).name);
		modalChangeStatusOpen();
	}
	const modalCategoryHide = () => {
		setCategoryId('');
		modalCategoryClose();
	}
	const handleImage = (e) => {
		const file = e.target.files?.[0];
		if (!file) return;
		const reader = new FileReader();
		reader.onload = (r) => setProductImages([...productImages, r.target.result]);
		reader.readAsDataURL(file);
	};
	const fullPriceGet = (p) => {
		const price = parseFloat(p||productPrice) || 0;
		const discount = parseFloat(productDiscount) || 0;
		return price - (price * discount / 100);
	}
	const searchProduct = (e) => {
		const search = e.target.value;
		setSearch(search);
		if (Utils.empty(search)) setProducts(productsAll);
		else setProducts(productsAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase())));
	}
	const searchProductRelated = (e) => {
		const search = e.target.value;
		setSearch(search);
		if (Utils.empty(search)) setProductsRelated(productsAll);
		else setProductsRelated(productsAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase())));
	}
	const searchProductSupplement = (e) => {
		const search = e.target.value;
		setSearch(search);
		if (Utils.empty(search)) setProductsSupplement(productsAll);
		else setProductsSupplement(productsAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase())));
	}
	const searchProductSkus = (e) => {
		const search = e.target.value;
		setSearch(search);
		if (Utils.empty(search)) setProductsSkus(productsAll);
		else setProductsSkus(productsAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase())));
	}
	const searchProductModal = (e) => {
		const search = e.target.value;
		setSearch(search);
		if (Utils.empty(search)) setProductsComponents(productsAll);
		else setProductsComponents(productsAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase())));
	};
	const searchOptionGroups = (e) => {
		const search = e.target.value;
		setSearch(search);
		if (Utils.empty(search)) setProductOptionGroups(productOptionGroupsAll);
		else setProductOptionGroups(productOptionGroupsAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase())));
	};
	const imageError = (e) => e.target.src = imgNoPhoto;
	const imageUrlGet = (partnerId, productId, filename) => `${API.assets}partners/${partnerId}/products/${productId}/${filename}`;
	const productDelete = (id) => {
		Products.remove(id).then((res) => {
			successShow(res.message);
			productsGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
		return true;
	}
	const pointSelect = (point) => {
		const p = productPoints.find(f => f.id === point._id);
		if (p) setProductPoints(productPoints.filter(f => f.id !== point._id));
		else setProductPoints([...productPoints, {id:point._id,name:point.name}]);
	}
	const imageMainSet = (index) => setProductImageMainIndex(index);
	const sourceSet = (item) => {
		if (sources.includes(item)) setSources(sources.filter(f => f !== item));
		else setSources([...sources, item]);
	}
	const deliveryTypeSet = (item) => {
		if (deliveryType.includes(item)) setDeliveryType(deliveryType.filter(f => f !== item));
		else setDeliveryType([...deliveryType, item]);
	}
	const tagSet = (item) => {
		if (tags.includes(item)) setTags(tags.filter(f => f !== item));
		else setTags([...tags, item]);
	}
	const linkCreate = (e) => {
		const name = e.target.value;
		setCategoryLink(Utils.translit(name));
		setCategoryName(name);
	}
	const pointCategorySelect = (point) => {
		const p = categoryPoints.find(f => f.id === point._id);
		if (p) setCategoryPoints(categoryPoints.filter(f => f.id !== point._id));
		else setCategoryPoints([...categoryPoints, {id:point._id,name:point.name}]);
	}
	const componentsItemShow = () => modalItemComponentsOpen();
	const componentSelect = (component) => {
		setComponent(component);
		const amount = component.prices?.manual||component.prices?.invoice||0;
		setAmount(amount);
		modalItemComponentsClose();
		setIsSemifinish(false);
		setIsProduct(false);
	}
	const componentAdd = async (e) => {
		e.preventDefault();
		if (!component) {
			errorShow('Необходимо выбрать компонент');
			return;
		}
		const data = {
			id:component._id,
			name:component.name,
			brutto:parseFloat(isSemifinish ? itemNetto : itemBrutto),
			netto:parseFloat(itemNetto),
			loss:parseFloat(isSemifinish || isProduct ? 0 : itemLoss),
			amount:parseFloat(amount),
			weight:totalWeight,
			total:totalAmount,
			issemifinish:isSemifinish,
			isproduct:isProduct
		};
		if (isProduct) {
			data.brutto = (component.properties.weight * itemCount) / 1000;
			data.netto = data.brutto;
		}
		if (Utils.empty(data.brutto) || Utils.empty(data.netto) || Utils.empty(data.loss) || Utils.empty(data.amount)) {
			errorShow('Необходимо заполнить все поля');
			return;
		}
		setItems([...items, data]);
	}
	const componentCategoryShow = () => {
		setComponentCategoryName('');
		modalComponentCategoryOpen();
	}
	const componentCategorySave = async () => {
		if (Utils.empty(componentCategoryName)) return;
		const data = {name:componentCategoryName};
		const res = await ComponentCategories.add(data).catch((ex) => {
			console.log(666, ex);
			errorShow();
		});
		if (res) {
			successShow(res.message);
			componentCategoriesGet();
			modalComponentCategoryClose();
		}
	}
	const componentAddShow = () => {
		setComponentName('');
		setComponentDescription('');
		setComponentPrice('');
		setComponentInvoicePrice('');
		setComponentMinimum('');
		setComponentRequestCount('');
		setComponentWeight('');
		setComponentMeasureType('');
		setIsPieces(false);
		setComponentStatus('');
		modalComponentOpen();
	}
	const handleComponentCategory = (e) => setComponentCategoryId(e.target.value);
	const handleComponentMeasureType = (e) => setComponentMeasureType(parseInt(e.target.value));
	const itemShow = () => {
		setAmount('');
		setItemBrutto('');
		setItemNetto('');
		setItemLoss('');
		setItemCount('');
		setTotalWeight(0);
		setTotalAmount(0);
		setComponent(null);
		setComponentCategoryId(null);
		setIsSemifinish(undefined);
		setIsProduct(undefined);
		modalItemOpen();
	}
	const componentSave = async (e) => {
		e.preventDefault();
		const data = {
			categoryId:componentCategoryId,
			name:componentName,
			description:componentDescription,
			price:componentPrice,
			requestCount:componentRequestCount,
			minimum:componentMinimum,
			weight:componentWeight,
			measure:componentMeasureType,
			isPieces:isPieces
		};
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить название компонента');
			return;
		}
		if (Utils.empty(data.weight)) {
			errorShow('Необходимо заполнить объем / вес компонента');
			return;
		}
		if (data.measure === null) {
			errorShow('Необходимо заполнить единицу измерения компонента');
			return;
		}
		const res = id ?
				await Components.update(id, data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				})
			:
				await Components.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
		if (res) {
			successShow(res.message);
			componentsGet();
			modalComponentClose();
		}
	}
	const semifinishesShow = () => modalSemifinishesOpen();
	const semifinishSelect = (semifinish) => {
		setComponent(semifinish);
		const amount = semifinish.prices?.manual||semifinish.prices?.calculate||0;
		setAmount(amount);
		modalSemifinishesClose();
		setIsSemifinish(true);
		setIsProduct(false);
	}
	const componentProductShow = () => modalProductsOpen();
	const productComponentSelect = (product) => {
		setComponent(product);
		const amount = product.prices?.priceFull||0;
		setAmount(amount);
		modalProductsClose();
		setIsSemifinish(false);
		setIsProduct(true);
	}
	const productLinkCreate = (e) => {
		const name = e.target.value;
		setProductLink(Utils.translit(name));
		setProductName(name);
	}
	const imageGet = (v) => <img src={imageUrlGet(partnerId, v._id, v.image)} alt="" onError={imageError} />;
	const statusGet = (v) => <div className={`status${v.status===commonStatus.ACTIVE?v.status:0}`}>
		<div className="status-point"></div>
	</div>;
	const massCopy = async (ids) => {
		for (const v of ids) {
			const s = products.find(f => f._id === v);
			await productCopy(s, true);
		};
		successShow('Операция прошла успешно');
		productsGet();
		cancel();
		return true;
	};
	const massDelete = async (ids) => {
		for (const v of ids) {
			await Products.remove(v).catch((ex) => console.log(666, ex));
		};
		successShow('Операция прошла успешно');
		productsGet();
		cancel();
		return true;
	};
	const massStop = (ids) => massStopChange(ids, true);
	const massStopBack = (ids) => massStopChange(ids, false);
	const massStopChange = async (ids, stop) => {
		for (const id of ids) await productStop(id, stop, true);
		successShow('Операция прошла успешно');
		productsGet();
		cancel();
		return true;
	}
	const massActive = (ids) => massStatusChange(ids, commonStatus.ACTIVE);
	const massUnactive = (ids) => massStatusChange(ids, commonStatus.IN_ACTIVE);
	const massStatusChange = async (ids, status) => {
		for (const id of ids) {
			await Products.status(id, {status}).catch((ex) => console.log(666, ex));
		}
		successShow('Операция прошла успешно');
		productsGet();
		cancel();
		return true;
	};
	const massCategoryMove = async (ids, categoryId) => {
		for (const id of ids) {
			await Products.patch(id, {categoryId}).catch((ex) => console.log(666, ex));
		}
		successShow('Операция прошла успешно');
		productsGet();
		cancel();
		return true;
	};
	const massCategoryCopy = async (ids, categoryId) => {
		for (const id of ids) {
			const s = products.find(f => f._id === id);
			await productCopy(s, true, true, {categoryId});
		};
		successShow('Операция прошла успешно');
		productsGet();
		cancel();
		return true;
	};
	const massPointAdd = async (ids, pointId) => {
		for (const id of ids) {
			const s = products.find(f => f._id === id);
			const points = s.points.map(v => ({id:v.id}));
			if (!points.find(f => f.id === pointId)) points.push({id:pointId});
			await Products.patch(id, {points}).catch((ex) => console.log(666, ex));
		}
		successShow('Операция прошла успешно');
		productsGet();
		cancel();
		return true;
	};
	const massPointRemove = async (ids, pointId) => {
		let iserror = false;
		for (const id of ids) {
			const s = products.find(f => f._id === id);
			const points = s.points.filter(f => f.id !== pointId);
			if (points.length === 0) iserror = true;
			else await Products.patch(id, {points}).catch((ex) => console.log(666, ex));
		}
		if (iserror) errorShow('Некоторые товары не могут быть удалены из филиала');
		else successShow('Операция прошла успешно');
		productsGet();
		cancel();
		return true;
	};
			



	const pointGet = (item) => {
		const p = item.points;
		const out = [];
		if (p && p.length !== points.length) {
			for (let i = 0; i < p.length; i++) {
				out.push(points.find(f => f._id === p[i].id).name);
			}
		}
		return out.length === 0 ? 'Все филиалы' : out.join(', ');
	};
	const productSort = async (data) => {
		setProducts([...data]);
		setProductsAll([...data]);
		const ids = data.map(v => v._id);
		await Products.sort({ids}).catch((ex) => console.log(666, ex));
	};
	const nameGet = (v) => <>
		{v.name}
		{v.options.isNew || v.options.isTop || v.options.isStop ?
			<div className="options">
				{v.options.isNew ? <span className="new">Новинка</span> : null}
				{v.options.isTop ? <span className="top">TOP</span> : null}
				{v.options.isStop ? <span className="stop">Стоп-лист</span> : null}
			</div> : null}
	</>;
	const productRelatedShow = () => {
		setProductsRelated(productsAll);
		modalProductsRelatedOpen();
	};
	const productRelatedSelect = (product) => {
		const p = related.find(f => f.id === product._id);
		if (p) setRelated(related.filter(f => f.id !== product._id));
		else {
			setRelated([...related, {
				id:product._id,
				name:product.name
			}]);
		}
	};
	const productSupplementsShow = () => {
		setProductsSupplement(productsAll);
		modalProductsSupplementOpen();
	};
	const productSupplementSelect = (product) => {
		const p = supplements.find(f => f.id === product._id);
		if (p) setSupplements(supplements.filter(f => f.id !== product._id));
		else {
			setSupplements([...supplements, {
				id:product._id,
				name:product.name
			}]);
		}
	};
	const productSkuShow = () => {
		setProductsSkus(productsAll);
		modalProductsSkusOpen();
	};
	const productSkuSelect = (product) => {
		const p = skus.find(f => f.id === product._id);
		if (p) setSkus(skus.filter(f => f.id !== product._id));
		else {
			setSkus([...skus, {
				id:product._id,
				name:product.name
			}]);
		}
	};
	const skuTitleHandler = (e, id) => {
		const value = e.target.value;
		setSkus(skus.map(v => {
			if (v.id === id) v.title = value;
			return v;
		}));
	};
	const optionGroupSelect = (group) => {
		const g = optionGroups.find(f => f.id === group._id);
		if (g) setOptionGroups(optionGroups.filter(f => f.id !== group._id));
		else {
			setOptionGroups([...optionGroups, {
				id:group._id,
				name:group.name,
				options:group.productOptions.map(v => ({id:v._id,name:v.name}))
			}]);
		}
	};
	const productOptionGroupsShow = () => {
		setProductOptionGroups(productOptionGroupsAll);
		modalOptionGroupsOpen();
	};
	const tabSet = (tab) => setTab(tab);
	const tabsBlock = <div className="categories-tabs">
		<div className="tabs">
			<div className={`tab${tab===0?' active':''}`} onClick={() => tabSet(0)}>Разделы</div>
			<div className={`tab${tab===1?' active':''}`} onClick={() => tabSet(1)}>Категории</div>
		</div>
	</div>;
	const productCopy = async (s, norelaod, nonamechange, options) => {
		if (s) {
			const data = {
				link:nonamechange?`${s.link}_`:`${s.link}-copy`,
				name:nonamechange?s.name:`${s.name} (копия)`,
				description:s.description,
				categoryId:s.categoryId,
				price:s.prices.price,
				priceFull:s.prices.priceFull,
				discount:s.discounts.discount,
				weight:s.properties.weight,
				measureType:s.properties.measureType,
				kkal:s.properties.kkal,
				proteins:s.properties.proteins,
				fats:s.properties.fats,
				carbohydrates:s.properties.carbohydrates,
				productArticle:s.properties.article,
				sortOrder:s.options.sortOrder,
				isNew:s.options.isNew,
				isTop:s.options.isTop,
				isStop:s.options.isStop,
				isStopTill:s.options.isStopTill,
				siteHide:s.options.siteHide,
				components:s.components,
				points:s.points,
				images:s.images,
				image:s.image,
				days:s.options?.days||[true,true,true,true,true,true,true],
				deliveryType:s.options.deliveryType||Object.values(delType).slice(1).map(v => v),
				sources:s.options.sources||Object.values(sourceType).slice(1).map(v => v),
				groups:s.optionGroups.map(v => v.id),
				related:s.related?.products?.map(v => v.id)||[],
				relatedTitle:s.related?.title,
				supplements:s.supplements.map(v => v.id),
				skus:s.skus.map(v => ({id:v.id,title:v.title})),
				tags:s.tags,
				status:s.status
			};
			if (options) {
				if (options.categoryId) data.categoryId = options.categoryId;
			}
			const res = await Products.add(data).catch((ex) => {
				console.log(666, ex);
				errorShow();
			});
			if (res) Products.image.copy(s._id, res.id).catch((ex) => console.log(666, ex));
		}
		if (norelaod) return true;
		successShow('Операция прошла успешно');
		productsGet();
		cancel();
		return true;
	};
	const filterSet = (name, value) => {
		const f = {...filterTemp};
		f[name] = value;
		setFilterTemp(f);
	};
	const filterCount = () => {
		let count = 0;
		for (const key in filter) {
			const k = filter[key];
			if (k) count++;
		}
		return count;
	};
	const filterShow = () => setFilterTemp(filter);
	const filterReset = () => {
		setFilter({});
		setFilterTemp({});
		setProducts(productsAll);
	};
	const filterApply = () => {
		setFilter(filterTemp);
		let p = productsAll;
		if (filterTemp.activeOnly) p = p.filter(f => f.status === commonStatus.ACTIVE);
		if (filterTemp.isNew) p = p.filter(f => f.options.isNew);
		if (filterTemp.isStop) p = p.filter(f => f.options.isStop);
		 setProducts(p);
	};
	const productMoneyChange = (product) => {
		setId(product._id);
		setProductName(product.name);
		setProductPrice(product.prices.price);
		setProductDiscount(product.discounts.discount);
		modalPriceChangeOpen();
	};
	const productPriceChange = async () => {
		const data = {
			price:productChangePrice,
			priceFull:fullPriceGet(productChangePrice)
		};
		const res = await Products.patch(id, data).catch((ex) => {
			console.log(666, ex);
			errorShow();
		});
		if (res) {
			successShow('Цена успешно изменена');
			productsGet();
			cancel();
		}
		return true;
	};
	const productCost = () => items.reduce((a,b) => a + b.total, 0);
	const productMargin = () => (parseFloat(productPrice) || 0) - productCost();
	const productMarginPercent = () => {
		const cost = productCost();
		const price = parseFloat(productPrice) || 0;
		return price ? Math.round(((price - cost) / price) * 100) : 0;
	};
	const massPriceChange = (ids) => {
		const products = ids.map(v => productsAll.find(f => f._id === v));
		setIds(products);
		setIdsFinal([]);
		setPriceSumChange(null);
		setPricePercentChange(null);
		modalPriceChangeMassOpen();
		setIsPriceRound(true);
		return true;
	};
	const productPriceDiscountChange = async (id, price, discount, showalert) => {
		const data = {
			price:price,
			priceFull:price - (price * discount / 100),
			discount:discount
		};
		const res = await Products.patch(id, data).catch((ex) => {
			console.log(666, ex);
			if (showalert) errorShow();
		});
		if (showalert) {
			if (res) {
				successShow('Цена успешно изменена');
				productsGet();
				cancel();
			}
		}
	}
	const productPriceChangeMassHandler = (e, v) => {
		let value = parseFloat(e.target.value);
		const id = v._id;
		const isfinal = idsFinal.length > 0;
		const data = isfinal ? idsFinal : ids;
		const p = data.map((v) => {
			if (v._id === id) {
				if (value) {
					v.prices.price = value;
					if (v.discounts.discount) v.prices.priceFull = value - (value * v.discounts.discount / 100);
					else v.prices.priceFull = value;
					if (!isfinal) productPriceDiscountChange(id, value, v.discounts.discount, true);
				}
			}
			return v;
		});
		if (isfinal) setIdsFinal(p);
		else setIds(p);
	};
	const productDiscountChangeMassHandler = (e, v) => {
		let value = parseFloat(e.target.value);
		const id = v._id;
		const isfinal = idsFinal.length > 0;
		const data = isfinal ? idsFinal : ids;
		const p = data.map((v) => {
			if (v._id === id) {
				value = value || 0;
				value = value > 100 ? 100 : value;
				v.discounts.discount = value;
				v.prices.priceFull = v.prices.price - (v.prices.price * value / 100);
				if (!isfinal) productPriceDiscountChange(id, v.prices.price, value, true);
			}
			return v;
		});
		if (isfinal) setIdsFinal(p);
		else setIds(p);
	};
	const productPriceGet = (id) => {
		const product = productsAll.find(f => f._id === id);
		return product ? <span>Цена: {product.prices.priceFull} ₽ {product.discounts.discount ? `• Скидка: ${product.discounts.discount}% • Без скидки: ${product.prices.price} ₽` : null}</span> : null;
	};
	const productMassApply = () => {
		const percent = parseFloat(pricePercentChange);
		const sum = parseFloat(priceSumChange);
		if (percent && sum) {
			errorShow('Выберите только один вариант изменения цены');
			return;
		}
		if (!percent && !sum) {
			errorShow('Укажите процент или сумму изменения цены');
			return;
		}
		const p = [];
		if (percent) {
			if (Math.abs(percent) > 100) {
				errorShow('Процент изменения не может быть больше 100');
				return;
			}
			ids.forEach((v) => {
				const item = Utils.deepCopy(v);
				item.prices.price = item.prices.price + (item.prices.price * percent / 100);
				if (isPriceRound) item.prices.price = Math.round(item.prices.price);
				item.prices.priceFull = item.prices.price - (item.prices.price * item.discounts.discount / 100);
				p.push(item);
			});
		}
		if (sum) {
			ids.forEach((v) => {
				const item = Utils.deepCopy(v);
				item.prices.price = item.prices.price + sum;
				if (isPriceRound) item.prices.price = Math.round(item.prices.price);
				item.prices.priceFull = item.prices.price - (item.prices.price * item.discounts.discount / 100);
				p.push(item);
			});
		}
		setIdsFinal(p);
	};
	const productMassSave = async () => {
		const data = Utils.deepCopy(idsFinal);
		setIds(idsFinal);
		setIdsFinal([]);
		for (const v of data) await productPriceDiscountChange(v._id, v.prices.price, v.discounts.discount, false);
		successShow('Цены успешно изменены');
		productsGet();
	};
	const productMassApplyCancel = () => setIdsFinal([]);
	const priceMassChangeHistory = (id) => {
		if (idsFinal.length === 0) return;
		const product = ids.find(f => f._id === id);
		return product ? <span><b>{product.prices.price}</b>→</span> : null;
	};
	return (
		<>
			<Template>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<Layout
							title="Товары"
							type="books"
							data={products}
							id={id}
							search={search}
							sortIndex={1}
							rows={[
								{title:'',field:'image',class:'avatar',func:imageGet},
								{title:'Название',field:'name',class:'no-hide',func:nameGet},
								{title:'Филиал',field:'points',class:'area',func:pointGet},
								{title:'Цена',field:'prices',class:'prices',func:(v) => v.prices.priceFull,sort:'prices.priceFull'},
								{title:'Скидка',field:'discounts',class:'discount',func:(v) => v.discounts.discount ? `${v.discounts.discount}%` : '–',sort:'discounts.discount'},
								{title:'Объем',field:'weight',class:'weight',func:(v) => `${v.properties.weight} ${measureTypeName[v.properties.measureType]}`,sort:'properties.weight'},
								{title:'Статус',field:'status',class:'status',func:statusGet}
							]}
							rowsShort={['image','name','prices','status']}
							rowControlItems={[
								{title:'Копировать',class:'copy',action:productCopy},
								{title:'Изменить цену',class:'price',action:productMoneyChange},
								{title:(v) => v.status===commonStatus.ACTIVE?'Отключить':'Включить',class:(v) => v.status===commonStatus.ACTIVE?'inactive':'active',action:productEdit,action:(v) => v.status === commonStatus.ACTIVE ? modalChangeStatusShow(v._id) : productChangeStatusOn(v._id)},
								{title:(v) => v.options?.isStop?'Вернуть из стопа':'В стоп-лист',class:(v) => v.options?.isStop?'stopliston':'stoplistoff',action:(v) => v.options?.isStop ? productStopOff(v._id) : modalStopShow(v._id)}
							]}
							footerItems={[
								{title:'Сделать копию',action:massCopy},
								{title:'В стоп-лист',action:massStop,confirm:true},
								{title:'Вернуть из стоп-листа',action:massStopBack,confirm:true},
								{title:'Переместить в категорию',action:massCategoryMove,confirm:true,depend:{data:categories,title:'Выберите категорию'}},
								{title:'Копировать в категорию',action:massCategoryCopy,confirm:true,depend:{data:categories,title:'Выберите категорию'}},
								{title:'Привязать к филиалу',action:massPointAdd,depend:{data:points,title:'Выберите филиал'}},
								{title:'Отвязать от филиала',action:massPointRemove,confirm:true,depend:{data:points,title:'Выберите филиал'}},
								{title:'Изменить цены',action:massPriceChange},
								{title:'Активировать',action:massActive},
								{title:'Деактивировать',action:massUnactive,confirm:true},
								{title:'Удалить',action:massDelete,confirm:true}
							]}
							filterCount={filterCount()}
							filterShow={filterShow}
							filterReset={filterReset}
							filterApply={filterApply}
							contentFilter={<>
								<div className="filter-row">
									<label>Только активные</label>
									<img src={filterTemp.activeOnly ? imgCheckOn : imgCheckOff} alt="" onClick={() => filterSet('activeOnly', !filterTemp.activeOnly)} />
								</div>
								<div className="filter-row">
									<label>Новинки</label>
									<img src={filterTemp.isNew ? imgCheckOn : imgCheckOff} alt="" onClick={() => filterSet('isNew', !filterTemp.isNew)} />
								</div>
								<div className="filter-row">
									<label>Товары в стоп-листе</label>
									<img src={filterTemp.isStop ? imgCheckOn : imgCheckOff} alt="" onClick={() => filterSet('isStop', !filterTemp.isStop)} />
								</div>
							</>}
							empty={<>Добавьте первую<br/>позицию</>}
							contentTabs={tab === 0 ? tabsBlock : null}
							contentSideMenu={tab === 1 ?
									<>
										<div className="categories-list-container">
											{tabsBlock}
											<div className="container-inner">
												<div className="list">
													<div className={`category${category?'':' category-select'}`} onClick={() => categorySelectAll()}>
														Все категории <i>{productsAll.length}</i>
													</div>
													{categories.length ?
															categories.map((v,i) => <div key={i} className={`category${v._id===category?._id?' category-select':''}${v.options.isHide?' category-hide':''}`} onClick={() => categorySelect(v)}>
																<div>
																	{v.name}
																	<i>{productsAll.filter(f => f.categoryId === v._id).length}</i>
																</div>
																<span className="category-edit" onClick={() => categoryAddShow(v)}></span>
															</div>)
														:
															<div className="category-empty">
																<div>
																	Здесь можно добавить новые категории, чтобы заполнить меню
																</div>
																<img src={imgArrowCurveDown} alt="" />
															</div>
													}
												</div>
											</div>
											<div className="categories-list-footer">
												<button type="button" onClick={() => categoryAddShow()}>
													<img src={imgPlus} alt="" />
													Добавить категорию
												</button>
											</div>
										</div>
									</>
								: null}
							contentShowTitle={productName}
							contentShow={<>
								<div className="product-view-row product-view-row-simple">
									<p className="description">{productDescription}</p>
								</div>
								{productArticle ?
									<div className="product-view-row product-view-row-oneline">
										<span>Артикул</span>
										<div>{productArticle}</div>
									</div> : null}
								<div className="product-view-row product-view-row-oneline">
									<span>Цена</span>
									<div>{productPriceFull} ₽</div>
								</div>
								{productDiscount ?
									<div className="product-view-row product-view-row-oneline">
										<span>Цена без скидки</span>
										<div>{productPrice} ₽</div>
									</div> : null}
								{productDiscount ?
									<div className="product-view-row product-view-row-oneline">
										<span>Скидка</span>
										<div>{productDiscount}%</div>
									</div> : null}
								<div className="product-view-row product-view-row-oneline">
									<span>Себестоимость</span>
									<div>{productCost()} ₽</div>
								</div>
								<div className="product-view-row product-view-row-oneline">
									<span>Маржа</span>
									<div><b>{productMarginPercent()}</b>% / <b>{productMargin()}</b> ₽</div>
								</div>
								<div className="product-view-row product-view-row-oneline">
									<span>Объем / Вес</span>
									<div>{productWeight} {measureTypeName[productMeasureType]}</div>
								</div>
								<div className="product-view-row">
									<span>Энергетическая ценность</span>
									<div>{productKkal||'–'} ккал, {productProteins||'–'} белка, {productFats||'–'} жира, {productCarbohydrates||'–'} углеводов</div>
								</div>
								{productSiteHide || productIsNew || productIsTop || (productIsStop && !productIsStopTill) ?
									<div className="product-view-row product-view-row-oneline">
										<span>Дополнительно</span>
										<div>{[productSiteHide?'Скрыто на сайте':null,productIsNew?'Новинка':null,productIsTop?'TOP':null,(productIsStop&&!productIsStopTill)?'В стоп-листе':null].filter(f => f !== null).join(', ')}</div>
									</div> : null}
								{productIsStopTill && productIsStopTill ?
									<div className="product-view-row product-view-row-oneline">
										<span></span>
										<div>В стоп-листе до {moment(productIsStopTill).format('DD.MM.YYYY HH:mm:ss')}</div>
									</div> : null}
								<PointsSelect isShow={true} selected={productPoints} />
								{items && items.length ?
									<>
										<h4>Компоненты</h4>
										<div className="product-edit-components-block">
											{items.map((v,i) => <div key={i} className="product-edit-component-item">
												<div className="product-edit-component-item-name">
													<b>{v.name}{v.issemifinish ? <span className="type">п/ф</span> : (v.isproduct ? <span className="type">товар</span> : null)}</b>
												</div>
												<div className="info">
													<div>брутто: {v.brutto}кг • нетто: {v.netto}кг • потери: {v.loss}кг</div>
													<div><b>вес: {v.weight}кг • цена: {v.total}₽</b></div>
												</div>
											</div>)}
										</div>
									</> : null}
								<h4>Фотографии</h4>
								<div className="product-edit-row">
									{productImages.length ?
											<div className="product-edit-images">
												{productImages.map((v,i) => <div key={i} className="product-edit-image">
													<img src={v} alt="" />
												</div>)}
											</div>
										: null
									}
								</div>
							</>}
							contentEdit={<>
								<div className="product-edit-row">
									<input type="text" {...bindProductName} placeholder="Название блюда" className="input-title" onChange={productLinkCreate} autoFocus={true} required />
								</div>
								<div className="product-edit-row">
									<textarea placeholder="Описание" {...bindProductDescription} required></textarea>
								</div>
								<div className="product-edit-row product-edit-row-oneline product-edit-row-plus">
									<div className="select select-wide">
										<select onChange={handleCategory} value={categoryId} required>
											<option value="">Выберите категорию</option>
											{categories.map((v,i) => <option key={i} value={v._id}>{v.name}</option>)}
										</select>
									</div>
									<img src={imgPlusButton} alt="" className="categoty-add" onClick={() => categoryAddShow()} />
								</div>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="link" className="label-middle">Ссылка</label>
									<input id="link" {...bindProductLink} type="text" placeholder="Ссылка для позиции" />
								</div>
								<h4>Цены / Скидки</h4>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="productPrice" className="label-middle">Цена блюда</label>
									<input id="productPrice" {...bindProductPrice} type="text" placeholder="0" required />
								</div>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="productDiscount" className="label-middle">Процент скидки</label>
									<input id="productDiscount" {...bindProductDiscount} type="text" placeholder="0" maxLength={2} />
								</div>
								<div className="product-edit-row product-edit-row-oneline product-edit-row-select">
									<label className="label-middle">Цена блюда со скидкой</label>
									<b>{fullPriceGet()} ₽</b>
								</div>
								<div className="product-edit-row-select">
									<div className="product-edit-row product-edit-row-oneline">
										<label className="label-middle">Себестоимость</label>
										<div>{productCost()} ₽</div>
									</div>
									<div className="product-edit-row product-edit-row-oneline">
										<label className="label-middle">Маржа</label>
										<div><b>{productMarginPercent()}</b>% / <b>{productMargin()}</b> ₽</div>
									</div>
								</div>
								<h4>Фотографии</h4>
								<div className="product-edit-row">
									{productImages.length ?
											<div className="product-edit-images">
												{productImages.map((v,i) => <div key={i} className={`product-edit-image${productImageMainIndex===i?' product-edit-image-main':''}`}  onClick={() => imageMainSet(i)}>
													<img src={v} alt="" />
													<div className="delete" onClick={() => setProductImages(productImages.filter(f => f !== v))}></div>
												</div>)}
											</div>
										: null
									}
									<label>
										<div className="product-edit-btn">
											Добавить фото
											<input type="file" accept="image/jpeg,image/png,image/webp" onChange={handleImage} />
										</div>
									</label>
								</div>
								<h4>Объем / Вес</h4>
								<div className="product-edit-row product-edit-row-oneline">
									<input type="text" {...bindProductWeight} placeholder="Объем / Вес" required />
									<div className="select">
										<select onChange={handleMeasureType} value={productMeasureType} required>
											{measureTypeName.map((v,i) => <option key={i} value={i}>{v}</option>)}
										</select>
									</div>
								</div>
								<Accoirdion title="Энергетическая ценность">
									<div className="product-edit-row product-edit-row-oneline">
										<label htmlFor="productKkal" className="label-middle">Каллории</label>
										<input id="productKkal" {...bindProductKkal} type="text" placeholder="Ккал" maxLength={5} />
									</div>
									<div className="product-edit-row product-edit-row-oneline">
										<label htmlFor="productProteins" className="label-middle">Белки</label>
										<input id="productProteins" {...bindProductProteins} type="text" placeholder="0" maxLength={3} />
									</div>
									<div className="product-edit-row product-edit-row-oneline">
										<label htmlFor="productFats" className="label-middle">Жиры</label>
										<input id="productFats" {...bindProductFats} type="text" placeholder="0" maxLength={3}/>
									</div>
									<div className="product-edit-row product-edit-row-oneline">
										<label htmlFor="productCarbohydrates" className="label-middle">Углеводы</label>
										<input id="productCarbohydrates" {...bindProductCarbohydrates} type="text" placeholder="0" maxLength={3} />
									</div>
								</Accoirdion>
								<Accoirdion title="Компоненты" info={items&&items.length?Orfo.counters.components(items.length):null}>
									<div className="product-edit-row">
										{items.length ?
												<div className="product-edit-components-block">
													{items.map((v,i) => <div key={i} className="product-edit-component-item">
														<div className="product-edit-component-item-name">
															<b>{v.name}{v.issemifinish ? <span className="type">п/ф</span> : (v.isproduct ? <span className="type">товар</span> : null)}</b>
															<img src={imgClose} alt="" className="close-icon" onClick={() => setItems(items.filter(f => f.id !== v.id))} />
														</div>
														<div className="info">
															<div>брутто: {v.brutto}кг • нетто: {v.netto}кг • потери: {v.loss}кг</div>
															<div><b>вес: {v.weight}кг • цена: {v.total}₽</b></div>
														</div>
													</div>)}
												</div>
											: null
										}
										<div className="product-edit-btn" onClick={() => itemShow()}>Добавить</div>
									</div>
								</Accoirdion>
								<Accoirdion title="Группа опций"  info={optionGroups&&optionGroups.length?Orfo.counters.groups(optionGroups.length):null}>
									<div className="product-edit-row">
										{optionGroups ?
											<div className="product-edit-components-block">
												{optionGroups.map((v,i) => <div key={i} className="product-edit-component-item">
													<div className="product-edit-component-item-name">
														<div>
															<div className="name">{v.name}</div>
															{v.options ? <span className="info">{v.options.map((v,i) => v.name).join(', ')}</span> : null}
														</div>
														<img src={imgClose} alt="" className="close-icon" onClick={() => setOptionGroups(optionGroups.filter(f => f.id !== v.id))} />
													</div>
												</div>)}
											</div> : null}
										<div className="product-edit-btn" onClick={() => productOptionGroupsShow()}>Добавить</div>
									</div>
								</Accoirdion>
								<Accoirdion title="Связанные товары" info={related&&related.length?Orfo.counters.products(related.length):null}>
									<div className="product-edit-row">
										{related.length ?
												<div className="product-edit-components">
													{related.map((v,i) => <div key={i} className="product-edit-component">
														{v.name}
														<img src={imgClose} alt="" className="close-icon" onClick={() => setRelated(related.filter(f => f.id !== v.id))} />
													</div>)}
												</div>
											: null}
										<div className="product-edit-row product-edit-row-oneline">
											<label htmlFor="relatedTitle" className="label-middle">Заголовок на странице товаром</label>
											<input id="relatedTitle" {...bindRelatedTitle} type="text" />
										</div>
										<div className="product-edit-btn" onClick={() => productRelatedShow()}>Добавить</div>
									</div>
								</Accoirdion>
								<Accoirdion title="Бесплатная добавка на выбор" info={supplements&&supplements.length?Orfo.counters.products(supplements.length):null}>
									<div className="product-edit-row">
										{supplements.length ?
												<div className="product-edit-components">
													{supplements.map((v,i) => <div key={i} className="product-edit-component">
														{v.name}
														<img src={imgClose} alt="" className="close-icon" onClick={() => setSupplements(supplements.filter(f => f.id !== v.id))} />
													</div>)}
												</div>
											: null}
										<div className="product-edit-btn" onClick={() => productSupplementsShow()}>Добавить</div>
									</div>
								</Accoirdion>
								<Accoirdion title="Модификаторы" info={skus?skus.map((v) => v.title).join(', '):null}>
									<div className="product-edit-row">
										<div className="product-edit-components-block">
											{skus.map((v,i) => <div key={i} className="product-edit-component-item">
												<div className="product-edit-component-item-name">
													<span>{v.name}</span>
													<img src={imgClose} alt="" className="close-icon" onClick={() => setSkus(skus.filter(f => f.id !== v.id))} />
												</div>
												<input type="text" placeholder="Характеристика, залоговок" onChange={(e) => skuTitleHandler(e, v.id)} value={v.title} />
											</div>)}
										</div>
										<div className="product-edit-btn" onClick={() => productSkuShow()}>Добавить</div>
									</div>
								</Accoirdion>
								<Accoirdion title="Ярлыки товара" info={tags&&tags.length?Orfo.counters.tags(tags.length):null}>
									<div className="product-edit-row">
										{tagsAll.map((v,i) => <div key={i} className="product-edit-area product-edit-row-oneline-start">
											<img src={tags.find(f => f === v._id) ? imgCheckOn : imgCheckOff} alt="" className="checkbox" onClick={() => tagSet(v._id)} />
											<div>
												<span className="product-tag-icon" style={{backgroundColor:v.options.color}}>
													{v.text && !v.image && !v.icon ? <span style={{color:v.options.colorText}}>{v.text}</span> : null}
													{v.icon ?
														<>
															<img id={`tagimage${i}`} src={icons.find(f => f.id === v.icon).img} alt="" />{v.text ? <span style={{color:v.options.colorText}}>{v.text}</span> : null}
														</> : null}
													{v.image ? <>
															<img src={`${API.assets}partners/${partnerId}/producttags/${v._id}/${v.image}`} alt="" />
															{v.text ? <span style={{color:v.options.colorText}}>{v.text}</span> : null}
														</> : null}
												</span>
											</div>
											<style>{`#tagimage${i}{filter:${hexToCSSFilter(v.options.colorText).filter}}`}</style>
										</div>)}
									</div>
								</Accoirdion>
								<PointsSelect isEdit={true} points={points} selected={productPoints} onSelect={pointSelect} />
								<Accoirdion title="Доступно для заказа">
									<div className="product-edit-row">
										{days.map((v,i) => <div key={i} className="product-edit-area product-edit-row-oneline-start">
											<img src={v ? imgCheckOn : imgCheckOff} alt="" className="checkbox" onClick={() => setDays(days.map((m,j) => i === j ? !m : m))} />
											{Utils.weekDayName.full[i]}
										</div>)}
									</div>
								</Accoirdion>
								<Accoirdion title="Способ получения">
									<div className="product-edit-row">
										{deliveryTypeName.map((v,i) => i === 0 ? null : <div key={i} className="product-edit-area product-edit-row-oneline-start">
											<img src={deliveryType.includes(i) ? imgCheckOn : imgCheckOff} alt="" className="checkbox" onClick={() => deliveryTypeSet(i)} />
											{deliveryTypeName[i]}
										</div>)}
									</div>
								</Accoirdion>
								<Accoirdion title="Источники">
									<div className="product-edit-row">
										{sourceTypeName.map((v,i) => i === 0 ? null : <div key={i} className="product-edit-area product-edit-row-oneline-start">
											<img src={sources.includes(i) ? imgCheckOn : imgCheckOff} alt="" className="checkbox" onClick={() => sourceSet(i)} />
											{sourceTypeName[i]}
										</div>)}
									</div>
								</Accoirdion>
								<Accoirdion title="Дополнительные параметры">
									<div className="product-edit-row product-edit-row-oneline">
										<label htmlFor="productArticle" className="label-middle">Артикул</label>
										<input id="productArticle" {...bindProductArticle} type="text" placeholder="0" maxLength={2} />
									</div>
									<div className="product-edit-row product-edit-row-oneline">
										<label htmlFor="productSortOrder" className="label-middle">Порядок сортировки</label>
										<input id="productSortOrder" {...bindProductSortOrder} type="text" placeholder="0" maxLength={2} />
									</div>
									<div className="product-edit-row product-edit-row-oneline">
										<label>Скрывать на сайте</label>
										<img src={productSiteHide ? imgCheckOn : imgCheckOff} alt="" onClick={() => setProductSiteHide(!productSiteHide)} />
									</div>
									<div className="product-edit-row product-edit-row-oneline">
										<label>В стопе</label>
										<img src={productIsStop ? imgCheckOn : imgCheckOff} alt="" onClick={() => setProductIsStop(!productIsStop)} />
									</div>
									<div className="product-edit-row product-edit-row-oneline">
										<label>Новинка</label>
										<img src={productIsNew ? imgCheckOn : imgCheckOff} alt="" onClick={() => setProductIsNew(!productIsNew)} />
									</div>
									<div className="product-edit-row product-edit-row-oneline">
										<label>TOP-товар</label>
										<img src={productIsTop ? imgCheckOn : imgCheckOff} alt="" onClick={() => setProductIsTop(!productIsTop)} />
									</div>
									<div className="product-edit-row product-edit-row-oneline">
										<label className="label-middle">Имеет остаток на складе</label>
										<img src={productIsStore ? imgCheckOn : imgCheckOff} alt="" onClick={() => setProductIsStore(!productIsStore)} />
									</div>
								</Accoirdion>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="status">Статус</label>
									<div className="select select-middle">
										<select id="status" onChange={handleStatus} value={status} required>
											<option value=""></option>
											{commonStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
										</select>
									</div>
								</div>
							</>}
							onDelete={productDelete}
							onEdit={productEdit}
							onAdd={productAdd}
							onSave={productSave}
							onSearch={searchProduct}
							onShow={productShow}
							onSort={productSort}
							onClose={cancel}
						/>
					)
				}
			</Template>
			<Alert />
			<ModalCategory>
				<div className="modal modal-category">
					<div className="header">
						<h4>{categoryId ? 'Изменить категорию' : 'Добавить категорию'}</h4>
						<img src={imgClose} alt="" onClick={modalCategoryHide} className="btn-close" />
					</div>
					<form className="product-edit" onSubmit={categorySave}>
						<div className="container-inner">
							<div className="list">
								<div className="product-edit-row">
									<input type="text" {...bindCategoryName} placeholder="Название категории" className="input-title" autoFocus={true} onChange={linkCreate} required />
								</div>
								<div className="product-edit-row">
									<textarea placeholder="Описание" {...bindCategoryDescription}></textarea>
								</div>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="link" className="label-middle">Ссылка</label>
									<input id="link" {...bindCategoryLink} type="text" placeholder="Ссылка для категории" />
								</div>
								<PointsSelect isEdit={true} points={points} selected={categoryPoints} onSelect={pointCategorySelect} />
								<h4>Дополнительные параметры</h4>
								<div className="product-edit-row product-edit-row-oneline">
									<label className="label-middle">Скрытая категория</label>
									<img src={isCategoryHide ? imgCheckOn : imgCheckOff} alt="" onClick={() => setIsCategoryHide(!isCategoryHide)} />
								</div>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="sortOrder" className="label-middle">Порядок сортировки</label>
									<input id="sortOrder" {...bindCategorySortOrder} type="text" placeholder="0" maxLength={2} />
								</div>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="status">Статус</label>
									<div className="select select-middle">
										<select id="status" onChange={handleCategoryStatus} value={categoryStatus} required>
											<option value=""></option>
											{commonStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
										</select>
									</div>
								</div>
							</div>
						</div>
						<div className="product-edit-footer">
							<button type="button" onClick={modalCategoryHide} className="btn-cancel">Отменить</button>
							<button type="submit" className="btn-save btn-accept">Сохранить</button>
						</div>
					</form>
				</div>
			</ModalCategory>
			<ModalItem>
				<div className="modal modal-items modal-items-wide">
					<div className="header">
						<h4>Добавить позицию</h4>
						<img src={imgClose} alt="" onClick={modalItemClose} className="btn-close" />
					</div>
					<form onSubmit={componentAdd}>
						{component ?
								<div>
									<b>{component.name}</b> • <span>{isProduct===true?component.prices?.priceFull:(component.prices?.manual||component.prices?.invoice||0)} ₽ за {isProduct===true?component.properties.weight:component.weight} {measureTypeName[isProduct===true?component.properties.measureType:component.measure]}</span>
								</div>
							: null}
						<div className="buttons">
							<button type="button" className={`btn-cancel btn-select btn-select-half${isProduct===false&&isSemifinish===false?' btn-select-half-select':''}`} onClick={() => componentsItemShow()}>Компонент</button>
							<button type="button" className={`btn-cancel btn-select btn-select-half${isProduct===false&&isSemifinish===true?' btn-select-half-select':''}`} onClick={() => semifinishesShow()}>Полуфабрикат</button>
							<button type="button" className={`btn-cancel btn-select btn-select-half${isProduct===true&&isSemifinish===false?' btn-select-half-select':''}`} onClick={() => componentProductShow()}>Товар</button>
						</div>
						<div className="weights-form">
							{isSemifinish ?
									<div className="weights-form-row">
										<label htmlFor="brutto">Цена<span>полуфабриката за {component.properties.netto} кг</span></label>
										<div>
											<input type="text" {...bindAmount} placeholder="0" maxLength={5} required />
											<span>₽</span>
										</div>
									</div>
								:
									(isProduct ?
										<div className="weights-form-row">
											<label htmlFor="brutto">Цена<span>товара за {component.properties.weight} {measureTypeName[component.properties.measureType]}</span></label>
											<div>
												<input type="text" {...bindAmount} placeholder="0" maxLength={5} required />
												<span>₽</span>
											</div>
										</div>
									:
										<div className="weights-form-row">
											<label htmlFor="brutto">Цена<span>компонента за 1 кг</span></label>
											<div>
												<input type="text" {...bindAmount} placeholder="0" maxLength={5} required />
												<span>₽</span>
											</div>
										</div>
									)
							}
						</div>
						<div className="weights-form">
							{!isSemifinish && !isProduct ?
								<div className="weights-form-row">
									<label htmlFor="brutto">Брутто<span>вес с упаковкой</span></label>
									<div>
										<input id="brutto" type="text" {...bindItemBrutto} placeholder="0" maxLength={5} required />
										<span>кг</span>
									</div>
								</div> : null}
							{!isProduct ?
								<div className="weights-form-row">
									<label htmlFor="netto">Нетто<span>чистый вес</span></label>
									<div>
										<input id="netto" type="text" {...bindItemNetto} placeholder="0" maxLength={5} required />
										<span>кг</span>
									</div>
								</div> : null}
							{isProduct ?
								<div className="weights-form-row">
									<label htmlFor="count">Количество</label>
									<div>
										<input id="count" type="text" {...bindItemCount} placeholder="0" maxLength={2} required />
										<span>шт</span>
									</div>
								</div> : null}
							{!isSemifinish && !isProduct ?
								<div className="weights-form-row">
									<label htmlFor="loss">Потери</label>
									<div>
										<input id="loss" type="text" {...bindItemLoss} placeholder="0" maxLength={5} required />
										<span>кг</span>
									</div>
								</div> : null}
						</div>
						<div className="weights-form">
							<div className="weights-form-row">
								<label>Выход веса</label>
								<div>
									<b>{totalWeight} кг</b>
								</div>
							</div>
							<div className="weights-form-row">
								<label>Сумма</label>
								<div>
									<b>{totalAmount} ₽</b>
								</div>
							</div>
						</div>
						<div className="buttons">
							<button type="submit" className="btn-accept">Добавить</button>
						</div>
					</form>
				</div>
			</ModalItem>
			<ModalItemComponents>
				<div className="modal modal-conditions-lite">
					<div className="header">
						<div className="header-sub">
							<h4>Добавить компонент</h4>
							<img src={imgAdd} alt="" className="add" onClick={() => componentAddShow()} />
						</div>
						<img src={imgClose} alt="" onClick={modalItemComponentsClose} className="btn-close" />
					</div>
					<div className="conditions">
						<div className="sections">
							<div className="inner">
								{componentCategories.length ?
										<div className="list">
											{componentCategories.map((v,i) => <div key={i} className={`section${componentCategoryId===v._id?' section-select':''}`} onClick={() => setComponentCategoryId(v._id)}>
												{v.name}
											</div>)}
										</div>
									:
										<div className="category-empty">
											<div>
												Здесь можно добавить новые категории, чтобы заполнить меню
											</div>
											<img src={imgArrowCurveDown} alt="" />
										</div>
								}
							</div>
							<div className="sections-footer">
								<button type="button" onClick={() => componentCategoryShow()}>
									<img src={imgPlus} alt="" />
										Добавить категорию
								</button>
							</div>
						</div>
						<div className="content">
							{components.filter(f => f.category.id === componentCategoryId).length ?
									<div className="components-list">
										{components.filter(f => f.category.id === componentCategoryId).map((v,i) => <div key={i} className="component" onClick={() => componentSelect(v)}>
											{v.name}
											<span>{v.category.name} • {v.prices?.manual||v.prices?.invoice||0} ₽ • {v.weight} {measureTypeName[v.measure]}</span>
										</div>)}
									</div>
								:
									<div className="empty">
										<Empty image={'components'} text={
											<>
												Для добавления компонента,<br />выберите один из списка слева
												<div className="product-add-container">
													<p>или<br/>добавьте новую позицию</p>
													<button type="button" onClick={() => componentAddShow()}>Добавить компонент</button>
												</div>
											</>} />
									</div>
								}
						</div>
					</div>
				</div>
			</ModalItemComponents>
			<ModalComponent>
				<div className="modal modal-component-newadd">
					<div className="header">
						<h4>Добавить компонент</h4>
						<img src={imgClose} alt="" onClick={modalComponentClose} className="btn-close" />
					</div>
					<form className="product-edit" onSubmit={componentSave}>
						<div className="container-inner">
							<div className="list">
								<div className="product-edit-row">
									<input type="text" {...bindComponentName} placeholder="Название компонента" autoFocus={true} required />
								</div>
								<div className="product-edit-row">
									<textarea placeholder="Описание" {...bindComponentDescription} className="lite"></textarea>
								</div>
								<div className="product-edit-row product-edit-row-oneline product-edit-row-plus">
									<div className="select select-wide">
										<select onChange={handleComponentCategory} value={componentCategoryId} required>
											<option value="">Выберите категорию</option>
											{componentCategories.map((v,i) => <option key={i} value={v._id}>{v.name}</option>)}
										</select>
									</div>
									<img src={imgPlusButton} alt="" className="categoty-add" onClick={() => componentCategoryShow()} />
								</div>
								<h4>Объем / Вес</h4>
								<div className="product-edit-row product-edit-row-oneline">
									<input type="text" {...bindComponentWeight} placeholder="Объем / Вес" maxLength={4} required />
									<div className="select">
										<select onChange={handleComponentMeasureType} value={componentMeasureType} required>
											{measureTypeName.map((v,i) => <option key={i} value={i===0?'':i}>{v}</option>)}
										</select>
									</div>
								</div>
								<h4>Цены</h4>
								<div className="product-edit-row product-edit-row-oneline product-edit-row-select">
									<label className="label-middle">Цена из последней накладной</label>
									{componentInvoicePrice?`${componentInvoicePrice} ₽`:'–'}
								</div>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="price" className="label-middle">Цена (коррекция), ₽</label>
									<input id="price" {...bindComponentPrice} type="text" placeholder="0" maxLength={6} />
								</div>
								<h4>Свойства</h4>
								<div className="product-edit-row product-edit-row-oneline">
									<label className="label-middle">Штучный, количественный товар</label>
									<img src={isPieces ? imgCheckOn : imgCheckOff} alt="" onClick={() => setIsPieces(!isPieces)} />
								</div>
								<h4>Дополнительные параметры</h4>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="minimum" className="label-middle">Минимальный остаток на складе</label>
									<input id="minimum" {...bindComponentMinimum} type="text" placeholder="0" maxLength={4} />
								</div>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="requestcount" className="label-middle">Количество для заявки по умолчанию</label>
									<input id="requestcount" {...bindComponentRequestCount} type="text" placeholder="0" maxLength={4} />
								</div>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="status">Статус</label>
									<div className="select select-middle">
										<select id="status" onChange={handleComponentStatus} value={componentStatus} required>
											<option value=""></option>
											{commonStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
										</select>
									</div>
								</div>
							</div>
						</div>
						<div className="product-edit-footer">
							<button type="button" onClick={modalComponentClose} className="btn-cancel">Отменить</button>
							<button type="submit" className="btn-save btn-accept">Сохранить</button>
						</div>
					</form>
				</div>
			</ModalComponent>
			<ModalComponentCategory>
				<div className="modal">
					<div className="header">
						<h4>Добавить категорию</h4>
						<img src={imgClose} alt="" onClick={modalComponentCategoryClose} className="btn-close" />
					</div>
					<input type="text" {...bindComponentCategoryName} placeholder="Название категории" autoFocus={true} />
					<div className="buttons">
						<button type="button" onClick={modalComponentCategoryClose} className="btn-cancel">Отменить</button>
						<button type="button" onClick={componentCategorySave} className="btn-accept">Сохранить</button>
					</div>
				</div>
			</ModalComponentCategory>
			<ModalSemifinishes>
				<div className="modal modal-products modal-semifinishes">
					<div className="header">
						<h4>Добавить полуфабрикат</h4>
						<img src={imgClose} alt="" onClick={modalSemifinishesClose} className="btn-close" />
					</div>
					<div className="products">
						<div className="list">
							{semifinishes.length ?
									semifinishes.map((v,i) => <div key={i} className="product product-semifinish" onClick={() => semifinishSelect(v)}>
										<div>{v.name}</div>
									</div>)
								:
									<div className="product-empty">
										<div>
											Ничего не найдено
										</div>
									</div>
							}
						</div>
					</div>
				</div>
			</ModalSemifinishes>
			<ModalStop>
				<div className="modal modal-stoplist">
					<div className="header">
						<h4>Позицию в стоп-лист</h4>
						<img src={imgClose} alt="" onClick={modalStopHide} className="btn-close" />
					</div>
					<p>Вы уверены что хотите перевести позицию <b>{productName}</b> в стоп-лист?</p>
					<div className="date">
						<label htmlFor="stoptill">Время в часах для перевода позиции в стоп-лист (макс 24ч)</label>
						<input type="text" id="stoptill" {...bindIsStopTill} placeholder="0" maxLength={2} autoFocus={true} />
					</div>
					<div className="buttons">
						<button type="button" onClick={modalStopHide} className="btn-cancel">Отменить</button>
						<button type="button" onClick={productStopModal} className="btn-accept btn-delete">В стоп-лист</button>
					</div>
				</div>
			</ModalStop>
			<ModalChangeStatus>
				<div className="modal">
					<div className="header">
						<h4>Отключить позицию</h4>
						<img src={imgClose} alt="" onClick={modalChangeStatusHide} className="btn-close" />
					</div>
					<p>Вы уверены что хотите отключить позицию <b>{productName}?</b></p>
					<div className="buttons">
						<button type="button" onClick={modalChangeStatusHide} className="btn-cancel">Отменить</button>
						<button type="button" onClick={productChangeStatusModal} className="btn-accept btn-delete">Отключить</button>
					</div>
				</div>
			</ModalChangeStatus>
			<ModalProductsRelated>
				<div className="modal modal-products-list">
					<div className="header">
						<h4>Добавить позицию</h4>
						<img src={imgClose} alt="" onClick={modalProductsRelatedClose} className="btn-close" />
					</div>
					<input type="search" placeholder="Поиск" className="search" onChange={searchProductRelated} autoFocus={true} />
					<div className="products">
						<div className="list">
							{productsRelated.length ?
									productsRelated.map((v,i) => <div key={i} className="product">
										<div>
											{v.name}
											<span>{v.prices.priceFull} ₽ • {v.properties.weight} {measureTypeName[v.properties.measureType]}</span>
										</div>
										<img src={related.find(f => f.id === v._id) ? imgCheckOn : imgCheckOff} alt="" onClick={() => productRelatedSelect(v)} />
									</div>)
								:
									<div className="product-empty">
										<div>
											Ничего не найдено
										</div>
									</div>
							}
						</div>
					</div>
				</div>
			</ModalProductsRelated>
			<ModalProductsSupplement>
				<div className="modal modal-products-list">
					<div className="header">
						<h4>Добавить позицию</h4>
						<img src={imgClose} alt="" onClick={modalProductsSupplementClose} className="btn-close" />
					</div>
					<input type="search" placeholder="Поиск" className="search" onChange={searchProductSupplement} autoFocus={true} />
					<div className="products">
						<div className="list">
							{productsSupplement.length ?
									productsSupplement.map((v,i) => <div key={i} className="product">
										<div>
											{v.name}
											<span>{v.prices.priceFull} ₽ • {v.properties.weight} {measureTypeName[v.properties.measureType]}</span>
										</div>
										<img src={supplements.find(f => f.id === v._id) ? imgCheckOn : imgCheckOff} alt="" onClick={() => productSupplementSelect(v)} />
									</div>)
								:
									<div className="product-empty">
										<div>
											Ничего не найдено
										</div>
									</div>
							}
						</div>
					</div>
				</div>
			</ModalProductsSupplement>
			<ModalProductsSkus>
				<div className="modal modal-products-list">
					<div className="header">
						<h4>Добавить позицию</h4>
						<img src={imgClose} alt="" onClick={modalProductsSkusClose} className="btn-close" />
					</div>
					<input type="search" placeholder="Поиск" className="search" onChange={searchProductSkus} autoFocus={true} />
					<div className="products">
						<div className="list">
							{productsSkus.length ?
									productsSkus.map((v,i) => <div key={i} className="product">
										<div>
											{v.name}
											<span>{v.prices.priceFull} ₽ • {v.properties.weight} {measureTypeName[v.properties.measureType]}</span>
										</div>
										<img src={skus.find(f => f.id === v._id) ? imgCheckOn : imgCheckOff} alt="" onClick={() => productSkuSelect(v)} />
									</div>)
								:
									<div className="product-empty">
										<div>
											Ничего не найдено
										</div>
									</div>
							}
						</div>
					</div>
				</div>
			</ModalProductsSkus>
			<ModalProducts>
				<div className="modal modal-products-list">
					<div className="header">
						<h4>Добавить позицию</h4>
						<img src={imgClose} alt="" onClick={modalProductsClose} className="btn-close" />
					</div>
					<input type="search" placeholder="Поиск" className="search" onChange={searchProductModal} autoFocus={true} />
					<div className="products">
						<div className="list">
							{productsComponents.length ?
									productsComponents.map((v,i) => <div key={i} className="product">
										<div>
											{v.name}
											<span>{v.prices.priceFull} ₽ • {v.properties.weight} {measureTypeName[v.properties.measureType]}</span>
										</div>
										<img src={component?.id === v._id ? imgCheckOn : imgCheckOff} alt="" onClick={() => productComponentSelect(v)} />
									</div>)
								:
									<div className="product-empty">
										<div>
											Ничего не найдено
										</div>
									</div>
							}
						</div>
					</div>
				</div>
			</ModalProducts>
			<ModalOptionGroups>
				<div className="modal modal-products-list">
					<div className="header">
						<h4>Добавить группу опций</h4>
						<img src={imgClose} alt="" onClick={modalOptionGroupsClose} className="btn-close" />
					</div>
					<input type="search" placeholder="Поиск" className="search" onChange={searchOptionGroups} autoFocus={true} />
					<div className="products">
						<div className="list">
							{productOptionGroups.length ?
									productOptionGroups.map((v,i) => <div key={i} className="product">
										<div>
											{v.name}
											<span>{v.productOptions.map((v,i) => v.name).join(', ')}</span>
										</div>
										<img src={optionGroups.find(f => f.id === v._id) ? imgCheckOn : imgCheckOff} alt="" onClick={() => optionGroupSelect(v)} />
									</div>)
								:
									<div className="product-empty">
										<div>
											Ничего не найдено
										</div>
									</div>
							}
						</div>
					</div>
				</div>
			</ModalOptionGroups>
			<ModalPriceChange>
				<div className="modal modal-product-price">
					<div className="header">
						<h4>Изменение цены</h4>
						<img src={imgClose} alt="" onClick={modalPriceChangeClose} className="btn-close" />
					</div>
					<p><b>{productName}</b></p>
					<p>Цена: <b>{fullPriceGet()}</b> ₽ {productDiscount ? `• Скидка ${productDiscount}% (${productPrice} ₽)` : null}</p>
					<div className="modal-row modal-row-select">
						<label htmlFor="productPrice" className="label-middle">Новая цена блюда</label>
						<input id="productPrice" {...bindProductChangePrice} type="text" placeholder="0" required autoFocus={true} />
					</div>
					<div className="buttons">
						<button type="button" onClick={modalPriceChangeClose} className="btn-cancel">Отменить</button>
						<button type="button" onClick={productPriceChange} className="btn-accept">Сохранить</button>
					</div>
				</div>
			</ModalPriceChange>
			<ModalPriceChangeMass>
				<div className="modal modal-product-mass">
					<div className="header">
						<h4>Изменение цен</h4>
						<img src={imgClose} alt="" onClick={modalPriceChangeMassClose} className="btn-close" />
					</div>
					<div className="product product-title">
						<div className="name">Название товара и исходные цены</div>
						<div className="price price-wide">Цена, ₽</div>
						<div className="price">Скидка, %</div>
						<div className="price">Итого, ₽</div>
					</div>
					<div className="products">
						{(idsFinal.length ? idsFinal : ids).map((v,i) => <div key={i} className="product">
							<div className="name">
								{v.name}
								{productPriceGet(v._id)}
							</div>
							<div className="price price-wide">
								{priceMassChangeHistory(v._id)}
								<input type="text" placeholder="0" onChange={(e) => productPriceChangeMassHandler(e, v)} maxLength={6} value={v.prices.price} />
							</div>
							<div className="price price-discount">
								<input type="text" placeholder="0" onChange={(e) => productDiscountChangeMassHandler(e, v)} maxLength={2} value={v.discounts.discount} />
							</div>
							<div className="price">
								{v.prices.priceFull}
							</div>
						</div>)}
					</div>
					<div className="controls">
						<div className="amounts">
							<div>
								<label>Изменить все позиции ± на сумму</label>
								<input type="text" {...bindPriceSumChange} placeholder="0" maxLength={5} />
							</div>
							<div>
								<label>± на процент</label>
								<input type="text" {...bindPricePercentChange}placeholder="0" maxLength={3} />
							</div>
							<div>
								<label>Округлять</label>
								 <img src={isPriceRound ? imgCheckOn : imgCheckOff} alt="" onClick={() => setIsPriceRound(!isPriceRound)} />
							</div>
						</div>
						<div className="buttons">
							{idsFinal.length ?
									<>
										<button type="button" className="cancel" onClick={productMassApplyCancel}>Отмена</button>
										<button type="button" onClick={productMassSave}>Сохранить</button>
									</>
								:
									<>
										<button type="button" className={priceSumChange||pricePercentChange?'':'disabled'} onClick={priceSumChange||pricePercentChange?productMassApply:null}>Применить</button>
									</>
							}
						</div>
					</div>
				</div>
			</ModalPriceChangeMass>
		</>
	);
};

export default ProductsScreen;