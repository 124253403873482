import React, {useEffect,useState} from 'react';

// plug-ins
import {useModal} from 'react-hooks-use-modal';
import moment from 'moment';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import SideMenu from '../../../Components/SideMenu';

// models
import {Cashbox, Orders} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';

// constants
import { cashboxPaymentType, cashboxPaymentTypeName, transactionType, transactionTypeNameCashbox } from '../../../Globals/Constants';

// styles
import './styles.css';

// images
import imgPlusButton from './Images/plus-btn.svg';
import imgArrowCurveNE from './Images/arrow-curve-ne.svg';
import imgMenuPoints from './Images/menu-points.svg';
import imgClose from './Images/close.svg';
import imgWarning from './Images/warning.svg';
import imgPlus from './Images/plus-green.svg';
import imgMinus from './Images/minus-red.svg';
import imgCheckOff from './Images/checkbox-off.svg';
import imgCheckOn from './Images/checkbox-on.svg';


// start
const CashboxScreen = () => {
	const [ModalOrders, modalOrdersOpen, modalOrdersClose] = useModal('root');
	const [ModalDelete, modalDeleteOpen, modalDeleteClose] = useModal('root');
	const [loading, setLoading] = useState(true);
	const [orders, setOrders] = useState([]);
	const [ordersAll, setOrdersAll] = useState([]);
	const [order, setOrder] = useState(null);
	const [cashbox, setCashbox] = useState([]);
	const [cashboxList, setCashboxList] = useState([]);
	const [cashboxListAll, setCashboxListAll] = useState([]);
	const [id, setId] = useState(null);
	const [user, setUser] = useState(ls('user'));
	const [createdAt, setCreatedAt] = useState(null);
	const {value:amount, setValue:setAmount, bind:bindAmount} = useInput('');
	const {value:comment, setValue:setComment, bind:bindComment} = useInput('');
	const [orderId, setOrderId] = useState(null);
	const [type, setType] = useState(transactionType.CREDIT);
	const [paymentType, setPaymentType] = useState(null);
	const [search, setSearch] = useState(null);
	const [controlId, setControlId] = useState(null);
	const [isShow, setIsShow] = useState(false);
	const [isEditShow, setIsEditShow] = useState(false);
	const [isClose, setIsClose] = useState(false);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await ordersGet();
			await cashboxGet();
		};
		await dataGet();
		setLoading(false);
	}, []);
	const ordersGet = async () => {
		const orders = await Orders.get.dayshift().catch((ex) => {
			console.log(666, ex);
			if (ex.code === 400) {
				setOrders([]);
				setOrdersAll([]);
			} else setIsError(true);
		});
		if (orders === undefined) return;
		setOrders(orders);
		setOrdersAll(orders);
	};
	const cashboxGet = async () => {
		const cashbox = await Cashbox.get().catch((ex) => {
			console.log(666, ex);
			if (ex.code === 400) {
				const cashbox = {
					orders: {
						cash:[],
						card:[]
					},
					transactions: {
						cash:[],
						card:[]
					},
					dayshift: {
						number:0,
						start:new Date(),
						users: {
							opener: {name:''}
						}
					},
					unpaid:[]
				};
				setIsClose(true);
				setCashbox(cashbox);
				setCashboxList([]);
				setCashboxListAll([]);
			}
			else setIsError(true);
		});
		if (cashbox === undefined) return;
		const cashboxList = [...cashbox.transactions.cash,...cashbox.transactions.card];
		cashboxList.sort((a,b) => new Date(b.createdAt) - new Date(a.createdAt));
		setCashboxList(cashboxList);
		setCashboxListAll(cashboxList);
		setCashbox(cashbox);
		setIsClose(false);
	};
	const cashboxAdd = () => {
		if (isClose) return;
		setIsShow(false);
		setIsEditShow(true);
		show();
	}
	const cashboxShow = (cashbox) => {
		show(cashbox);
		setIsEditShow(false);
		setIsShow(true);
	};
	const show = (cashbox) => {
		setControlId(null);
		setId(cashbox?._id||null);
		setAmount(cashbox?.amount||'');
		setType(cashbox?.type||null);
		setPaymentType(cashbox?.paymentType||null);
		setComment(cashbox?.comment||'');
		setOrderId(cashbox?cashbox.order?.id:null);
		setCreatedAt(cashbox?cashbox.createdAt:null);
		setUser(cashbox?cashbox.user:null);
		setIsEditShow(true);
	};
	const cancel = () => {
		cashboxShow(null);
		setIsEditShow(false);
		setIsShow(false);
		modalDeleteHide();
		modalOrdersClose();
	}
	const save = async (e) => {
		e.preventDefault();
		const data = {
			type:type,
			comment:comment,
			paymentType:paymentType,
			amount:amount
		};
		if (order) data.orderId = order._id;
		if (Utils.empty(data.type)) {
			errorShow('Необходимо выбрать операцию — внесение или изъятие');
			return;
		}
		if (Utils.empty(data.paymentType)) {
			errorShow('Необходимо выбрать тип оплаты');
			return;
		}
		if (Utils.empty(data.amount)) {
			errorShow('Необходимо заполнить сумму');
			return;
		}
		if (!Utils.empty(data.orderId) && Utils.empty(data.comment)) {
			errorShow('В случае выбора заказа необходимо заполнить примечание');
			return;
		}
		const res = await Cashbox.add(data).catch((ex) => {
			console.log(666, ex);
			errorShow();
		});
		if (res) {
			successShow(res.message);
			cashboxGet();
			cancel();
		}
	}
	const searchCashbox = (e) => {
		console.log(111)
		const search = e.target.value;
		if (Utils.empty(search)) setCashboxList(cashboxListAll);
		else setCashboxList(cashboxListAll.filter(f => f.comment.toLowerCase().includes(search.toLowerCase())));
		setSearch(search);
	}
	const cashboxControlSelect = (e, id) => {
		e.stopPropagation();
		if (id === controlId) setControlId(null);
		else setControlId(id);
	}
	const modalDeleteHide = () => modalDeleteClose();
	const modalDeleteShow = (e, id) => {
		e.stopPropagation();
		setControlId(null);
		setId(id);
		const cash = cashboxListAll.find(f => f._id === id);
		const order = cash.orderId ? ordersAll.find(f => f._id === cash.orderId) : null;
		setOrder(order);
		setAmount(cash.amount);
		setType(cash.type);
		modalDeleteOpen();
	}
	const handlePaymentType = (e) => setPaymentType(Utils.empty(e.target.value) ? null : parseInt(e.target.value));
	const cashboxDelete = () => {
		Cashbox.remove(id).then((res) => {
			successShow(res.message);
			cashboxGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
	}
	const calc = (data) => Utils.moneyFormat(calcplain(data));
	const calcplain = (data) => data.reduce((a,v) => a + v.amount, 0);
	const total = (type) => Utils.moneyFormatMinus(totalplain(type));
	const totalplain = (type) => {
		const d1 = calcplain(cashbox.orders[type]),
			d2 = calcplain(cashbox.transactions[type].filter(f => f.type === transactionType.CREDIT)),
			d3 = calcplain(cashbox.transactions[type].filter(f => f.type === transactionType.DEBIT));
		return d1 + d2 - d3;
	}
	const searchOrder = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setOrders(ordersAll);
		else setOrders(ordersAll.filter(f => f.number.toLowerCase().includes(search.toLowerCase()) || f.amounts.amountFull.toString().includes(search.toLowerCase())
			|| f.address.address?.toLowerCase().includes(search.toLowerCase()) || f.client.name?.toLowerCase().includes(search.toLowerCase()) || f.client.phone?.toString().toLowerCase().includes(search.toLowerCase())));
	};
	const ordersShow = () => modalOrdersOpen();
	const orderSelect = (item) => {
		if (order && order._id === item._id) setOrder(null);
		else setOrder(item);
	};
	const orderAdd = () => {
		modalOrdersClose();
		setOrders(ordersAll);
	};
	const orderGet = (id) => {
		const order = ordersAll.find(f => f._id === id);
		return order ? order.number : '';
	}
	const debtClose = (type) => {
		const amount = totalplain(type) * -1;
		setAmount(amount);
		setComment('Закрытие долга');
	};
	return (
		<>
			<Template click={() => setControlId(null)}>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<div className="components-container">
							<div className="categories-container">
								<SideMenu title="Касса" type="money" />
								<div className="products-list">
									<div className="products-list-controls">
										<input type="search" placeholder="Поиск" className="search" onChange={searchCashbox} value={search} />
										<button type="button" onClick={() => cashboxAdd()}>
											<img src={imgPlusButton} alt="" />
										</button>
									</div>
									<div className="container-inner">
										{cashboxList.length > 0 ?
												<table className="items">
													<thead>
														<tr>
															<th>Время</th>
															<th className="weight">Источник</th>
															<th className="prices">Сумма</th>
															<th className="control"></th>
														</tr>
													</thead>
													<tbody>
														{cashboxList.map((v,i) => <tr key={i} onClick={() => cashboxShow(v)}>
															<td>
																{moment(v.createdAt).format('DD.MM.YYYY HH:mm:ss')}
																{v.comment ? <span>{v.comment}</span> : null}
															</td>
															<td className="weight">{cashboxPaymentTypeName[v.paymentType]}</td>
															<td className={`prices${v.type===transactionType.CREDIT?' plus':' minus'}`}>{v.type===transactionType.CREDIT?'':'–'}{v.amount} ₽</td>
															<td className="control">
																<img src={imgMenuPoints} alt="" onClick={(e) => cashboxControlSelect(e, v._id)} />
																{controlId === v._id ?
																		<div className="control-block">
																			<div className="delete" onClick={(e) => modalDeleteShow(e, v._id)}>Удалить</div>
																		</div>
																	: null}
															</td>
														</tr>)}
													</tbody>
												</table>
											:
												<div className="products-empty">
													<div>
														{isClose ?
																<><b>Смена закрыта!</b><br/>для добавления операций<br/>откройте смену</>
															:
																<>Добавьте первую<br/>запись</>
														}
													</div>
													<img src={imgArrowCurveNE} alt="" />
												</div>
										}
									</div>
								</div>
							</div>
							<div className="product-edit">
								{isClose ?
									<div className="product-edit-list-container">
										<div className="container-inner">
											<div className="list">
												<h4>Смена закрыта</h4>
												<div className="product-view-row product-view-row-simple">
													<span className="product-view-row-notice">
														Чтобы начать работу с кассой, откройте смену.<br/>
														Для этого нажмите на пункт меню <b>Смена</b> вверху.
													</span>
												</div>
											</div>
										</div>
									</div>
								: null}
								{!isEditShow && !isShow && !isClose ?
										<div className="product-edit-list-container">
											<div className="container-inner">
												<div className="list">
													<h4>
														Смена №{cashbox.dayshift.number} от {moment(cashbox.dayshift.start).format('DD.MM.YYYY HH:mm:ss')}
														<span className="product-view-row-title-notice">Смену открыл <b>{cashbox.dayshift.users.opener.name}</b></span>
													</h4>
													<div className="product-view-row-simple">
														<div className="product-view-cashbox">
															<div className="product-view-cashbox-item">
																<span className="product-view-cashbox-item-title">Выручка</span>
																<div className="product-view-cashbox-item-data">
																	<div>
																		<span>В кассе</span>
																		<h3>{calc(cashbox.orders.cash)}</h3>
																	</div>
																	<div>
																		<span>На счету</span>
																		<h3>{calc(cashbox.orders.card)}</h3>
																	</div>
																</div>
															</div>
															<div className="product-view-cashbox-item">
																<span className="product-view-cashbox-item-title">Внесено</span>
																<div className="product-view-cashbox-item-data">
																	<div>
																		<span>В кассе</span>
																		<h3>{calc(cashbox.transactions.cash.filter(f => f.type === transactionType.CREDIT))}</h3>
																	</div>
																	<div>
																		<span>На счету</span>
																		<h3>{calc(cashbox.transactions.card.filter(f => f.type === transactionType.CREDIT))}</h3>
																	</div>
																</div>
															</div>
															<div className="product-view-cashbox-item">
																<span className="product-view-cashbox-item-title">Изъято</span>
																<div className="product-view-cashbox-item-data">
																<div>
																		<span>В кассе</span>
																		<h3>{calc(cashbox.transactions.cash.filter(f => f.type === transactionType.DEBIT))}</h3>
																	</div>
																	<div>
																		<span>На счету</span>
																		<h3>{calc(cashbox.transactions.card.filter(f => f.type === transactionType.DEBIT))}</h3>
																	</div>
																</div>
															</div>
															<div className="product-view-cashbox-item product-view-cashbox-item-select">
																<span className="product-view-cashbox-item-title">Всего</span>
																<div className="product-view-cashbox-item-data">
																	<div>
																		<span>В кассе</span>
																		<h3>{total('cash')}</h3>
																	</div>
																	<div>
																		<span>На счету</span>
																		<h3>{total('card')}</h3>
																	</div>
																</div>
															</div>
														</div>
														{cashbox.unpaid && cashbox.unpaid.length ?
															<div className="product-view-row-warning">
																<img src={imgWarning} alt="" />
																Есть не оплаченные заказы на сумму {calc(cashbox.unpaid)} ₽
															</div> : null}
													</div>
													<div className="product-view-row product-view-row-simple">
														<span className="product-view-row-notice-simple">
															Операции по закрытию и открытию смены можно выполнить нажав на пункт меню <b>Смена</b> вверху
														</span>
													</div>
												</div>
											</div>
										</div>
									: null}
								{isShow && !isClose ?
										<div className="product-edit-list-container">
											<div className="container-inner">
												<div className="list">
													<div className="product-view-row product-view-row-simple product-view-row-oneline-up">
														<h3>Информация по операции</h3>
													</div>
													<div className="product-view-row product-view-row-oneline">
														<span>Сумма</span>
														<div>{type===transactionType.CREDIT?'':'–'}{amount}₽</div>
													</div>
													<div className="product-view-row product-view-row-oneline">
														<span>Операция</span>
														<div>{transactionTypeNameCashbox[paymentType]}</div>
													</div>
													<div className="product-view-row product-view-row-oneline">
														<span>Источник</span>
														<div>{cashboxPaymentTypeName[paymentType]}</div>
													</div>
													<div className="product-view-row product-view-row-oneline">
														<span>Дата</span>
														<div>{moment(createdAt).format('DD.MM.YYYY HH:mm:ss')}</div>
													</div>
													{orderId ?
														<div className="product-view-row product-view-row-oneline">
															<span>Заказ</span>
															<div>№ {orderGet(orderId)}</div>
														</div> : null}
													<div className="product-view-row product-view-row-oneline">
														<span>Пользователь</span>
														<div>{user?.name}</div>
													</div>
													{comment ?
														<div className="product-view-row-notice" dangerouslySetInnerHTML={{__html: Utils.rn2br(comment)}} /> : null}
												</div>
											</div>
											<div className="product-edit-footer">
												<button type="button" onClick={cancel} className="btn-cancel btn-cancel-wide">Закрыть</button>
											</div>
										</div>
									: null}
								{isEditShow && !isClose ?
										<form className="product-edit-list-container" onSubmit={save}>
											<div className="container-inner">
												<div className="list">
													<div className="product-edit-row product-edit-row-first">
														<div className="product-edit-row-tabs">
															<div className={`product-edit-row-tab${type===transactionType.CREDIT?' active1':''}`} onClick={() => setType(transactionType.CREDIT)}>
																<img src={imgPlus} alt="" />
																{transactionTypeNameCashbox[transactionType.CREDIT]}
															</div>
															<div className={`product-edit-row-tab${type===transactionType.DEBIT?' active2':''}`} onClick={() => setType(transactionType.DEBIT)}>
																<img src={imgMinus} alt="" />
																{transactionTypeNameCashbox[transactionType.DEBIT]}
															</div>
														</div>
													</div>
													<div className="product-edit-row">
														<div className="select select-wide">
															<select onChange={handlePaymentType} value={paymentType} required>
																<option value="">Выберите источник</option>
																{cashboxPaymentTypeName.map((v,i) => i === 0 ? null : <option key={i} value={i}>{v}</option>)}
															</select>
														</div>
													</div>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="amount" className="label-middle">Сумма{type===transactionType.CREDIT?' для внесения в кассу':null}{type===transactionType.DEBIT?' для изъятия из кассы':null}, ₽</label>
														<input id="amount" {...bindAmount} type="text" placeholder="0" required />
													</div>
													{type === transactionType.DEBIT ?
															(
																paymentType === null ?
																		<div className="product-view-row-notice">
																			в кассе <b>{total('cash')}</b> ₽, на счету <b>{total('card')}</b> ₽
																		</div>
																	: 
																		<>
																			{amount ?
																				(
																					paymentType === cashboxPaymentType.CASH ?
																						<>
																							{totalplain('cash') > 0 && totalplain('cash') < amount ?
																								<div className="product-view-row-notice product-view-row-notice-alert">
																									в кассе образуется долг <b>{Utils.moneyFormatMinus(totalplain('cash') - amount)}</b> ₽
																								</div> : null}
																							{totalplain('cash') < 0 ?
																								<div className="product-view-row-notice product-view-row-notice-alert">
																									в кассе увеличивается долг до <b>{Utils.moneyFormatMinus(totalplain('cash') - amount)}</b> ₽
																								</div> : null}
																						</>
																					:
																						<>
																							{totalplain('cash') > 0 && totalplain('card') < amount ?
																								<div className="product-view-row-notice product-view-row-notice-alert">
																									на счету образуется долг <b>{Utils.moneyFormatMinus(totalplain('card') - amount)}</b> ₽
																								</div> : null}
																							{totalplain('cash') < 0 ?
																								<div className="product-view-row-notice product-view-row-notice-alert">
																									на счету увеличивается долг до <b>{Utils.moneyFormatMinus(totalplain('card') - amount)}</b> ₽
																								</div> : null}
																						</>
																				)
																			:
																				<>
																					{paymentType === cashboxPaymentType.CASH && totalplain('cash') > 0 ?
																						<div className="product-view-row-notice">
																							не более <b>{total('cash')}</b> ₽ из кассы
																						</div> : null}
																					{paymentType === cashboxPaymentType.CASH && totalplain('cash') === 0 ?
																						<div className="product-view-row-notice">
																							в кассе нет денег
																						</div> : null}
																					{paymentType === cashboxPaymentType.CASH && totalplain('cash') < 0 ?
																						<div className="product-view-row-notice product-view-row-notice-alert">
																							в кассе долг <b>{total('cash')}</b> ₽
																						</div> : null}
																					{paymentType === cashboxPaymentType.CARD && totalplain('card') > 0 ?
																						<div className="product-view-row-notice">
																							не более <b>{total('card')}</b> ₽ со счета
																						</div> : null}
																					{paymentType === cashboxPaymentType.CARD && totalplain('card') === 0 ?
																						<div className="product-view-row-notice">
																							на счету нет денег
																						</div> : null}
																					{paymentType === cashboxPaymentType.CARD && totalplain('card') < 0 ?
																						<div className="product-view-row-notice product-view-row-notice-alert">
																							в счету долг <b>{total('card')}</b> ₽
																						</div> : null}
																				</>
																			}
																		</>
															)
														: null}
													{type === transactionType.CREDIT ?
															(
																<>
																	{paymentType === cashboxPaymentType.CARD && totalplain('card') < 0 ?
																		<div className="product-view-row-notice product-view-row-notice-alert">
																			на счету долг <b>{total('card')}</b> ₽. Погасить<span onClick={() => debtClose('card')}>долг</span>
																		</div> : null}
																	{paymentType === cashboxPaymentType.CASH && totalplain('cash') < 0 ?
																		<div className="product-view-row-notice product-view-row-notice-alert">
																			в кассе долг <b>{total('cash')}</b> ₽. Погасить <span onClick={() => debtClose('cash')}>долг</span>
																		</div> : null}
																</>
															)
														: null}
													<div className="product-edit-row">
														<textarea placeholder="Примечание" {...bindComment} className="lite"></textarea>
													</div>
													{orders.length ?
														<>
															<h4>Выбрать заказ</h4>
															<div className="product-edit-row">
																{order ?
																		<div className="product-edit-order-block">
																			<div>
																				<b>Заказ № {order.number}</b> • {order.amounts.amountFull}₽ <span>{order.client && order.client.name ? `• ${order.client.name}` : ''}</span>
																				<div className="info">
																					{order.properties.isPickup ? 'самовывоз' : `доставка на ${order.address.address}`}
																				</div>
																			</div>
																			<img src={imgClose} alt="" onClick={() => setOrder(null)} />
																		</div>
																	: null
																}
																<div className="product-edit-btn" onClick={() => ordersShow()}>Выбрать</div>
															</div>
														</> : null}
												</div>
											</div>
											<div className="product-edit-footer">
												<button type="button" onClick={cancel} className="btn-cancel">Отменить</button>
												<button type="submit" className="btn-save">Сохранить</button>
											</div>
										</form>
									: null}
							</div>
						</div>
					)
				}
			</Template>
			<Alert />
			<ModalOrders>
				<div className="modal modal-products">
					<div className="header">
						<h4>Выбрать заказ</h4>
						<img src={imgClose} alt="" onClick={modalOrdersClose} className="btn-close" />
					</div>
					<input type="search" placeholder="Поиск" className="search" onChange={searchOrder} autoFocus={true} />
					<div className="products">
						<div className="list">
							{orders.length ?
									orders.map((v,i) => <div key={i} className="product">
										<div>
											Заказ № {v.number} • <b>{v.amounts.amountFull}₽</b>{v.client && v.client.name ? ` • ${v.client.name}` : ''}
											<span>{v.properties.isPickup ? 'самовывоз' : `доставка на ${v.address.address}`}</span>
										</div>
										<img src={order?._id === v._id ? imgCheckOn : imgCheckOff} alt="" onClick={() => orderSelect(v)} />
									</div>)
								:
									<div className="product-empty">
										<div>
											Ничего не найдено
										</div>
									</div>
							}
						</div>
						<div className="buttons">
							<button type="button" onClick={orderAdd} className="btn-accept">Добавить</button>
						</div>
					</div>
				</div>
			</ModalOrders>
			<ModalDelete>
				<div className="modal">
					<div className="header">
						<h4>Удалить запись</h4>
						<img src={imgClose} alt="" onClick={modalDeleteHide} className="btn-close" />
					</div>
					<p>
						{type === transactionType.CREDIT ? 'Внесение' : 'Изъятие' } на <b>{type === transactionType.CREDIT ? '' : '–'}{Utils.moneyFormat(amount)}₽</b><br/>
						Вы уверены что хотите удалить запись?<br/><br/>
						Эту операцию нельзя отменить!
					</p>
					<div className="buttons">
						<button type="button" onClick={modalDeleteHide} className="btn-cancel">Отменить</button>
						<button type="button" onClick={cashboxDelete} className="btn-accept btn-delete">Удалить</button>
					</div>
				</div>
			</ModalDelete>
		</>
	);
};

export default CashboxScreen;