import React, {useEffect,useState} from 'react';

// plug-ins
import InputMask from 'react-input-mask';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import Layout from '../../../Components/Layout';

// models
import {Suppliers} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';

// constants
import { commonStatusName } from '../../../Globals/Constants';


// start
const SuppliersScreen = () => {
	const [loading, setLoading] = useState(true);
	const [suppliersAll, setSuppliersAll] = useState([]);
	const [suppliers, setSuppliers] = useState(null);
	const [id, setId] = useState(null);
	const {value:name, setValue:setName, bind:bindName} = useInput('');
	const {value:description, setValue:setDescription, bind:bindDescription} = useInput('');
	const {value:phone, setValue:setPhone, bind:bindPhone} = useInput('');
	const {value:email, setValue:setEmail, bind:bindEmail} = useInput('');
	const {value:address, setValue:setAddress, bind:bindAddress} = useInput('');
	const {value:contact, setValue:setContact, bind:bindContact} = useInput('');
	const {value:inn, setValue:setInn, bind:bindInn} = useInput('');
	const {value:kpp, setValue:setKpp, bind:bindKpp} = useInput('');
	const [search, setSearch] = useState(null);
	const [status, setStatus] = useState(null);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await suppliersGet();
		};
		await dataGet();
		setLoading(false);
	}, []);
	const suppliersGet = async () => {
		const suppliers = await Suppliers.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (suppliers === undefined) return;
		setSuppliers(suppliers);
		setSuppliersAll(suppliers);
	};
	const supplierAdd = () => show();
	const supplierEdit = (supplier) => {
		supplier = supplier || suppliers.find(f => f._id === id);
		show(supplier);
	};
	const supplierShow = (supplier) => show(supplier);
	const show = (supplier) => {
		setId(supplier?._id||null);
		setName(supplier?.name||'');
		setDescription(supplier?.description||'');
		setPhone(supplier?.contacts.phone||'');
		setEmail(supplier?.contacts.email||'');
		setContact(supplier?.contacts.contact||'');
		setAddress(supplier?.requisites.address||'');
		setInn(supplier?.requisites.inn||'');
		setKpp(supplier?.requisites.kpp||'');
		setStatus(supplier?supplier.status:'');
	};
	const cancel = () => supplierShow(null);
	const save = async () => {
		const data = {
			name:name,
			description:description,
			phone:phone,
			email:email,
			contact:contact,
			address:address,
			inn:inn,
			kpp:kpp,
			status:status
		};
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить наименование поставщика');
			return;
		}
		const res = id ?
				await Suppliers.update(id, data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				})
			:
				await Suppliers.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
		if (res) {
			successShow(res.message);
			suppliersGet();
			cancel();
			return true;
		}
	}
	const searchSuppliers = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setSuppliers(suppliersAll);
		else setSuppliers(suppliersAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase())));
		setSearch(search);
	}
	const supplierDelete = () => {
		Suppliers.remove(id).then((res) => {
			successShow(res.message);
			suppliersGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
		return true;
	}
	const handleStatus = (e) => setStatus(parseInt(e.target.value));
	const massCopy = async (ids) => {
		for (const v of ids) {
			const s = suppliers.find(f => f._id === v);
			if (s) {
				const data = {
					name:`${s.name} (копия)`,
					url:s.url,
					description:s.description
				};
				await Suppliers.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
			}
		};
		successShow('Операция прошла успешно');
		suppliersGet();
		cancel();
		return true;
	};
	const massDelete = async (ids) => {
		for (const v of ids) {
			await Suppliers.remove(v).catch((ex) => {
				console.log(666, ex);
				errorShow();
			});
		};
		successShow('Операция прошла успешно');
		suppliersGet();
		cancel();
		return true;
	};
	return (
		<>
			<Template>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<Layout
							title="Поставщики"
							type="books"
							data={suppliers}
							id={id}
							search={search}
							rows={[
								{title:'Название',field:'name'}
							]}
							footerItems={[
								{title:'Сделать копию',action:massCopy},
								{title:'Удалить',action:massDelete,confirm:true}
							]}
							empty={<>Добавьте первую<br/>причину списания</>}
							contentShowTitle={name}
							contentShow={<>
								{description ?
									<div className="product-view-row product-view-row-simple" dangerouslySetInnerHTML={{__html: Utils.rn2br(description)}} /> : null}
								{phone || email || contact ? <h4>Контакты</h4> : null}
								{phone ?
									<div className="product-view-row product-view-row-oneline">
										<span>Телефон</span>
										<div>{Utils.phoneFormatter(phone)}</div>
									</div> : null}
								{email ?
									<div className="product-view-row product-view-row-oneline">
										<span>E-mail</span>
										<div>{email}</div>
									</div> : null}
								{contact ?
									<div className="product-view-row product-view-row-oneline">
										<span>Контактное лицо</span>
										<div>{contact}</div>
									</div> : null}
								{address || inn || kpp ? <h4>Реквизиты</h4> : null}
								{address ?
									<div className="product-view-row product-view-row-oneline">
										<span>Адрес</span>
										<div>{address}</div>
									</div> : null}
								{inn ?
									<div className="product-view-row product-view-row-oneline">
										<span>ИНН</span>
										<div>{inn}</div>
									</div> : null}
								{kpp ?
									<div className="product-view-row product-view-row-oneline">
										<span>КПП</span>
										<div>{kpp}</div>
									</div> : null}
							</>}
							contentEdit={<>
								<div className="product-edit-row">
									<input type="text" {...bindName} placeholder="Наименование поставщика" className="input-title" autoFocus={true} required />
								</div>
								<div className="product-edit-row">
									<textarea placeholder="Описание" {...bindDescription}></textarea>
								</div>
								<h4>Контакты</h4>
								<div className="product-edit-row">
									<InputMask type="tel" mask="+7 (999) 999-99-99" placeholder="Телефон" maskPlaceholder={null} {...bindPhone} />
								</div>
								<div className="product-edit-row">
									<input type="email" placeholder="E-mail" {...bindEmail} />
								</div>
								<div className="product-edit-row">
									<input type="text" placeholder="Контактное лицо" {...bindContact} />
								</div>
								<h4>Реквизиты</h4>
								<div className="product-edit-row">
									<input type="text" placeholder="Адрес" {...bindAddress} />
								</div>
								<div className="product-edit-row">
									<input type="text" placeholder="ИНН" {...bindInn} maxLength={12} />
								</div>
								<div className="product-edit-row">
									<input type="text" placeholder="КПП" {...bindKpp} maxLength={9} />
								</div>
								<h4>Дополнительные параметры</h4>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="status">Статус</label>
									<div className="select select-middle">
										<select id="status" onChange={handleStatus} value={status} required>
											<option value=""></option>
											{commonStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
										</select>
									</div>
								</div>
							</>}
							onDelete={supplierDelete}
							onEdit={supplierEdit}
							onAdd={supplierAdd}
							onSave={save}
							onSearch={searchSuppliers}
							onShow={supplierShow}
							onClose={cancel}
						/>
					)
				}
			</Template>
			<Alert />
		</>
	);
};

export default SuppliersScreen;