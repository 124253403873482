import React, {useEffect,useState} from 'react';

// plug-ins
import {useModal} from 'react-hooks-use-modal';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Empty from '../../../Components/Empty';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import Layout from '../../../Components/Layout';

// models
import {ComponentCategories, Components, Points, ProductOptionGroups, ProductOptions} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';

// constants
import { API, commonStatus, commonStatusName, measureTypeName } from '../../../Globals/Constants';

// styles
import './styles.css';

// images
import imgPlus from './Images/plus-black.svg';
import imgPlusButton from './Images/plus-btn.svg';
import imgArrowCurveDown from './Images/arrow-curve-down.svg';
import imgClose from './Images/close.svg';
import imgCheckOff from './Images/checkbox-off.svg';
import imgCheckOn from './Images/checkbox-on.svg';
import imgNoImage from './Images/no-image.svg';
import imgAdd from './Images/add.svg';


// start
const ProductOptionsScreen = () => {
	const componentAmountGet = (t) => {
		const netto = t.id === 'netto' ? t.value : itemNetto || 0;
		const loss = t.id === 'loss' ? t.value : itemLoss || 0;
		const amt = t.id === 'amount' ? t.value : amount || 0;
		const w = netto - loss;
		const a = amt * w;
		setTotalWeight(w > 0 ? Number(w.toFixed(3)) : 0);
		setTotalAmount(a > 0 ? Number(a.toFixed(2)) : 0);
	};
	const [ModalComponent, modalComponentOpen, modalComponentClose] = useModal('root');
	const [ModalItem, modalItemOpen, modalItemClose] = useModal('root');
	const [ModalComponentCategory, modalComponentCategoryOpen, modalComponentCategoryClose] = useModal('root');
	const [ModalItemComponents, modalItemComponentsOpen, modalItemComponentsClose] = useModal('root');
	const [loading, setLoading] = useState(true);
	const [partnerId, setPartnerId] = useState(null);
	const [optionsAll, setOptionsAll] = useState([]);
	const [options, setOptions] = useState(null);
	const [groups, setGroups] = useState([]);
	const [points, setPoints] = useState([]);
	const [id, setId] = useState(null);
	const {value:name, setValue:setName, bind:bindName} = useInput('');
	const {value:description, setValue:setDescription, bind:bindDescription} = useInput('');
	const {value:price, setValue:setPrice, bind:bindPrice} = useInput('');
	const {value:weight, setValue:setWeight, bind:bindWeight} = useInput('');
	const {value:measureType, setValue:setMeasureType, bind:bindMeasureType} = useInput('');
	const [optionGroups, setOptionGroups] = useState([]);
	const [search, setSearch] = useState(null);
	const {value:componentCategoryName, setValue:setComponentCategoryName, bind:bindComponentCategoryName} = useInput('');
	const [items, setItems] = useState([]);
	const {value:amount, setValue:setAmount, bind:bindAmount} = useInput('', componentAmountGet);
	const {value:itemBrutto, setValue:setItemBrutto, bind:bindItemBrutto} = useInput('');
	const {value:itemNetto, setValue:setItemNetto, bind:bindItemNetto} = useInput('', componentAmountGet);
	const {value:itemLoss, setValue:setItemLoss, bind:bindItemLoss} = useInput('', componentAmountGet);
	const [totalWeight, setTotalWeight] = useState(0);
	const [totalAmount, setTotalAmount] = useState(0);
	const [component, setComponent] = useState(null);
	const [components, setComponents] = useState([]);
	const [componentCategoryId, setComponentCategoryId] = useState(null);
	const [componentCategories, setComponentCategories] = useState([]);
	const {value:componentName, setValue:setComponentName, bind:bindComponentName} = useInput('');
	const {value:componentDescription, setValue:setComponentDescription, bind:bindComponentDescription} = useInput('');
	const {value:componentWeight, setValue:setComponentWeight, bind:bindComponentWeight} = useInput('');
	const {value:componentMeasureType, setValue:setComponentMeasureType, bind:bindComponentMeasureType} = useInput('');
	const {value:componentPrice, setValue:setComponentPrice, bind:bindComponentPrice} = useInput('');
	const {value:componentInvoicePrice, setValue:setComponentInvoicePrice, bind:bindComponentInvoicePrice} = useInput('');
	const {value:componentMinimum, setValue:setComponentMinimum, bind:bindComponentMinimum} = useInput('');
	const {value:componentRequestCount, setValue:setComponentRequestCount, bind:bindComponentRequestCount} = useInput('');
	const [componentStatus, setComponentStatus] = useState('');
	const [isPieces, setIsPieces] = useState(false);
	const [image, setImage] = useState(null);
	const [imageName, setImageName] = useState(null);
	const [status, setStatus] = useState(null);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await pointsGet();
			await componentCategoriesGet();
			await componentsGet();
			await optionGroupsGet();
			await optionsGet();
			const point = ls('point');
			setPartnerId(point.partnerId);
		};
		await dataGet();
		setLoading(false);
	}, []);
	const pointsGet = async () => {
		const points = await Points.get.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (points === undefined) return;
		setPoints(points);
	};
	const componentsGet = async () => {
		const components = await Components.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (components === undefined) return;
		setComponents(components);
	};
	const componentCategoriesGet = async () => {
		const categories = await ComponentCategories.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (categories === undefined) return;
		setComponentCategories(categories);
	};
	const optionGroupsGet = async () => {
		const groups = await ProductOptionGroups.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (groups === undefined) return;
		setGroups(groups);
	};
	const optionsGet = async () => {
		const options = await ProductOptions.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (options === undefined || options.length === 0) return;
		setOptions(options);
		setOptionsAll(options);
	};
	const optionAdd = () => show();
	const optionEdit = (option) => {
		option = option || options.find(f => f._id === id);
		show(option);
	};
	const optionShow = (option) => show(option);
	const show = (option) => {
		setId(option?._id||null);
		setName(option?.name||'');
		setDescription(option?.description||'');
		setPrice(option?.price||'');
		setWeight(option?.properties.weight||'');
		setMeasureType(option?.properties.measureType||'');
		setItems(option?.components||[]);
		setImage(option && option.image ? imageUrlGet(partnerId, option._id, option.image) : null);
		setImageName(option?.image||null);
		setOptionGroups(option?.groups||[]);
		setStatus(option?option.status:'');
	};
	const cancel = () => {
		optionShow(null);
		modalItemComponentsClose();
		modalItemClose();
		modalComponentCategoryClose();
		modalComponentClose();
	}
	const save = async () => {
		const data = {
			groups:optionGroups.map(v => v.id),
			name:name,
			description:description,
			price:price,
			weight:weight,
			measureType:measureType,
			components:items,
			status:status
		};
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить название блюда');
			return;
		}
		if (Utils.empty(data.price)) {
			errorShow('Необходимо указать цену опции');
			return;
		}
		const res = id ?
				await ProductOptions.update(id, data).catch((ex) => {
					console.log(666, ex);
					errorShow(ex.message);
				})
			:
				await ProductOptions.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow(ex.message);
				});
		if (res) {
			if (image) imageUpload(id||res.id);
			successShow(res.message);
			optionsGet();
			cancel();
			return true;
		}
	}
	const imageUpload = async (id) => {
		const data = imagePrepare(image);
		if (data) {
			const type = Utils.imageExtGet(image);
			ProductOptions.image.add(id, {data,type}).catch((ex) => console.log(666, ex));
		}
	}
	const imagePrepare = (image) => Utils.isImage(image) ? image.replace(/^.*,/gi,'') : null;
	const handleMeasureType = (e) => setMeasureType(parseInt(e.target.value));
	const handleStatus = (e) => setStatus(parseInt(e.target.value));
	const handleComponentStatus = (e) => setComponentStatus(parseInt(e.target.value));
	const searchOption = (e) => {
		const search = e.target.value;
		setSearch(search);
		if (Utils.empty(search)) setOptions(optionsAll);
		else setOptions(optionsAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase())));
	}
	const optionDelete = (id) => {
		ProductOptions.remove(id).then((res) => {
			successShow(res.message);
			optionsGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
		return true;
	}
	const componentsItemShow = () => modalItemComponentsOpen();
	const componentSelect = (component) => {
		setComponent(component);
		const amount = component.prices?.manual||component.prices?.invoice||0;
		setAmount(amount);
		modalItemComponentsClose();
	}
	const componentAdd = async (e) => {
		e.preventDefault();
		if (!component) {
			errorShow('Необходимо выбрать компонент');
			return;
		}
		const data = {
			id:component._id,
			name:component.name,
			brutto:parseFloat(itemBrutto),
			netto:parseFloat(itemNetto),
			loss:parseFloat(itemLoss),
			amount:parseFloat(amount),
			weight:totalWeight,
			total:totalAmount
		};
		if (Utils.empty(data.brutto) || Utils.empty(data.netto) || Utils.empty(data.loss) || Utils.empty(data.amount)) {
			errorShow('Необходимо заполнить все поля');
			return;
		}
		setItems([...items, data]);
	}
	const componentCategoryShow = () => {
		setComponentCategoryName('');
		modalComponentCategoryOpen();
	}
	const componentCategorySave = async () => {
		if (Utils.empty(componentCategoryName)) return;
		const data = {name:componentCategoryName};
		const res = await ComponentCategories.add(data).catch((ex) => {
			console.log(666, ex);
			errorShow();
		});
		if (res) {
			successShow(res.message);
			componentCategoriesGet();
			modalComponentCategoryClose();
		}
	}
	const componentAddShow = () => {
		setComponentName('');
		setComponentDescription('');
		setComponentPrice('');
		setComponentInvoicePrice('');
		setComponentMinimum('');
		setComponentRequestCount('');
		setComponentWeight('');
		setComponentMeasureType('');
		setIsPieces(false);
		setComponentStatus('');
		modalComponentOpen();
	}
	const handleComponentCategory = (e) => setComponentCategoryId(e.target.value);
	const handleComponentMeasureType = (e) => setComponentMeasureType(parseInt(e.target.value));
	const itemShow = () => {
		setAmount('');
		setItemBrutto('');
		setItemNetto('');
		setItemLoss('');
		setTotalWeight(0);
		setTotalAmount(0);
		setComponent(null);
		setComponentCategoryId(null);
		modalItemOpen();
	}
	const componentSave = async (e) => {
		e.preventDefault();
		const data = {
			categoryId:componentCategoryId,
			name:componentName,
			description:componentDescription,
			price:componentPrice,
			requestCount:componentRequestCount,
			minimum:componentMinimum,
			weight:componentWeight,
			measure:componentMeasureType,
			isPieces:isPieces
		};
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить название компонента');
			return;
		}
		if (Utils.empty(data.weight)) {
			errorShow('Необходимо заполнить объем / вес компонента');
			return;
		}
		if (data.measure === null) {
			errorShow('Необходимо заполнить единицу измерения компонента');
			return;
		}
		const res = id ?
				await Components.update(id, data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				})
			:
				await Components.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
		if (res) {
			successShow(res.message);
			componentsGet();
			modalComponentClose();
		}
	}
	const statusGet = (v) => <div className={`status${v.status===commonStatus.ACTIVE?v.status:0}`}>
		<div className="status-point"></div>
	</div>;
	const massCopy = async (ids) => {
		for (const v of ids) {
			const s = options.find(f => f._id === v);
			if (s) {
				const data = {
					groups:s.groups.map(v => v.id),
					name:`${s.name} (копия)`,
					description:s.description,
					price:s.price,
					weight:s.properties.weight,
					measureType:s.properties.measureType,
					components:s.components,
					image:s.image,
					status:s.status
				};
				const res = await ProductOptions.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
				if (res) ProductOptions.image.copy(s._id, res.id).catch((ex) => console.log(666, ex));
			}
		};
		successShow('Операция прошла успешно');
		optionsGet();
		cancel();
		return true;
	};
	const massDelete = async (ids) => {
		for (const v of ids) {
			await ProductOptions.remove(v).catch((ex) => {
				console.log(666, ex);
				errorShow();
			});
		};
		successShow('Операция прошла успешно');
		optionsGet();
		cancel();
		return true;
	};
	const pointGet = (item) => {
		const p = item.points;
		const out = [];
		if (p && p.length !== points.length) {
			for (let i = 0; i < p.length; i++) {
				out.push(points.find(f => f._id === p[i].id).name);
			}
		}
		return out.length === 0 ? 'Все филиалы' : out.join(', ');
	};

	const imageUrlGet = (partnerId, id, filename) => `${API.assets}partners/${partnerId}/productoptions/${id}/${filename}`;
	const imageError = (e) => e.target.src = imgNoImage;
	const imageDelete = () => setImage(null);
	const handleImage = (e) => {
		const file = e.target.files?.[0];
		if (!file) return;
		const reader = new FileReader();
		reader.onload = (r) => setImage(r.target.result);
		reader.readAsDataURL(file);
	};
	const imageGet = (v) => <img src={imageUrlGet(partnerId, v._id, v.image)} alt="" onError={imageError} />;
	const inGroup = (id) => optionGroups.find(f => f.id === id);
	const groupSelect = (group) => {
		if (inGroup(group._id)) setOptionGroups(optionGroups.filter(f => f.id !== group._id));
		else {
			setOptionGroups([...optionGroups, {
				id:group._id,
				name:group.name
			}]);
		}
	};
	return (
		<>
			<Template>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<Layout
							title="Опции"
							type="books"
							data={options}
							id={id}
							search={search}
							sortIndex={1}
							rows={[
								{title:'',field:'image',class:'avatar',func:imageGet},
								{title:'Название',field:'name'},
								{title:'Филиал',field:'points',class:'area',func:pointGet},
								{title:'Цена',field:'price',class:'prices'},
								{title:'Объем',field:'weight',class:'weight',func:(v) => `${v.properties.weight} ${measureTypeName[v.properties.measureType]}`,sort:'properties.weight'},
								{title:'Статус',field:'status',class:'status',func:statusGet}
							]}
							rowsShort={['image','name','prices','status']}
							footerItems={[
								{title:'Сделать копию',action:massCopy},
								{title:'Удалить',action:massDelete,confirm:true}
							]}
							empty={<>Добавьте первую<br/>позицию опцию</>}
							contentShowTitle={name}
							contentShow={<>
								<div className="product-view-row product-view-row-simple">
									<p className="description">{description}</p>
								</div>
								<div className="product-view-row product-view-row-oneline">
									<span>Цена</span>
									<div>{price} ₽</div>
								</div>
								<div className="product-view-row product-view-row-oneline">
									<span>Объем / Вес</span>
									<div>{weight} {measureTypeName[measureType]}</div>
								</div>
								{items && items.length ?
									<>
										<h4>Компоненты</h4>
										<div className="product-edit-components-block">
											{items.map((v,i) => <div key={i} className="product-edit-component-item">
												<div className="product-edit-component-item-name">
													<b>{v.name}</b>
												</div>
												<div className="info">
													<div>брутто: {v.brutto}кг • нетто: {v.netto}кг • потери: {v.loss}кг</div>
													<div><b>вес: {v.weight}кг • цена: {v.total}₽</b></div>
												</div>
											</div>)}
										</div>
									</> : null}
								{image ?
									<>
									{console.log(1111, image)}
										<h4>Фотография</h4>
										<div className="product-view-row product-view-row-simple product-edit-image">
											<img src={imageUrlGet(partnerId, id, imageName)} alt="" onError={imageError} className="image-small" />
										</div>
									</> : null}
							</>}
							contentEdit={<>
								<div className="product-edit-row">
									<input type="text" {...bindName} placeholder="Название опции" className="input-title" autoFocus={true} required />
								</div>
								<div className="product-edit-row">
									<textarea placeholder="Описание" {...bindDescription}></textarea>
								</div>
								<h4>Группа опций</h4>
								<div className="product-edit-row">
									{groups.length ?
										<div className="product-edit-area">
											{groups.map((v,i) => <div key={i} className="product-edit-area product-edit-row-oneline-start">
												<img src={inGroup(v._id) ? imgCheckOn : imgCheckOff} alt="" className="checkbox" onClick={() => groupSelect(v)} />
												{v.name}
											</div>)}
										</div> : null}
								</div>
								<h4>Цена</h4>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="productPrice" className="label-middle">Цена блюда</label>
									<input id="productPrice" {...bindPrice} type="text" placeholder="0" required />
								</div>
								<h4>Фотографии блюда</h4>
								<div className="product-edit-row">
									{image ?
										<div className="product-edit-images">
											<div className="product-edit-image">
												<img src={image} alt="" onError={imageError} />
												<div className="delete" onClick={imageDelete}></div>
											</div>
										</div> : null}
									<label>
										<div className="product-edit-btn">
											Добавить фото
											<input type="file" accept="image/jpeg,image/png,image/webp" onChange={handleImage} />
										</div>
									</label>
								</div>
								<h4>Объем / Вес</h4>
								<div className="product-edit-row product-edit-row-oneline">
									<input type="text" {...bindWeight} placeholder="Объем / Вес" required />
									<div className="select">
										<select onChange={handleMeasureType} value={measureType} required>
											{measureTypeName.map((v,i) => <option key={i} value={i}>{v}</option>)}
										</select>
									</div>
								</div>
								<h4>Компоненты</h4>
								<div className="product-edit-row">
									{items.length ?
											<div className="product-edit-components-block">
												{items.map((v,i) => <div key={i} className="product-edit-component-item">
													<div className="product-edit-component-item-name">
														<b>{v.name}</b>
														<img src={imgClose} alt="" onClick={() => setItems(items.filter(f => f.id !== v.id))} />
													</div>
													<div className="info">
														<div>брутто: {v.brutto}кг • нетто: {v.netto}кг • потери: {v.loss}кг</div>
														<div><b>вес: {v.weight}кг • цена: {v.total}₽</b></div>
													</div>
												</div>)}
											</div>
										: null
									}
									<div className="product-edit-btn" onClick={() => itemShow()}>Добавить</div>
								</div>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="status">Статус</label>
									<div className="select select-middle">
										<select id="status" onChange={handleStatus} value={status} required>
											<option value=""></option>
											{commonStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
										</select>
									</div>
								</div>
							</>}
							onDelete={optionDelete}
							onEdit={optionEdit}
							onAdd={optionAdd}
							onSave={save}
							onSearch={searchOption}
							onShow={optionShow}
							onClose={cancel}
						/>
					)
				}
			</Template>
			<Alert />
			<ModalItem>
				<div className="modal modal-items">
					<div className="header">
						<h4>Добавить компонент</h4>
						<img src={imgClose} alt="" onClick={modalItemClose} className="btn-close" />
					</div>
					<form onSubmit={componentAdd}>
						{component ?
								<div>
									<b>{component.name}</b> • <span>{component.prices?.manual||component.prices?.invoice||0} ₽ за {component.weight} {measureTypeName[component.measure]}</span>
								</div>
							: null}
						<div className="buttons">
							<button type="button" className="btn-cancel btn-select" onClick={() => componentsItemShow()}>Компонент</button>
						</div>
						<div className="weights-form">
							<div className="weights-form-row">
								<label htmlFor="brutto">Цена<span>компонента за 1 кг</span></label>
								<div>
									<input type="text" {...bindAmount} placeholder="0" maxLength={5} required />
									<span>₽</span>
								</div>
							</div>
						</div>
						<div className="weights-form">
							<div className="weights-form-row">
								<label htmlFor="brutto">Брутто<span>вес с упаковкой</span></label>
								<div>
									<input id="brutto" type="text" {...bindItemBrutto} placeholder="0" maxLength={5} required />
									<span>кг</span>
								</div>
							</div>
							<div className="weights-form-row">
								<label htmlFor="netto">Нетто<span>чистый вес</span></label>
								<div>
									<input id="netto" type="text" {...bindItemNetto} placeholder="0" maxLength={5} required />
									<span>кг</span>
								</div>
							</div>
							<div className="weights-form-row">
								<label htmlFor="loss">Потери</label>
								<div>
									<input id="loss" type="text" {...bindItemLoss} placeholder="0" maxLength={5} required />
									<span>кг</span>
								</div>
							</div>
						</div>
						<div className="weights-form">
							<div className="weights-form-row">
								<label>Выход веса</label>
								<div>
									<b>{totalWeight} кг</b>
								</div>
							</div>
							<div className="weights-form-row">
								<label>Сумма</label>
								<div>
									<b>{totalAmount} ₽</b>
								</div>
							</div>
						</div>
						<div className="buttons">
							<button type="submit" className="btn-accept">Добавить</button>
						</div>
					</form>
				</div>
			</ModalItem>
			<ModalItemComponents>
				<div className="modal modal-conditions-lite">
					<div className="header">
						<div className="header-sub">
							<h4>Добавить компонент</h4>
							<img src={imgAdd} alt="" className="add" onClick={() => componentAddShow()} />
						</div>
						<img src={imgClose} alt="" onClick={modalItemComponentsClose} className="btn-close" />
					</div>
					<div className="conditions">
						<div className="sections">
							<div className="inner">
								{componentCategories.length ?
										<div className="list">
											{componentCategories.map((v,i) => <div key={i} className={`section${componentCategoryId===v._id?' section-select':''}`} onClick={() => setComponentCategoryId(v._id)}>
												{v.name}
											</div>)}
										</div>
									:
										<div className="category-empty">
											<div>
												Здесь можно добавить новые категории, чтобы заполнить меню
											</div>
											<img src={imgArrowCurveDown} alt="" />
										</div>
								}
							</div>
							<div className="sections-footer">
								<button type="button" onClick={() => componentCategoryShow()}>
									<img src={imgPlus} alt="" />
										Добавить категорию
								</button>
							</div>
						</div>
						<div className="content">
							{components.filter(f => f.category.id === componentCategoryId).length ?
									<div className="components-list">
										{components.filter(f => f.category.id === componentCategoryId).map((v,i) => <div key={i} className="component" onClick={() => componentSelect(v)}>
											{v.name}
											<span>{v.category.name} • {v.prices?.manual||v.prices?.invoice||0} ₽ • {v.weight} {measureTypeName[v.measure]}</span>
										</div>)}
									</div>
								:
									<div className="empty">
										<Empty image={'components'} text={
											<>
												Для добавления компонента,<br />выберите один из списка слева
												<div className="product-add-container">
													<p>или<br/>добавьте новую позицию</p>
													<button type="button" onClick={() => componentAddShow()}>Добавить компонент</button>
												</div>
											</>} />
									</div>
								}
						</div>
					</div>
				</div>
			</ModalItemComponents>
			<ModalComponent>
				<div className="modal modal-component-newadd">
					<div className="header">
						<h4>Добавить компонент</h4>
						<img src={imgClose} alt="" onClick={modalComponentClose} className="btn-close" />
					</div>
					<form className="product-edit" onSubmit={componentSave}>
						<div className="container-inner">
							<div className="list">
								<div className="product-edit-row">
									<input type="text" {...bindComponentName} placeholder="Название компонента" autoFocus={true} required />
								</div>
								<div className="product-edit-row">
									<textarea placeholder="Описание" {...bindComponentDescription} className="lite"></textarea>
								</div>
								<div className="product-edit-row product-edit-row-oneline product-edit-row-plus">
									<div className="select select-wide">
										<select onChange={handleComponentCategory} value={componentCategoryId} required>
											<option value="">Выберите категорию</option>
											{componentCategories.map((v,i) => <option key={i} value={v._id}>{v.name}</option>)}
										</select>
									</div>
									<img src={imgPlusButton} alt="" className="categoty-add" onClick={() => componentCategoryShow()} />
								</div>
								<h4>Объем / Вес</h4>
								<div className="product-edit-row product-edit-row-oneline">
									<input type="text" {...bindComponentWeight} placeholder="Объем / Вес" maxLength={4} required />
									<div className="select">
										<select onChange={handleComponentMeasureType} value={componentMeasureType} required>
											{measureTypeName.map((v,i) => <option key={i} value={i===0?'':i}>{v}</option>)}
										</select>
									</div>
								</div>
								<h4>Цены</h4>
								<div className="product-edit-row product-edit-row-oneline product-edit-row-select">
									<label className="label-middle">Цена из последней накладной</label>
									{componentInvoicePrice?`${componentInvoicePrice} ₽`:'–'}
								</div>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="price" className="label-middle">Цена (коррекция), ₽</label>
									<input id="price" {...bindComponentPrice} type="text" placeholder="0" maxLength={6} />
								</div>
								<h4>Свойства</h4>
								<div className="product-edit-row product-edit-row-oneline">
									<label className="label-middle">Штучный, количественный товар</label>
									<img src={isPieces ? imgCheckOn : imgCheckOff} alt="" onClick={() => setIsPieces(!isPieces)} />
								</div>
								<h4>Дополнительные параметры</h4>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="minimum" className="label-middle">Минимальный остаток на складе</label>
									<input id="minimum" {...bindComponentMinimum} type="text" placeholder="0" maxLength={4} />
								</div>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="requestcount" className="label-middle">Количество для заявки по умолчанию</label>
									<input id="requestcount" {...bindComponentRequestCount} type="text" placeholder="0" maxLength={4} />
								</div>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="status">Статус</label>
									<div className="select select-middle">
										<select id="status" onChange={handleComponentStatus} value={componentStatus} required>
											<option value=""></option>
											{commonStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
										</select>
									</div>
								</div>
							</div>
						</div>
						<div className="product-edit-footer">
							<button type="button" onClick={modalComponentClose} className="btn-cancel">Отменить</button>
							<button type="submit" className="btn-save btn-accept">Сохранить</button>
						</div>
					</form>
				</div>
			</ModalComponent>
			<ModalComponentCategory>
				<div className="modal">
					<div className="header">
						<h4>Добавить категорию</h4>
						<img src={imgClose} alt="" onClick={modalComponentCategoryClose} className="btn-close" />
					</div>
					<input type="text" {...bindComponentCategoryName} placeholder="Название категории" autoFocus={true} />
					<div className="buttons">
						<button type="button" onClick={modalComponentCategoryClose} className="btn-cancel">Отменить</button>
						<button type="button" onClick={componentCategorySave} className="btn-accept">Сохранить</button>
					</div>
				</div>
			</ModalComponentCategory>
		</>
	);
};

export default ProductOptionsScreen;