import React, {useEffect,useState} from 'react';
import {Link} from 'react-router-dom';
import { useHistory } from "react-router-dom";

// plug-ins
import {useModal} from 'react-hooks-use-modal';
import InputMask from 'react-input-mask';
import Moment from 'moment';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import {useInput} from '../../../Components/Hooks/Input';
import NotFound from '../../../Components/NotFound';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import Empty from '../../../Components/Empty';
import Error from '../../../Components/Error';

// models
import { Addresses, Categories, Clients, DeliveryAreas, Orders, OrderStatuses, Points, Products, Sources } from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';
import Orfo from '../../../Globals/Orfo';

// constants
import { deliveryType, discountType, measureTypeName, paymentType, sourceType } from '../../../Globals/Constants';

// styles
import './styles.css';

// images
import imgPlusButton from './Images/plus-btn.svg';
import imgCheckOn from './Images/checkbox-on.svg';
import imgCheckOff from './Images/checkbox-off.svg';
import imgEdit from './Images/edit.svg';
import imgClose from './Images/close.svg';
import imgCutlery from './Images/cutlery.svg';
import imgPlus from './Images/plus-minus-plus.svg';
import imgMinus from './Images/plus-minus-minus.svg';


// start
const OrderAddScreen = (props) => {
	const history = useHistory();
	const [ModalAddressAdd, modalAddressAddOpen, modalAddressAddClose] = useModal('root');
	const [loading, setLoading] = useState(true);
	const [clientLoading, setClientLoading] = useState(false);
	const [order, setOrder] = useState(null);
	const [productsAll, setProductsAll] = useState([]);
	const [productsFull, setProductsFull] = useState([]);
	const [products, setProducts] = useState([]);
	const [categories, setCategories] = useState([]);
	const [points, setPoints] = useState([]);
	const [category, setCategory] = useState(null);
	const [orderStatus, setOrderStatus] = useState(null);
	const [id, setId] = useState(null);
	const {value:phone, setValue:setPhone, bind:bindPhone} = useInput('');
	const {value:name, setValue:setName, bind:bindName} = useInput('');
	const {value:birthdate, setValue:setBirthdate, bind:bindBirthdate} = useInput('');
	const {value:date, setValue:setDate, bind:bindDate} = useInput('');
	const {value:comment, setValue:setComment, bind:bindComment} = useInput('');
	const [isDeliveryFree, setIsDeliveryFree] = useState(false);
	const [isPaid, setIsPaid] = useState(false);
	const {value:discount, setValue:setDiscount, bind:bindDiscount} = useInput('');
	const [deliveryArea, setDeliveryArea] = useState(null);
	const [clients, setClients] = useState(null);
	const [client, setClient] = useState(null);
	const [pointId, setPointId] = useState(null);
	const [point, setPoint] = useState(null);
	const [addressId, setAddressId] = useState(null);
	const {value:addressName, setValue:setAddressName, bind:bindAddressName} = useInput('');
	const {value:addressRoom, setValue:setAddressRoom, bind:bindAddressRoom} = useInput('');
	const {value:addressFloor, setValue:setAddressFloor, bind:bindAddressFloor} = useInput('');
	const {value:addressEntrance, setValue:setAddressEntrance, bind:bindAddressEntrance} = useInput('');
	const {value:addressIntercom, setValue:setAddressIntercom, bind:bindAddressIntercom} = useInput('');
	const {value:addressComment, setValue:setAddressComment, bind:bindAddressComment} = useInput('');
	const [addresses, setAddresses] = useState([]);
	const [address, setAddress] = useState(null);
	const [cutlery, setCutlery] = useState(1);
	const [sources, setSources] = useState([]);
	const [source, setSource] = useState(null);
	const [tab, setTab] = useState(0);
	const [status, setStatus] = useState(null);
	const [search, setSearch] = useState('');
	const [isPickup, setIsPickup] = useState(false);
	const [isClientEdit, setIsClientEdit] = useState(false);
	const [isClientFinish, setIsClientFinish] = useState(false);
	const [isNoClient, setIsNoClient] = useState(false);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			const id = props.match.params.id;
			if (id) {
				if (props.match.path === '/order-edit/:id') await orderSet(id);
				else await clientGet(id, (client) => clientSelect(client));
			}
			await pointsGet();
			await deliveryAreaGet();
			await categoriesGet();
			await productsGet();
			await orderStatusGet();
			await sourcesGet();
		};
		await dataGet();
		setLoading(false);
	}, []);
	const pointsGet = async () => {
		const points = await Points.get.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (points === undefined) return;
		setPoints(points);
	};
	const deliveryAreaGet = async () => {
		const area = await DeliveryAreas.get.default().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		setDeliveryArea(area);
	};
	const categoriesGet = async () => {
		const categories = await Categories.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (categories === undefined) return;
		setCategories(categories);
	};
	const productsGet = async () => {
		const products = await Products.get.active().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (products === undefined) return;
		setProducts(products);
		setProductsAll(products);
		setProductsFull(products);
	};
	const orderStatusGet = async () => {
		const status = await OrderStatuses.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (status === undefined) return;
		setOrderStatus(status);
	};
	const sourcesGet = async () => {
		const sources = await Sources.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (sources === undefined) return;
		setSources(sources);
	};
	const clientGet = async (id, callback) => {
		const client = await Clients.get.id(id).catch((ex) => console.log(666, ex));
		setClient(client);
		if (callback) callback(client);
	};
	const deliveryAreaGetByAddress = async (lat, lng) => {
		const area = await DeliveryAreas.get.coords(lat, lng).catch((ex) => console.log(666, ex));
		setDeliveryArea(area);
	};
	const pointGetByAddress = async (lat, lng) => {
		const point = await Points.get.coords(lat, lng).catch((ex) => console.log(666, ex));
		pointSelect(point);
	};
	const orderSet = async (id) => {
		const order = await Orders.get.id(id).catch((ex) => console.log(666, ex));
		setId(id);
		setOrder(order);
		setPoint(points.find(v => v._id === order.point.id));
		setCutlery(order.options?.cutleries);
		setComment(order.comment);
		setDate(Utils.dateFullNormalize(order.options, 'date'));
		setStatus(order.status);
		setSource(order.options?.source?.id);
		setIsPickup(order.properties?.isPickup);
		setIsPaid(order.properties?.isPaid);
		setDiscount(order.discounts?.percent);
		setIsDeliveryFree(order.properties.deliveryFree);
		const products = productsAll.filter(f => order.products.find(v => v.id === f._id));
		setProducts(products);
		const isPickup = order.properties?.isPickup;
		if (order.client !== null) {
			const {latitude, longitude} = order.address;
			if (!isPickup) {
				await deliveryAreaGetByAddress(latitude, longitude);
				await pointGetByAddress(latitude, longitude);
			}
			setIsClientFinish(true);
			await clientGet(order.client.id, (client) => clientSelect(client));
		}
		setTab(1);
	};
	const tabSwitch = async (tab) => {
		if (tab === 0) {
			if (client === null) setSearch('');
			setClients(null);
		}
		if (tab === 1) {
			if (!isNoClient) {
				if (client === null) {
					setTab(tab);
					return;
				}
			}
			if (isPickup) {
				if (pointId === null) {
					errorShow('Необходимо выбрать филиал самовывоза');
					return;
				}
			}
			if (order === null) {
				const data = {
					products:[]
				};
				setOrder(data);
			}
			if (!isPickup) {
				await deliveryAreaGetByAddress(address.latitude, address.longitude);
				await pointGetByAddress(address.latitude, address.longitude);
			}
			setIsClientFinish(true);
		}
		if (tab === 2) {
			if (order && order.products.length === 0) return;
		}
		setTab(tab);
	}
	const categorySelect = (cat) => {
		if (category) {
			if (category._id === cat._id) {
				categorySelectAll();
				return;
			}
		}
		const products = productsAll.filter(f => f.categoryId === cat._id);
		setCategory(cat);
		setProducts(products);
	}
	const categorySelectAll = () => {
		setCategory(null);
		setProducts(productsAll);
	};
	const modalAddressAddShow = (address) => {
		setAddress(address);
		setAddressId(address?._id||null);
		setAddressName(address?.address||'');
		setAddressRoom(address?.room||'');
		setAddressFloor(address?.floor||'');
		setAddressEntrance(address?.entrance||'');
		setAddressIntercom(address?.intercom||'');
		setAddressComment(address?.comment||'');
		setAddresses([]);
		modalAddressAddOpen();
	}
	const addressAdd = async () => {
		if (Utils.empty(addressName)) {
			errorShow('Необходимо заполнить адрес');
			return;
		}
		if (Utils.empty(addressRoom)) {
			errorShow('Необходимо заполнить квартиру / офис');
			return;
		}
		const id = client._id;
		const data = {
			address:addressName,
			room:addressRoom,
			floor:addressFloor,
			entrance:addressEntrance,
			intercom:addressIntercom,
			comment:addressComment,
			latitude:address.latitude,
			longitude:address.longitude
		};
		const addresses = addressId ? client.addresses.map(v => v._id === addressId ? data : v) : [...client.addresses, data];
		const res = await Clients.address(id, {addresses}).catch((ex) => {
			console.log(666, ex);
			errorShow();
		});
		if (res) {
			successShow(res.message);
			clientGet(id);
			addressClose();
		}
	}
	const addressClose = () => {
		setAddress(null);
		setAddressName('');
		setAddressRoom('');
		setAddressFloor('');
		setAddressEntrance('');
		setAddressIntercom('');
		setAddressComment('');
		modalAddressAddClose();
	}
	const addressGet = async (e) => {
		const address = e.target.value;
		setAddressName(address);
		const addresses = await Addresses.get(address).catch((ex) => console.log(666, ex));
		setAddresses(addresses);
	}
	const addressSetDefault = (address) => {
		setAddressId(address._id);
		setAddress(address);
		setAddressId(address._id||null);
		setAddressName(address?.address||'');
		setAddressRoom(address?.room||'');
		setAddressFloor(address?.floor||'');
		setAddressEntrance(address?.entrance||'');
		setAddressIntercom(address?.intercom||'');
		setAddressComment(address?.comment||'');
	}
	const selectAddress = (address) => {
		setAddress(address);
		setAddressName(address.address);
		setAddresses([]);
	}
	const countCutleryMinus = () => cutlery === 0 ? null : setCutlery(cutlery - 1);
	const countCutleryPlus = () => cutlery === 10 ? null : setCutlery(cutlery + 1);
	const countProductsMinus = (item) => {
		const product = order.products.find(v => v.id === item.id);
		setOrder({...order, products: product.quantity === 1 ?
			order.products.filter(v => v.id !== item.id) : 
			order.products.map(v => v.id === item.id ? {...v, quantity:v.quantity - 1} : v)});
	}
	const countProductsPlus = (item) => {
		const data = order.products.map(v => v.id === item.id ? {...v, quantity:v.quantity === 99 ? v.quantity : v.quantity + 1} : v);
		setOrder({...order, products:data});
	}
	const searchClient = async (e) => {
		const search = e.target.value;
		setSearch(search);
		if (Utils.empty(search)) setClients(null);
		else {
			setClientLoading(true);
			const clients = await Clients.get.search(search).catch((ex) => console.log(666, ex));
			setClients(clients||[]);
			setClientLoading(false);
		}
	}
	const nextWithNoClient = () => {
		setIsNoClient(true);
		pickupSet();
	}
	const clientAdd = () => {
		setIsClientEdit(true);
		setIsClientFinish(false);
	}
	const clientClear = () => {
		setIsClientEdit(false);
		setClients(null);
		setPointId(null);
		setPoint(null);
		setIsPickup(false);
		setIsClientEdit(false);
		setIsNoClient(false);
		if (isClientEdit && client) return;
		setClient(null);
		clientSelect(null);
		setIsClientFinish(false);
	}
	const clientSelect = (client) => {
		setSearch('');
		setClient(client);
		setClients(null);
		setPhone(client?.phone||'');
		setName(client?.name||'');
		setBirthdate(Utils.dateNormalize(client, 'birthdate'));
		const address = client ? (client.addresses && client.addresses.length ? client.addresses[client.addresses.length-1] : {}) : {};
		setAddress(address);
		setAddressId(address._id||null);
		setAddressName(address?.address||'');
		setAddressRoom(address?.room||'');
		setAddressFloor(address?.floor||'');
		setAddressEntrance(address?.entrance||'');
		setAddressIntercom(address?.intercom||'');
		setAddressComment(address?.comment||'');
	}
	const orderCancel = () => {
		setOrder(null);
		clientClear();
		tabSwitch(0);
	}
	const save = async (e) => {
		e?.preventDefault();
		if (tab === 0) return clientSave();
	}
	const clientSave = async () => {
		if (Utils.empty(phone)) {
			errorShow('Необходимо заполнить телефон');
			return;
		}
		const data = {
			phone:Utils.phoneNormalize(phone),
			name:name,
			birthdate:birthdate
		};
		const id = client?._id||null;
		if (!isPickup) {
			if (id === null || (id && (client.addresses === null || client.addresses.length === 0))) {
				if (Utils.empty(addressName)) {
					errorShow('Необходимо заполнить адрес');
					return;
				}
				if (Utils.empty(addressRoom)) {
					errorShow('Необходимо заполнить квартиру / офис');
					return;
				}
				if (Utils.empty(address) || Utils.empty(address.latitude) || Utils.empty(address.longitude)) {
					errorShow('Необходимо выбрать адрес из списка');
					return;
				}
				data.addresses = [{
					address:addressName,
					room:addressRoom,
					floor:addressFloor,
					entrance:addressEntrance,
					intercom:addressIntercom,
					comment:addressComment,
					latitude:address.latitude,
					longitude:address.longitude
				}];
			}
		}
		const res = id ?
				await Clients.update(id, data).catch((ex) => {
					console.log(666, ex);
					errorShow(ex.code === 409 ? ex.message : null);
				})
			:
				await Clients.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow(ex.code === 409 ? ex.message : null);
				});
		if (res) {
			if (!id) successShow(res.message);
			clientGet(id||res.id, (client) => clientSelect(client));
			tabSwitch(1);
		}
		return false;
	}
	const orderSave = async () => {
		const data = {
			products:productsPrepare(order.products),
			amount:amoutGet(),
			amountFull:fullGet(),
			delivery:deliveryGet(),
			discountAmount:discountGet(),
			discount:discount,
			deliveryFree:deliveryGet() === 0,
			paymentType:paymentType.CASH,
			paymentSource:sourceType.DISPATCHER,
			source:source,
			isPaid:isPaid,
			isPickup:isPickup,
			cutleries:cutlery,
			comment:comment,
			isnoclient:isNoClient
		};
		if (!isNoClient) {
			if (client === null) {
				errorShow('Необходимо выбрать клиента');
				return;
			}
			data.clientId = client._id;
		}
		if (isPickup) {
			data.addressName = point.contacts.address;
			data.addressLatitude = point.properties.latitude;
			data.addressLongitude = point.properties.longitude;
			data.addressComment = `Самовывоз из филиала ${point.name} по адресу: ${point.contacts.address}`;
		} else {
			data.addressName = addressName;
			data.addressRoom = addressRoom;
			data.addressFloor = addressFloor;
			data.addressEntrance = addressEntrance;
			data.addressIntercom = addressIntercom;
			data.addressComment = addressComment;
			data.addressLatitude = address.latitude;
			data.addressLongitude = address.longitude;
		}
		if (status) data.status = status;
		if (date) data.date = date;
		if (data.discountAmount) data.discountType = discount ? discountType.MANUAL : discountType.PERSONAL;
		else {
			delete data.discountAmount;
			delete data.discount;
		}
		const res = id ?
				await Orders.update(id, data).catch((ex) => {
					console.log(666, ex);
					errorShow(ex.message);
				})
			:
				await Orders.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow(ex.message);
				});
		if (res) history.push('/orders');
		return false;
	};
	const productsPrepare = (products) => {
		const p = [];
		products.forEach((v) => {
			const data = {
				id:v.id,
				name:v.name,
				price:v.price,
				quantity:v.quantity,
				category:v.category
			};
			if (v.priceDiscount) data.priceDiscount = v.priceDiscount;
			if (v.discount) data.discount = v.discount;
			p.push(data);
		});
		return p;
	};
	const discountSet = (e) => {
		const value = e.target.value;
		if (value === '') {
			setDiscount('');
			return;
		}
		if (value < 0 || value > 100) return;
		setDiscount(parseInt(value));
	};
	const productAdd = (item) => {
		tabSwitch(1);
		const product = order.products.find(v => v.id === item._id);
		if (product) product.quantity++;
		else {
			const data = {
				points:item.points,
				deliveryType:item.options.deliveryType,
				id:item._id,
				name:item.name,
				price:item.prices.price,
				quantity:1,
				category:{
					id:item.categoryId,
					name:categories.find(v => v._id === item.categoryId).name
				}
			};
			if (item.discounts?.discount) {
				data.priceDiscount = item.prices.priceFull;
				data.discount = item.discounts.discount;
			}
			order.products.push(data);
		}
		setOrder({...order});
	}
	const totalGet = () => {
		const price = order.products.reduce((a, b) => a + (b.priceDiscount || b.price) * b.quantity, 0);
		if (client && client.discount) {
			const discount = price * client.discount / 100;
			return price - discount;
		}
		return price;
	}
	const amoutGet = () => order.products.reduce((a, b) => a + (b.priceDiscount || b.price) * b.quantity, 0);
	const discountGet = () => {
		const amount = amoutGet();
		if (discount) return amount * discount / 100;
		if (client && client.discount) return amount * client.discount / 100;
		return 0;
	}
	const deliveryGet = () => {
		const total = totalGet();
		if (isPickup) return 0;
		if (isDeliveryFree) return 0;
		if (deliveryArea.properties.alwaysPaid) return deliveryArea.prices.price;
		return total < deliveryArea.prices?.priceFree ? deliveryArea.prices?.price : 0;
	}
	const fullGet = () => totalGet() + deliveryGet();
	const searchProduct = (e) => {
		const search = e.target.value;
		setSearch(search);
		if (Utils.empty(search)) setProducts(productsAll);
		else setProducts(productsAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase())));
	}
	const pointSelect = (point) => {
		if (order && order.products.length) {
			const items = [];
			order.products.forEach((v) => {
				if (!v.points.find(f => f.id === point._id)) items.push(v);
			});
			if (items.length) {
				errorShow(`На выбранной филиале отсутствуют некоторые позиции<br><i>${items.map(v => v.name).join(', ')}</i>`);
				return;
			}
		}
		setPointId(point._id);
		setPoint(point);
		const p = productsFull.filter(f => f.points.find(f => f.id === point._id));
		setProductsAll(p);
		setProducts(p);
	}
	const pickupSet = () => {
		const flag = !isPickup;
		if (order && order.products.length) {
			const items = [];
			const type = flag ? deliveryType.PICKUP : deliveryType.DELIVERY;
			order.products.forEach((v) => {
				if (!v.deliveryType.includes(type)) items.push(v);
			});
			if (items.length) {
				errorShow(`Некоторые позиции недоступны для ${flag ? 'самовывоза' : 'доставки'}<br><i>${items.map(v => v.name).join(', ')}</i>`);
				return;
			}
		}
		setIsPickup(flag);
	};
	const handleStatus = (e) => setStatus(e.target.value);
	const handleSource = (e) => setSource(e.target.value);
	const addressAddRender = () => {
		return <>
			<div className="edit-row">
				<input type="text" placeholder="Адрес, улица и дом" {...bindAddressName} onChange={addressGet} required />
			</div>
			{addresses.length ?
				<div className="edit-row">
					<div className="addresses-list">
						{addresses.map((v,i) => <div key={i} onClick={() => selectAddress(v)}>{v.address}</div>)}
					</div>
				</div> : null}
			<div className="edit-row orderadd-oneline-wide">
				<input type="text" placeholder="Квартира, офис" {...bindAddressRoom} maxLength={5} className="small" required />
				<input type="text" placeholder="Подъезд" {...bindAddressEntrance} maxLength={3} className="small" />
				<input type="text" placeholder="Этаж" {...bindAddressFloor} maxLength={2} className="small" />
				<input type="text" placeholder="Код домофона" {...bindAddressIntercom} maxLength={6} className="small" />
			</div>
			<div className="edit-row">
				<textarea placeholder="Комментарий к адресу" {...bindAddressComment}></textarea>
			</div>
		</>;
	}
	const menuCheck = () => productsAll && productsAll.length && categories && categories.length;
	return (
		<>
			<Template>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<>
							<div className="orderadd-container">
								<div className="main">
									<div className="left-side">
										<div className="left-side-tabs orderadd-oneline-wide">
											<div className={`tab${tab===0?' tab-active':''}`} onClick={() => tabSwitch(0)}>Клиент</div>
											<div className={`tab${tab===1?' tab-active':''}`} onClick={() => tabSwitch(1)}>Заказ</div>
											<div className={`tab${tab===2?' tab-active':''}`} onClick={() => tabSwitch(2)}>Проверка</div>
										</div>
										<form className={`left-side-main left-side-main-tab${tab}`} onSubmit={save}>
											{tab === 0 ?
													(client || ((client === null && isClientEdit) || (client && isClientEdit) || isNoClient) ?
															<>
																<div className="client-info">
																	{isNoClient ? null :
																		<>
																			<div className="client-info-bottom orderadd-oneline-wide">
																				<h4>{isClientEdit ? (client ? 'Редакирование клиента' : 'Добавление клиента'): 'Информация о клиенте'}</h4>
																				{client && !isClientEdit ?
																					<img src={imgEdit} alt="" onClick={() => clientAdd()} className="edit" />
																				: null}
																			</div>
																			{isClientEdit ?
																					<>
																						<div className="edit-row">
																							<InputMask type="tel" mask="+7 (999) 999-99-99" placeholder="Телефон" maskPlaceholder={null} {...bindPhone} required />
																						</div>
																						<div className="edit-row orderadd-oneline-wide">
																							<input type="text" placeholder="Имя" {...bindName} />
																						</div>
																						<div className="edit-row orderadd-oneline-wide">
																							<input type="date" placeholder="Дата рождения" {...bindBirthdate} />
																						</div>
																					</>
																				:
																					<>
																						<div className="view-row orderadd-oneline-wide">
																							<label>Телефон</label>
																							<div>{Utils.phoneFormatter(client.phone)}</div>
																						</div>
																						<div className="view-row orderadd-oneline-wide">
																							<label>Имя</label>
																							<div>{client.name}</div>
																						</div>
																						<div className="view-row orderadd-oneline-wide">
																							<label>Дата рождения</label>
																							<div>{client.birthdate ? <>{Moment(client.birthdate).format('DD.MM.YYYY')} ({Orfo.counters.years(Moment().diff(client.birthdate, 'years', false))})</> : ''}</div>
																						</div>
																						<div className="view-row orderadd-oneline-wide">
																							<label>Скидка</label>
																							<div>{client.discount||0} %</div>
																						</div>
																					</>
																			}
																		</>
																	}
																	{client ?
																			<>
																				<div className="margin-top20 orderadd-oneline-wide">
																					<h4>{isPickup ? 'Заберет с филиала' : 'Адреса доставки'}</h4>
																					<div className={`pickup${isPickup?'-on':''} orderadd-oneline`} onClick={() => pickupSet()}>
																						<img src={isPickup ? imgCheckOn : imgCheckOff} alt="" />
																						Самовывоз
																					</div>
																				</div>
																				{isPickup ? null :
																					<>
																						<div className="view-row">
																							{client.addresses.length ?
																									<>
																										{client.addresses.map((v,i) => <div key={i} className={`order-view-address${addressId===v._id?' order-view-address-select':''} orderadd-oneline-wide`} onClick={() => addressSetDefault(v)}>
																											{v.address}
																											<img src={imgEdit} alt="" onClick={() => modalAddressAddShow(v)} />
																										</div>)}
																										<div className="view-row">
																											<button type="button" className="btn-button" onClick={modalAddressAddShow}>Добавить адрес</button>
																										</div>
																									</>
																								:
																									addressAddRender()
																							}
																						</div>
																					</>
																				}
																			</>
																		:
																			<div className="margin-top20 orderadd-oneline-wide">
																				<h4>{isPickup ? 'Заберет с филиала' : 'Адрес доставки'}</h4>
																				{isNoClient ? null :
																					<div className={`pickup${isPickup?'-on':''} orderadd-oneline`} onClick={() => pickupSet()}>
																						<img src={isPickup ? imgCheckOn : imgCheckOff} alt="" />
																						Самовывоз
																					</div>
																				}
																			</div>
																	}
																	{isPickup ?
																			<>
																				{points.map((v,i) => <div key={i} className="pickup-area" onClick={() => pointSelect(v)}>
																					<div className="orderadd-oneline">
																						<img src={pointId === v._id ? imgCheckOn : imgCheckOff} alt="" />
																						{v.name}
																					</div>
																					<span>{v.contacts.address}</span>
																				</div>)}
																			</>
																		:
																			(client === null && isClientEdit ?
																					addressAddRender()
																				: null
																			)
																	}
																</div>
																{isClientFinish ? null :
																	(
																		isNoClient ?
																				<div className="footer-controls orderadd-oneline-wide">
																					<button type="button" onClick={() => clientClear()} className="btn-second">Отмена</button>
																					<button type="button" onClick={() => tabSwitch(1)}>Продолжить</button>
																				</div>
																			:
																				<div className="footer-controls orderadd-oneline-wide">
																					<button type="button" onClick={() => clientClear()} className="btn-second">{isClientEdit ? (client ? 'Отмена' : 'К поиску') : 'К поиску'}</button>
																					<button type="submit" onClick={() => isClientEdit ? null : (client?.addresses && client?.addresses.length ? tabSwitch(1) : null)}>{isClientEdit ? 'Сохранить и продолжить' : 'Продолжить'}</button>
																				</div>
																	)
																}
															</>
														:
															<>
																	<div className="clients-block">
																		<div className="search-block">
																			<input type="search" placeholder="Поиск клиента по ФИО, телефону" onChange={searchClient} className="search" value={search} autoFocus={true} />
																			<button type="button" onClick={() => clientAdd()}>
																				<img src={imgPlusButton} alt="" />
																			</button>
																		</div>
																	</div>
																	{clientLoading ? <Loader /> :
																		<>
																			{clients === null ?
																					<div className="empty">
																						<Empty image={'clients'} text={<>Для создания заказа<br />необходимо добавит клиента</>}>
																							<div className="client-add-block">
																								<button type="button" onClick={nextWithNoClient}>Продолжить без клиента</button>
																							</div>
																							<span>только самовывоз</span>
																						</Empty>
																					</div>
																				: null}
																			{clients && clients.length ?
																					<div className="clients-lists">
																						{clients.map((v,i) => <div key={i} className="client orderadd-oneline-wide" onClick={() => clientSelect(v)}>
																							<div>{v.name}</div>
																							<div>{Utils.phoneFormatter(v.phone)}</div>
																						</div>)}
																					</div>
																				:
																					<NotFound />
																			}
																		</>
																	}
																</>
													)
												: null}
											{tab === 1 ?
													(!isNoClient && client === null ?
															<div className="empty">
																<Empty image={'clients'} text={<>Для создания заказа<br />сначала выберите клиента</>}>
																	<div className="client-add-block">
																		<button type="button" onClick={() => tabSwitch(0)}>Добавить клиента</button>
																	</div>
																</Empty>
															</div>
														:
															(order === null ?
																	<div className="empty">
																		<Empty image={'order'} text={<>Выберите позиции для<br />заказа из списка справа</>} />
																	</div>
																:
																	<>
																		{id ?
																			<div className="order-info-row orderadd-oneline-wide">
																				<h3>Заказ № {order.number}</h3>
																			</div> : null}
																		<div className="order-info-row orderadd-oneline-wide">
																			<div className="orderadd-oneline">
																				<img src={imgCutlery} alt="" />
																				<label>Приборы</label>
																			</div>
																			<div className="orderadd-oneline-wide">
																				<div className="order-info-counter orderadd-oneline-wide">
																					<img src={imgMinus} alt="" className={cutlery===0?'inactive':''} onClick={() => countCutleryMinus()} />
																					<div className="quantity">{cutlery}</div>
																					<img src={imgPlus} alt="" className={cutlery===10?'inactive':''} onClick={() => countCutleryPlus()} />
																				</div>
																				<div className="order-info-price"></div>
																			</div>
																		</div>
																		{order.products.length ?
																				order.products.map((v,i) => <div key={i} className="order-info-row orderadd-oneline-wide">
																					<div>{v.name}</div>
																					<div className="orderadd-oneline-wide">
																						<div className="order-info-counter orderadd-oneline-wide">
																							<img src={imgMinus} alt="" onClick={() => countProductsMinus(v)}/>
																							<div className="quantity">{v.quantity}</div>
																							<img src={imgPlus} alt="" onClick={() => countProductsPlus(v)} />
																						</div>
																						<div className="order-info-price">{Utils.moneyFormat(v.priceDiscount||v.price)} ₽</div>
																					</div>
																				</div>)
																			:
																				<div className="empty">
																					<Empty image={'order'} text={<>Выберите позиции для<br />заказа из списка справа</>} />
																				</div>
																		}
																	</>
															)
													)
												: null}
											{tab === 2 ?
													(!isNoClient && client === null ?
															<div className="empty">
																<Empty image={'clients'} text={<>Выберите клиента, создайте заказ и<br />добавляйте свойства</>} />
															</div>
														:
															(order === null ?
																	<div className="empty">
																		<Empty image={'order'} text={<>Выберите позиции для<br />заказа из списка справа</>} />
																	</div>
																:
																	<>
																		<div className="edit-row orderadd-oneline-wide">
																			<label htmlFor="date">Предзаказ на дату</label>
																			<input id="date" type="datetime-local" {...bindDate} className="inline" />
																		</div>
																		<div className="edit-row">
																			<textarea {...bindComment} placeholder="Комментарий к заказу" className="comment"></textarea>
																		</div>
																		<h4 className="margin-top10">{isPickup ? 'Клиент заберет с филиала' : 'Будет назначен на филиал'}</h4>
																		<div className="pickup-areas-final">
																			{isPickup ?
																					<div className="pickup-area-final pickup-area-final-select">
																						<div className="orderadd-oneline">
																							<img src={imgCheckOn} alt="" />
																							{point.name}
																						</div>
																						<span>{point.contacts.address}</span>
																					</div>
																				:
																					points.map((v,i) => <div key={i} className={`pickup-area-final${pointId===v._id?' pickup-area-final-select':''}`} onClick={() => pointSelect(v)}>
																						<div className="orderadd-oneline">
																							<img src={pointId === v._id ? imgCheckOn : imgCheckOff} alt="" />
																							{v.name}
																						</div>
																						<span>{v.contacts.address}</span>
																					</div>)
																			}
																		</div>
																		<h4 className="margin-top20">Дополнительно</h4>
																		<div className="edit-row orderadd-oneline-wide">
																			<label htmlFor="discount" className="inline">Сделать скидку</label>
																			<input id="discount" {...bindDiscount} type="text" placeholder="0" onChange={discountSet} maxLength={2} className="inline" />
																		</div>
																		<div className="edit-row orderadd-oneline-wide">
																			<label htmlFor="ispaid">Заказ оплачен</label>
																			<img id="ispaid" src={isPaid ? imgCheckOn : imgCheckOff} alt="" onClick={() => setIsPaid(!isPaid)} />
																		</div>
																		<div className="edit-row orderadd-oneline-wide">
																			<label>Бесплатная доставка</label>
																			<img src={isDeliveryFree ? imgCheckOn : imgCheckOff} alt="" onClick={() => setIsDeliveryFree(!isDeliveryFree)} />
																		</div>
																		<div className="edit-row orderadd-oneline-wide">
																			<label htmlFor="source">Откуда пришел заказ</label>
																			<div className="select">
																				<select id="source" onChange={handleSource} value={source}>
																					<option value=""></option>
																					{sources.map((v,i) => <option key={i} value={v._id}>{v.name}</option>)}
																				</select>
																			</div>
																		</div>
																		<div className="edit-row orderadd-oneline-wide">
																			<label htmlFor="status">Установить статус</label>
																			<div className="select">
																				<select id="status" onChange={handleStatus} value={status}>
																					<option value=""></option>
																					{orderStatus.map((v,i) => <option key={i} value={v.code}>{v.name}</option>)}
																				</select>
																			</div>
																		</div>
																	</>
															)
													)
												: null}
										</form>
										{order && tab === 1 ?
												<>
													<div className="order-info-summary-lite">
														<div className="summary-row orderadd-oneline-wide">
															<label>Сумма заказа</label>
															<div>{Utils.moneyFormat(amoutGet())} ₽</div>
														</div>
														<div className="summary-row orderadd-oneline-wide">
															<label>Скидка</label>
															<div className="discount">– {Utils.moneyFormat(discountGet())} ₽</div>
														</div>
														<div className="summary-row orderadd-oneline-wide ">
															<label>Итого по заказу</label>
															<div><b>{Utils.moneyFormat(totalGet())} ₽</b></div>
														</div>
													</div>
													<div className="footer-controls orderadd-oneline-wide">
														<button type="button" onClick={() => orderCancel()} className="btn-second">Отменить</button>
														<button type="submit" onClick={() => order?.products?.length ? tabSwitch(2) : null} className={order?.products?.length?null:'btn-disable'}>Продолжить</button>
													</div>
												</>
											: null}
										{order && tab === 2 ?
												<div className="order-info-summary">
													<div className="order-info-summary-row orderadd-oneline-wide">
														<label>Сумма заказа</label>
														<div>{Utils.moneyFormat(amoutGet())} ₽</div>
													</div>
													<div className="order-info-summary-row orderadd-oneline-wide">
														<label>Скидка</label>
														<div className="discount">– {Utils.moneyFormat(discountGet())} ₽</div>
													</div>
													{isPickup ?
															<div className="order-info-summary-row orderadd-oneline-wide">
																<label>Доставка</label>
																<div>Самовывоз</div>
															</div>
														:
															<div className="order-info-summary-row orderadd-oneline-wide">
																<label>Доставка</label>
																<div>{Utils.moneyFormat(deliveryGet())} ₽</div>
															</div>
													}
													<div className="order-info-summary-row order-info-summary-total orderadd-oneline-wide ">
														<label>Сумма к оплате</label>
														<div>{Utils.moneyFormat(fullGet())} ₽</div>
													</div>
													<div className="buttons orderadd-oneline-wide">
														<button type="button" onClick={orderCancel} className="btn-cancel">Отменить</button>
														{tab === 1 ?
																<button type="button" className="btn-next" onClick={() => tabSwitch(2)}>Продолжить</button>
															:
																<button type="button" className="btn-save" onClick={() => orderSave()}>{id ? 'Изменить заказ' : 'Создать заказ'}</button>
														}
													</div>
												</div>
											: null}
									</div>
									<div className="menu-side">
										{menuCheck() ?
												(isClientFinish ?
														<div className="inner">
															<div className="categories-container">
																<div className="categories-list">
																	<div className="list">
																		<div className={`category${category===null?' category-select':''}`} onClick={() => categorySelectAll()}>
																			Все категории <i>{productsAll.length}</i>
																		</div>
																		{categories.map((v,i) => <div key={i} className={`category${v._id===category?._id?' category-select':''}`} onClick={() => categorySelect(v)}>
																			{v.name}
																			<i>{productsAll.filter(f => f.categoryId === v._id).length}</i>
																		</div>)}
																	</div>
																</div>
																<div className="products-list">
																	<div className="products-list-controls">
																		<input type="search" placeholder="Поиск" className="search" onChange={searchProduct} value={search} />
																	</div>
																	<div className="container-inner">
																		{products.length ?
																				<table className="items">
																					<thead>
																						<tr>
																							<th>Имя</th>
																							<th className="prices">Цена</th>
																							<th className="weight">Объем</th>
																						</tr>
																					</thead>
																					<tbody>
																						{products.map((v,i) => <tr key={i} onClick={() => productAdd(v)}>
																							<td>{v.name}</td>
																							<td className="prices">{v.prices.priceFull} ₽</td>
																							<td className="weight">{v.properties.weight} {measureTypeName[v.properties.measureType]}</td>
																						</tr>)}
																					</tbody>
																				</table>
																			:
																				<NotFound />
																		}
																	</div>
																</div>
															</div>
														</div>
													:
														<div className="empty">
															<Empty image={'orders-white'} text={<>Выберите клиента и<br />создайте заказ</>} />
														</div>
												)
											:
												<div className="empty">
													<></>
													<Empty image={'plus'} text={<>В списке нет категорий и позиций,<br />добавьте их в меню</>}>
														<div className="menu-add-block">
															<Link to={'/books/products'}>Перейти в товары</Link>
														</div>
													</Empty>
												</div>
										}
									</div>
								</div>
							</div>
						</>
					)
				}
			</Template>
			<Alert />
			<ModalAddressAdd>
				<div className="modal modal-address-add">
					<div className="header">
						<h4>{address ? 'Изменить адрес' : 'Добавить новый адрес'}</h4>
						<img src={imgClose} alt="" onClick={addressClose} className="btn-close" />
					</div>
					<div className="container-inner">
						<input type="text" {...bindAddressName} placeholder="Введите адрес (улица и дом)" onChange={addressGet} />
						{addresses.length ?
							<div className="addresses-list">
								{addresses.map((v,i) => <div key={i} onClick={() => selectAddress(v)}>{v.address}</div>)}
							</div> : null}
						<div className="address-form">
							<div>
								<input type="text" {...bindAddressRoom} placeholder="Квартира, офиc" maxLength={5} required />
								<span>кв/офис</span>
							</div>
							<div>
								<input type="text" {...bindAddressEntrance} placeholder="Подъезд" maxLength={3} />
								<span>подъезд</span>
							</div>
							<div>
								<input type="text" {...bindAddressFloor} placeholder="Этаж" maxLength={2} />
								<span>этаж</span>
							</div>
							<div>
								<input type="text" {...bindAddressIntercom} placeholder="Код домофона" maxLength={6} />
								<span>домофон</span>
							</div>
						</div>
						<div className="address-form">
							<textarea {...bindAddressComment} placeholder="Комментарий к адресу"></textarea>
						</div>
					</div>
					<div className="buttons">
						<button type="button" onClick={addressClose} className="btn-cancel">Отменить</button>
						<button type="button" onClick={addressAdd} className="btn-accept">Добавить</button>
					</div>
				</div>
			</ModalAddressAdd>
		</>
	);
};

export default OrderAddScreen;