import React, {useEffect,useState} from 'react';

// plug-ins
import {useModal} from 'react-hooks-use-modal';
import moment from 'moment';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import Layout, {panelClose} from '../../../Components/Layout';

// models
import {Tickets} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';

// constants
import { commonStatus } from '../../../Globals/Constants';

// styles
import './styles.css';

// images
import imgCheckOff from './Images/checkbox-off.svg';
import imgCheckOn from './Images/checkbox-on.svg';
import imgClose from './Images/close.svg';


// start
const SupportScreen = () => {
	const [ModalChangeStatus, modalChangeStatusOpen, modalChangeStatusClose] = useModal('root');
	const [loading, setLoading] = useState(true);
	const [tickets, setTickets] = useState(null);
	const [ticketsAll, setTicketsAll] = useState([]);
	const [messages, setMessages] = useState([]);
	const [search, setSearch] = useState('');
	const [id, setId] = useState(null);
	const {value:title, setValue:setTitle, bind:bindTitle} = useInput('');
	const {value:comment, setValue:setComment, bind:bindComment} = useInput('');
	const [isClosed, setIsClosed] = useState(false);
	const [status, setStatus] = useState(null);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await ticketsGet();
		};
		await dataGet();
		setLoading(false);
	}, []);
	const ticketsGet = async () => {
		const tickets = await Tickets.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (tickets === undefined) return;
		setTickets(tickets);
		setTicketsAll(tickets);
	};
	const ticketAdd = () => show();
	const ticketEdit = (ticket) => {
		ticket = ticket || tickets.find(f => f._id === id);
		show(ticket);
	};
	const ticketShow = (ticket) => show(ticket);
	const show = (ticket) => {
		setId(ticket?._id||null);
		setTitle(ticket?.title||'');
		setComment(ticket?.comment||'');
		setMessages(ticket?.messages||[]);
		setStatus(ticket?.status);
		setIsClosed(false);
	};
	const cancel = () => {
		ticketShow(null);
		modalChangeStatusClose();
		panelClose();
	};
	const save = async () => {
		const data = {
			comment:comment
		};
		if (!id) data.title = title;
		if (isClosed) data.status = commonStatus.IN_ACTIVE;
		if (!id) {
			if (Utils.empty(data.title)) {
				errorShow('Необходимо заполнить тему');
				return;
			}
		}
		if (!isClosed) {
			if (Utils.empty(data.comment)) {
				errorShow('Вы ничего не написали в сообщении');
				return;
			}
		}
		const res = id ?
				await Tickets.update(id, data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				})
			:
				await Tickets.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
		if (res) {
			successShow(res.message);
			ticketsGet();
			cancel();
			return true;
		}
	}
	const searchTicket = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setTickets(ticketsAll);
		else setTickets(ticketsAll.filter(f => f.title.name.toLowerCase().includes(search.toLowerCase()) || f.comment.toString().includes(search.toLowerCase())));
		setSearch(search);
	}
	const statusGet = (v) => <div className={`status${v.status===commonStatus.ACTIVE?v.status:0}`}>
		<div className="status-point"></div>
	</div>;
	const messagesGet = () => <div className="ticket-list">
		{messages.map((v,i) => <div key={i} className="ticket">
			<div className="ticket-body">
				{v.comment}
			</div>
			<div className="ticket-info">
				<div className="ticket-user">{v.isAdmin?<span>Техническая поддержка • </span>:''}{v.client?v.client.name:v.user.name}</div>
				<div className="ticket-date">{moment(v.date).format('DD.MM.YYYY HH:mm')}</div>
			</div>
		</div>)}
	</div>;
	const savePrepare = () => {
		if (isClosed) modalChangeStatusOpen();
		else save();
	};
	return (
		<>
			<Template>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<Layout
							title="Поддержка"
							type="support"
							data={tickets}
							id={id}
							search={search}
							rows={[
								{title:'Тема',field:'title'},
								{title:'Дата',field:'date',class:'datetime',func:(v) => moment(v.createdAt).format('DD.MM.YYYY HH:mm')},
								{title:'Статус',field:'status',class:'status status-small',func:statusGet}
							]}
							rowsShort={['title','status']}
							empty={<>Добавьте обращение<br/>в поддержку</>}
							contentShowTitle={title}
							contentShow={messagesGet()}
							contentEdit={<>
								{id ?
										<div className="product-view-row product-view-row-simple">
											<h3>{title}</h3>
										</div>
									:
										<div className="product-edit-row">
											<input type="text" {...bindTitle} placeholder="Тема" className="input-title" autoFocus={true} required />
										</div>
								}
								{!id || status === commonStatus.ACTIVE ?
									<div className="product-edit-row">
										<textarea placeholder="Комментарий" {...bindComment} required={isClosed?false:true}></textarea>
									</div> : null}
								{!id || status === commonStatus.ACTIVE ?
									<div className="product-edit-row product-edit-row-oneline">
										<label>Закрыть тикет</label>
										<img src={isClosed ? imgCheckOn : imgCheckOff} alt="" onClick={() => setIsClosed(!isClosed)} />
									</div> : null}
								{id ?
									<>
										<h4>Сообщения</h4>
										{messagesGet()}
									</> : null}
							</>}
							rowControlHide={true}
							closeIfNotSave={true}
							onEdit={ticketEdit}
							onAdd={ticketAdd}
							onSave={status===commonStatus.IN_ACTIVE?null:savePrepare}
							onSearch={searchTicket}
							onShow={ticketShow}
							onClose={cancel}
						/>
					)
				}
			</Template>
			<Alert />
			<ModalChangeStatus>
				<div className="modal">
					<div className="header">
						<h4>Закрыть тикет</h4>
						<img src={imgClose} alt="" onClick={modalChangeStatusClose} className="btn-close" />
					</div>
					<p>Вы уверены что хотите закрыть тикет?</p>
					<div className="buttons">
						<button type="button" onClick={modalChangeStatusClose} className="btn-cancel">Отменить</button>
						<button type="button" onClick={save} className="btn-accept btn-delete">Закрыть</button>
					</div>
				</div>
			</ModalChangeStatus>
		</>
	);
};

export default SupportScreen;