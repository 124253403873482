const isDeveloperMode = process.env.NODE_ENV !== 'production';
const url = isDeveloperMode ? 'http://localhost:8042' : 'https://points.pastilapp.ru';
const assetsUrl = 'https://s3.pastil-app.ru/assets/';

const helpUrl = 'https://docs.pastilapp.ru/';

const API = {
	url			: `${url}/api/v1/`,
	ws			: url,
	assets		: assetsUrl,
	help		: helpUrl,
	key			: 'J<DxyN+YT^$d_//=eq{^6?/@(FSd+5{:D,/BUcqAH]{Okl281.G>?rYf4?ar-KN',
	version		: '1.0.14'
};

const MAP = {
	keys : {
		Google	: 'AIzaSyD-0oQ2L6y2J9Q3zQ0c1YI7G2zV1gB4lVY',
		Yandex	: 'ad2b9fe2-ef89-45ee-8fac-9c91f4a5b1df'
	},
	defCoords	: [55.75143033396653, 37.61747618792485],
	defCity		: 'Москва'
};

const httpMethods = {
	GET		: 'GET',
	POST	: 'POST',
	PUT		: 'PUT',
	PATCH	: 'PATCH',
	DELETE	: 'DELETE'
};

const commonStatus = {
	IN_ACTIVE	: 0,
	ACTIVE		: 1
};
const commonStatusName = ['Не активен','Активен'];
const commonStatusAreaName = ['Не работает','Работает'];
const commonStatusDayShiftName = ['Закрыта','Открыта'];

const userStatus = {
	ACTIVE		: 1,
	IN_ACTIVE	: 2,
	BAN			: 3,
	DELETED		: 4
};
const userStatusName = ['','Активен','Не активен','Заблокирован','Удален'];
const userStatusNameWork = ['Не работает','Работает','Не работает','Не работает','Не работает'];

const clientStatus = {
	ACTIVE		: 1,
	IN_ACTIVE	: 2,
	BAN			: 3,
	DELETED		: 4
};
const clientStatusName = ['','Активен','Не активен','Заблокирован','Удален'];

const orderStatus = {
	UNKNOWN			: 0,
	NEW				: 1,
	PAID			: 2,
	ACCEPTED		: 3,
	COOK_READY		: 4,
	COOK_START		: 5,
	COOK_END		: 6,
	LOGIST_READY	: 7,
	LOGIST_START	: 8,
	LOGIST_END		: 9,
	DELIVERY_READY	: 10,
	DELIVERY_START	: 11,
	DELIVERY_END	: 12,
	COMPLETED		: 13,
	CANCELED		: 14,
	REFUND			: 15,
	HOLD			: 16,
	HIDE			: 17,
	ARCHIVE			: 18
};
const orderStatusName = ['',
	'Новый','Новый оплаченый',
	'Принят в работу',
	'Ожидание готовки','Начал готовится','Закончил готовиться',
	'Готов к доставке','Ожидание распределения','Распределен',
	'Ожидание доставки','Доставляется','Доставлен',
	'Завершен',
	'Отменен',
	'Возврат',
	'Отложен',
	'Скрыт',
	'Архив'
];

const measureType = {
	UNKNOWN		: 0,
	PIECES		: 1,
	KILOGRAM	: 2,
	GRAMS		: 3,
	LITER		: 4,
	MILLILITER	: 5
};
const measureTypeName = ['','шт','кг','г','л','мл'];

const userType = {
	UNKNOWN		: 0,
	OPERATOR	: 1,
	COURIER		: 2,
	DISPATCHER	: 3,
	COOK		: 4,
	CASHIER		: 5,
	COLLECTOR	: 6,
	LOGIST		: 7,
	MANAGER		: 8,
	MONITOR		: 9,
	ADMIN		: 10
};
const userTypeName = ['','Оператор','Курьер','Диспетчер','Повар','Кассир','Сборщик','Логист','Менеджер','Монитор','Администратор'];

const permissionTypeName = [
	{id:1,name:'Компания'},
	{id:2,name:'Филиалы'},
	{id:3,name:'Зоны доставки'},
	{id:4,name:'Сотрудники'},
	{id:5,name:'Табель'},
	{id:6,name:'Роли доступа'},
	{id:7,name:'Категории'},
	{id:8,name:'Компоненты'},
	{id:9,name:'Клиенты'},
	{id:10,name:'Заказы'},
	{id:11,name:'Отчеты'},
	{id:12,name:'Программы лояльности'},
	{id:13,name:'Акции и Промо-коды'},
	{id:14,name:'Правила работы с корзиной'},
	{id:15,name:'Пуш-уведомления'},
	{id:16,name:'Каталог'},
	{id:17,name:'Заказы'},
	{id:18,name:'Заказы (только просмотр)'},
	{id:1000,name:'Без доступа'}
];

const settingType = {
	INPUT			: 0,
	TEXTAREA		: 1,
	IMAGE			: 2,
	GALLERY			: 3,
	TIMEZONE		: 4,	
	FORM			: 5,
	COLOR			: 6
};
const settingTypeName = ['Текстовое поле','Текстовая область','Изображение','Галерея','Часовой пояс','Форма','Цвет'];

const settingTarget = {
	COMMON			: 0,
	SITE			: 1,
	MOBILE			: 2,
	PAYMENT			: 3,
	INTEGRATIONS	: 4,
	SERVICES		: 5,
	MESSAGES		: 6,
	LINKS			: 7,
	DOCUMENTS		: 8
};
const settingTargetName = ['Общие','Сайт','Мобильное приложение','Оплата','Интеграции','Сервисы','Сообщения','Ссылки','Документы'];

const settingTargetCodes = {
	common			: settingTarget.COMMON,
	site			: settingTarget.SITE,
	apps			: settingTarget.MOBILE,
	payment			: settingTarget.PAYMENT,
	integrations	: settingTarget.INTEGRATIONS,
	services		: settingTarget.SERVICES,
	messages		: settingTarget.MESSAGES,
	links			: settingTarget.LINKS,
	documents		: settingTarget.DOCUMENTS
};

const timezones = [
    {id:1,name:"Гринвич, Лондон, Рейкьявик, Лиссабон, Дакар",value:0},
    {id:2,name:"Рим, Париж, Берлин, Осло, Мадрид, Копенгаген, Вена",value:1},
    {id:3,name:"Киев, Минск, Анкара, Афины, Иерусалим, Хельсинки",value:2},
    {id:4,name:"Москва, Аддис-Абеба, Багдад",value:3},
    {id:5,name:"Тегеран, Баку, Абу-Даби",value:4},
    {id:6,name:"Тюмень, Душанбе, Ташкент, Карачи",value:5},
    {id:7,name:"Омск, Алматы",value:6},
    {id:8,name:"Новосибирск, Джакарта, Бангкок",value:7},
    {id:9,name:"Иркутск, Улан-Удэ, Гонконг, Пекин, Шанхай, Урумчи",value:8},
    {id:10,name:"Якутск, Токио, Сеул, Пхеньян",value:9},
    {id:11,name:"Владивосток, Магадан, Канберра, Сидней, Мельбурн",value:10},
    {id:12,name:"Сахалинская область",value:11},
    {id:13,name:"Окленд, Веллингтон, Фиджи, Маршалловы о-ва",value:12},
    {id:14,name:"о. Мидуэй, Самоа, Нукуалофа",value:13},
    {id:15,name:"Анадырь, Камчатка",value:-12},
    {id:16,name:"Ном (Аляска), Самоа",value:-11},
    {id:17,name:"Гавайи",value:-10},
    {id:18,name:"Аляска",value:-9},
    {id:19,name:"Лос-Анджелес, Сан-Франциско, Сиэтл, Ванкувер",value:-8},
    {id:20,name:"Денвер, Феникс, о.Пасхи",value:-7},
    {id:21,name:"Центральное время (США и Канада), Гвалахара, Мехико",value:-6},
    {id:22,name:"Нью-Йорк, Вашингтон, Атланта, Оттава, Гавана",value:-5},
    {id:23,name:"Ла-Пас, Каракас, Галифакс",value:-4},
    {id:24,name:"Асунсьон, Буэнос-Айрес",value:-3},
    {id:25,name:"Сан-Паулу, Бразилиа",value:-2},
    {id:26,name:"Азорские о-ва",value:-1}
];

const sourceType = {
	UNKNOWN		: 0,
	HALL		: 1,
	DISPATCHER	: 2,
	WEBSITE		: 3,
	IOS			: 4,
	ANDROID		: 5,
	TELEGRAM	: 6,
	VK			: 7,
	ROBOT		: 8,
	KIOSK		: 9
};
const sourceTypeName = ['','Касса/Зал','Диспетчер','Сайт','iOS','Android','Telegram','Вконтакте','Голосовой робот','Киоск'];

const paymentType = {
	UNKNOWN		: 0,
	CASH		: 1,
	CARD		: 2,
	QR			: 3
};
const paymentTypeName = ['','Наличные','Карта','QR-код'];

const cashboxPaymentType = {
	UNKNOWN		: 0,
	CASH		: 1,
	CARD		: 2
};
const cashboxPaymentTypeName = ['','Наличные','Безналичные'];

const discountType = {
	UNKNOWN		: 0,
	PERSONAL	: 1,
	PROMOCODE	: 2,
	MANUAL		: 3,
	BASKET		: 4
};

const promoCodeType = {
	UNKNOWN		: 0,
	PERCENT		: 1,
	DISCOUNT	: 2,
	GIFT		: 3,
	FREE		: 4,
	FREE_DELIVERY: 5,
	BONUSES		: 6,
	BASKET_RULE	: 7
};
const promoCodeTypeName = ['','Скидка, %','Скидка, ₽','Подарок','Бесплатно','Бесплатная доставка','Начислить бонусы','Правило корзины'];

const deliveryType = {
	UNKNOWN		: 0,
	DELIVERY	: 1,
	PICKUP		: 2,
};
const deliveryTypeName = ['','Доставка','Самовывоз'];

const transactionType = {
	UNKNOWN		: 0,
	DEBIT		: 1,
	CREDIT		: 2
};
const transactionTypeName = ['','Списание','Зачисление'];
const transactionTypeNameCashbox = [null,'Изъятие','Внесение'];
const transactionTypeNameSalary = [null,'Выплатить','Начислить']

const notificationStatus = {
	ADDED		: 0,
	SENT		: 1
};
const notificationStatusName = ['Добавлено','Отправлено'];

const salaryParams = {
	salary: [
		{key:'salary',name:'Месячный оклад'},
		{key:'salary_hour',name:'Расчет за час'},
		{key:'salary_day',name:'Расчет за день'}
	],
	properties: [
		{key:'hours',name:'Отработанные часы'},
		{key:'days',name:'Отработанные смены'},
		{key:'orders_count',name:'Количество заказов'},
		{key:'orders_amount',name:'Сумма заказов'},
		{key:'orders_count_all',name:'Количество заказов за смену'},
		{key:'orders_amount_all',name:'Сумма заказов за смену'}
	]
};

const datePriod = {
	TODAY		: 0,
	YESTERDAY	: 1,
	WEEK		: 2,
	MONTH		: 3,
	CUSTOM		: 4
};
const datePriodName = ['Сегодня','Вчера','Неделя','Месяц','Период'];


export {
	API,
	MAP,
	httpMethods,
	commonStatus,
	commonStatusName,
	commonStatusAreaName,
	commonStatusDayShiftName,
	userStatus,
	userStatusName,
	userStatusNameWork,
	clientStatus,
	clientStatusName,
	orderStatus,
	orderStatusName,
	measureType,
	measureTypeName,
	userType,
	userTypeName,
	permissionTypeName,
	settingType,
	settingTypeName,
	settingTarget,
	settingTargetName,
	settingTargetCodes,
	timezones,
	sourceType,
	sourceTypeName,
	paymentType,
	paymentTypeName,
	cashboxPaymentType,
	cashboxPaymentTypeName,
	discountType,
	promoCodeType,
	promoCodeTypeName,
	deliveryType,
	deliveryTypeName,
	transactionType,
	transactionTypeName,
	transactionTypeNameCashbox,
	transactionTypeNameSalary,
	notificationStatus,
	notificationStatusName,
	salaryParams,
	datePriod,
	datePriodName
};