import React, {useEffect,useState} from 'react';

// plug-ins
import {useModal} from 'react-hooks-use-modal';
import {YMaps,Map,ZoomControl,Polygon} from 'react-yandex-maps';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import Layout from '../../../Components/Layout';

// models
import {Cities, DeliveryAreas, Points} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';

// constants
import { commonStatus, commonStatusName, MAP } from '../../../Globals/Constants';

// styles
import './styles.css';

// images
import imgClose from './Images/close.svg';
import imgCheckOn from './Images/checkbox-on.svg';
import imgCheckOff from './Images/checkbox-off.svg';


// start
const DeliveryAreasScreen = () => {
	const [ModalMap, modalMapOpen, modalMapClose] = useModal('root');
	const [ModalChangeStatus, modalChangeStatusOpen, modalChangeStatusClose] = useModal('root');
	const [loading, setLoading] = useState(true);
	const [areasAll, setAreasAll] = useState([]);
	const [areas, setAreas] = useState(null);
	const [point, setPoint] = useState(null);
	const [id, setId] = useState(null);
	const {value:name, setValue:setName, bind:bindName} = useInput('');
	const {value:description, setValue:setDescription, bind:bindDescription} = useInput('');
	const {value:color, setValue:setColor, bind:bindColor} = useInput('');
	const {value:area, setValue:setArea, bind:bindArea} = useInput('');
	const {value:price, setValue:setPrice, bind:bindPrice} = useInput('');
	const {value:priceFree, setValue:setPriceFree, bind:bindPriceFree} = useInput('');
	const {value:minimumAmount, setValue:setMinimumAmount, bind:bindMinimumAmount} = useInput('');
	const {value:maxTime, setValue:setMaxTime, bind:bindMaxTime} = useInput('');
	const {value:minTime, setValue:setMinTime, bind:bindMinTime} = useInput('');
	const [alwaysPaid, setAlwaysPaid] = useState(commonStatus.ACTIVE);
	const [workTime, setWorkTime] = useState([]);
	const [cities, setCities] = useState([]);
	const [search, setSearch] = useState(null);
	const [status, setStatus] = useState(null);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			const point = ls('point');
			await pontGet(point.id);
			await citiesGet();
			await areasGet();
		};
		await dataGet();
		setLoading(false);
	}, []);
	const pontGet = async (id) => {
		const point = await Points.get.id(id).catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (point === undefined) return;
		setPoint(point);
	};
	const citiesGet = async () => {
		const cities = await Cities.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (cities === undefined) return;
		setCities(cities);
	};
	const areasGet = async () => {
		const areas = await DeliveryAreas.get.all().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (areas === undefined || areas.length === 0) return;
		setAreas(areas);
		setAreasAll(areas);
	};
	const areaAdd = () => show();
	const areaEdit = (area) => {
		area = area || areas.find(f => f._id === id);
		show(area);
	};
	const areaShow = (area) => show(area);
	const show = (area) => {
		setId(area?._id||null);
		setName(area?.name||'');
		setDescription(area?.description||'');
		setPrice(area?.prices.price||'');
		setPriceFree(area?.prices.priceFree||'');
		setMinimumAmount(area?.prices.minimumAmount||'');
		setMaxTime(area?.maxTime||'');
		setMinTime(area?.minTime||'');
		setAlwaysPaid(area?.alwaysPaid||false);
		setArea(area && area.area ? area.area : []);
		setColor(area?.properties.color||'#00ff00');
		setWorkTime(workTimePrepare(area?.workTime)||[]);
		setStatus(area?area.status:'');
	};
	const cancel = () => {
		areaShow(null);
		modalChangeStatusHide();
		modalMapClose();
	}
	const save = async (e) => {
		e.preventDefault();
		const data = {
			name:name,
			description:description,
			price:price,
			priceFree:priceFree,
			minimumAmount:minimumAmount,
			maxTime:maxTime,
			minTime:minTime,
			alwaysPaid:alwaysPaid,
			area:area,
			color:color||'#00ff00',
			workTime:workTime,
			status:status
		};
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить название зоны доставки');
			return;
		}
		if (Utils.empty(data.area)) {
			errorShow('Необходимо заполнить область зоны доставки');
			return;
		}
		const res = id ?
				await DeliveryAreas.update(id, data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				})
			:
				await DeliveryAreas.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
		if (res) {
			successShow(res.message);
			areasGet();
			cancel();
			return true;
		}
	}
	const searchArea = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setAreas(areasAll);
		else setAreas(areasAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase())));
		setSearch(search);
	}
	const areaChangeStatusOn = (id) => areaChangeStatus(id);
	const areaChangeStatusModal = () => areaChangeStatus(id);
	const areaChangeStatus = (id) => {
		const area = areas.find(f => f._id === id);
		const status = area.status === commonStatus.ACTIVE ? commonStatus.IN_ACTIVE : commonStatus.ACTIVE;
		DeliveryAreas.status(id, {status}).then((res) => {
			successShow(res.message);
			areasGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
	}
	const modalChangeStatusHide = () => modalChangeStatusClose();
	const modalChangeStatusShow = (id) => {
		setId(id);
		setName(areas.find(f => f._id === id).name);
		modalChangeStatusOpen();
	}
	const handleStatus = (e) => setStatus(parseInt(e.target.value));
	const areaDelete = (id) => {
		DeliveryAreas.remove(id).then((res) => {
			successShow(res.message);
			areasGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
		return true;
	}
	const workTimePrepare = (workTime) => {
		workTime = workTime||[];
		const wt = [];
		Utils.weekDayName.full.forEach((v,i) => {
			const start = workTime.find(f => f.day === i)?.start||null;
			const end = workTime.find(f => f.day === i)?.end||null;
			wt.push({day:i,start:start,end:end});
		});
		return wt;
	};
	const wtStartSet = (e, i) => {
		const wt = [...workTime||[]];
		wt[i].start = e.target.value;
		setWorkTime(wt);
	}
	const wtEndSet = (e, i) => {
		const wt = [...workTime||[]];
		wt[i].end = e.target.value;
		setWorkTime(wt);
	}
	const cityCoordinatesGet = () => {
		const city = cities.find(f => f._id === point.cityId);
		const latitude = city?.coordinates?.latitude||MAP.defCoords[0], longitude = city?.coordinates?.longitude||MAP.defCoords[1];
		return [latitude,longitude];
	};
	const mapDraw = (ref) => {
		if (ref) {
			ref.editor.startDrawing();
			ref.geometry.events.add('change', (e) => setArea(e.get('newCoordinates')));
		}
	};
	const statusGet = (v) => <div className={`status${v.status===commonStatus.ACTIVE?v.status:0}`}>
		<div className="status-point"></div>
	</div>;
	const massCopy = async (ids) => {
		for (const v of ids) {
			const s = areas.find(f => f._id === v);
			if (s) {
				const data = {
					name:`${s.name} (копия)`,
					description:s.description,
					price:s.prices.price,
					priceFree:s.prices.priceFree,
					minimumAmount:s.prices.minimumAmount,
					maxTime:s.times.maxTime,
					minTime:s.times.minTime,
					alwaysPaid:s.properties.alwaysPaid,
					area:s.area,
					color:s.properties.color||'#00ff00',
					workTime:s.workTime,
					status:s.status
				};
				await DeliveryAreas.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
			}
		};
		successShow('Операция прошла успешно');
		areasGet();
		cancel();
		return true;
	};
	return (
		<>
			<Template>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<Layout
							title="Зоны доставки"
							type={[{type:'account'},{type:'settings',title:'Настройки'}]}
							data={areas}
							id={id}
							search={search}
							rows={[
								{title:'Название',field:'name'},
								{title:'Дост / Беспл / Мин',field:'fields',class:'prices-wide',func:(v) => `${v.prices.price||'–'} / ${v.prices.priceFree||'–'} / ${v.prices.minimumAmount||'–'}`},
								{title:'Статус',field:'status',class:'status status-small',func:statusGet},
							]}
							rowsShort={['name','status']}
							rowControlItems={[
								{title:(v) => v.status===commonStatus.ACTIVE?'Отключить':'Включить',class:(v) => v.status===commonStatus.ACTIVE?'inactive':'active',action:(v) => v.status === commonStatus.ACTIVE ? modalChangeStatusShow(v._id) : areaChangeStatusOn(v._id)},
							]}
							footerItems={[
								{title:'Сделать копию',action:massCopy}
							]}
							empty={<>Добавьте первую<br/>зону доставки</>}
							contentShowTitle={name}
							contentShow={<>
								{description ?
									<div className="product-view-row product-view-row-simple" dangerouslySetInnerHTML={{__html: Utils.rn2br(description)}} /> : null}
								<h4>Условия</h4>
								<div className="product-view-row product-view-row-oneline">
									<div className="label-middle">Стоимость доставки</div>
									<div>{price||'–'}</div>
								</div>
								<div className="product-view-row product-view-row-oneline">
									<div className="label-middle">Бесплатно от</div>
									<div>{priceFree||'–'}</div>
								</div>
								<div className="product-view-row product-view-row-oneline">
									<div className="label-middle">Мин. сумма заказа</div>
									<div>{minimumAmount||'–'}</div>
								</div>
								<div className="product-view-row product-view-row-oneline">
									<div className="label-middle">Макс. время доставки (мин.)</div>
									<div>{maxTime||'–'}</div>
								</div>
								<div className="product-view-row product-view-row-oneline">
									<div className="label-middle">Мин. время доставки (мин.)</div>
									<div>{minTime||'–'}</div>
								</div>


							</>}
							contentEdit={<>
								<div className="product-edit-row">
									<input type="text" {...bindName} placeholder="Название зоны" className="input-title" autoFocus={true} required />
								</div>
								<div className="product-edit-row">
									<textarea placeholder="Описание" {...bindDescription} className="lite"></textarea>
								</div>
								<h4>Область обслуживания</h4>
								<div className="product-edit-row">
									<div className="product-edit-btn" onClick={() => modalMapOpen()}>{area && area.length ? 'Изменить' : 'Добавить'}</div>
								</div>
								<h4>Условия</h4>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="price" className="label-middle">Стоимость доставки</label>
									<input id="price" {...bindPrice} type="text" placeholder="0" maxLength={3} />
								</div>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="priceFree" className="label-middle">Бесплтано от</label>
									<input id="priceFree" {...bindPriceFree} type="text" placeholder="0" maxLength={5} />
								</div>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="minimumAmount" className="label-middle">Мин. сумма заказа</label>
									<input id="minimumAmount" {...bindMinimumAmount} type="text" placeholder="0" maxLength={6} />
								</div>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="maxTime" className="label-middle">Макс. время доставки (мин.)</label>
									<input id="maxTime" {...bindMaxTime} type="text" placeholder="0" maxLength={3} />
								</div>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="minTime" className="label-middle">Мин. время доставки (мин.)</label>
									<input id="minTime" {...bindMinTime} type="text" placeholder="0" maxLength={3} />
								</div>
								<h4>Время работы</h4>
								<div className="product-edit-row">
									<div className="work-time">
										{Utils.weekDayName.full.map((v,i) => <div key={i} className="product-edit-row product-edit-row-oneline">
											<div>{v}</div>
											<div className="product-edit-row-oneline">
												<input type="time" className="time" value={workTime[i]?.start||''} onChange={(e) => wtStartSet(e, i)} required />
												<input type="time" className="time" value={workTime[i]?.end||''} onChange={(e) => wtEndSet(e, i)} required />
											</div>
										</div>)}
									</div>
								</div>
								<h4>Дополнительные параметры</h4>
								<div className="product-edit-row product-edit-row-oneline">
									<label>Платная доставка</label>
									<img src={alwaysPaid ? imgCheckOn : imgCheckOff} alt="" onClick={() => setAlwaysPaid(!alwaysPaid)} />
								</div>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="color" className="label-middle">Цвет</label>
									<div className="input">
										<input type="color" id="color" {...bindColor} className="color" />
									</div>
								</div>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="status">Статус</label>
									<div className="select select-middle">
										<select id="status" onChange={handleStatus} value={status} required>
											<option value=""></option>
											{commonStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
										</select>
									</div>
								</div>
							</>}
							onDelete={areaDelete}
							onEdit={areaEdit}
							onAdd={areaAdd}
							onSave={save}
							onSearch={searchArea}
							onShow={areaShow}
							onClose={cancel}
						/>
					)
				}
			</Template>
			<Alert />
			<ModalMap>
				<div className="modal modal-map">
					<div className="map-container">
						<YMaps query={{apikey:MAP.keys.Yandex}}>
							<Map
								state={{center:cityCoordinatesGet(),zoom:12}}
								modules={['geoObject.addon.editor']}
								width={'100%'} height={'100%'}
							>
								<ZoomControl />
								<Polygon 
									geometry={area||[]}
									options={{
										draggable:true,
										editorDrawingCursor:'crosshair',
										strokeOpacity:.5,
										strokeWidth:3,
										fillColor:color,
										opacity:.5
									}}
									properties={{hintContent:name}}
									modules={['geoObject.addon.hint']}
									instanceRef={mapDraw} />
							</Map>
						</YMaps>
						<div className="map-close">
							<img src={imgClose} alt="" onClick={modalMapClose} className="btn-close" />
						</div>
					</div>
				</div>
			</ModalMap>
			<ModalChangeStatus>
				<div className="modal">
					<div className="header">
						<h4>Отключить зону</h4>
						<img src={imgClose} alt="" onClick={modalChangeStatusHide} className="btn-close" />
					</div>
					<p>Вы уверены что хотите отключить зону <b>{name}?</b></p>
					<div className="buttons">
						<button type="button" onClick={modalChangeStatusHide} className="btn-cancel">Отменить</button>
						<button type="button" onClick={areaChangeStatusModal} className="btn-accept btn-delete">Отключить</button>
					</div>
				</div>
			</ModalChangeStatus>
		</>
	);
};

export default DeliveryAreasScreen;